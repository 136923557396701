/* eslint-disable react/button-has-type */
import React from "react"
import { ClipLoader } from "react-spinners"

type MainButtonProps = {
  type?: "button" | "submit" | "reset"
  children?: React.ReactNode
  props?: any
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  isLoading?: boolean
  style?: React.CSSProperties
}

type TOutlineButton = Omit<MainButtonProps, "type"> & { color?: string }

export const MainButton = ({
  isLoading,
  onClick,
  type = "button",
  children,
  className,
  disabled = false,
  ...props
}: MainButtonProps) => (
  <button
    type={type}
    className={`w-full py-4 text-sm text-white rounded-full font-light hover:text-white ${className} `}
    {...props}
    style={{
      background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {isLoading ? <ClipLoader color="#fff" size="20px" /> : children}
  </button>
)
export const RejectButton = ({
  isLoading,
  onClick,
  type = "button",
  children,
  className,
  disabled = false,
  ...props
}: MainButtonProps) => (
  <button
    type={type}
    className={`w-full py-4 text-sm text-white rounded-full bg-error  font-light hover:text-white ${className} `}
    {...props}
    onClick={onClick}
    disabled={disabled}
  >
    {isLoading ? <ClipLoader color="#fff" size="20px" /> : children}
  </button>
)
export const DisabledButton = ({
  onClick,
  type = "button",
  children,
  className,
  disabled = true,
  ...props
}: MainButtonProps) => (
  <button
    type={type}
    className={`${className} w-full py-2 text-sm text-white rounded-md bg-dark-text font-light  hover:text-white `}
    {...props}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export const OutlineButton = ({
  isLoading,
  onClick,
  className,
  children,
  style,
  ...props
}: TOutlineButton) => (
  <button
    type="button"
    className={`w-full py-4 text-sm text-primary rounded-full border border-primary font-light hover:text-primary ${className} `}
    {...props}
    onClick={onClick}
  >
    {isLoading ? <ClipLoader color="#CA1611" size="20px" /> : children}
  </button>
)
