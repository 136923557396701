import React, { ChangeEvent, useEffect, useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate, useParams } from "react-router-dom"
import { DateRangePicker, Uploader } from "rsuite"
import GoBack from "../../../../Components/elements/Display/GoBack"
import Hero from "../../../../Components/elements/Hero"
import {
  CommunityIMG,
  EditCom,
  NotificationIcon,
  Profile,
  WalletIcon,
  InviteMembersIcon,
  EscrowGroupsIcon,
  Walletbg,
  FileUploadIcon,
  ProfileGuy,
  ProfileGirl,
  ProfileLady,
  ProfileWoman,
  UpArrowIcon,
  WithdrawalIcon,
  DownArrowIcon,
  SuccessIcon,
  FailureIcon,
} from "../../../../Assets"
import {
  MainButton,
  OutlineButton,
} from "../../../../Components/elements/Button"
import SearchInput from "../../../../Components/elements/Forms/Search"
import CommunityMembers from "./CommunityMembers"
import Search, { useDebounce } from "../../../../Components/elements/Forms/MembersSearch"
import { RiUserAddLine, RiGroupLine } from "react-icons/ri"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import ResultToast from "../../../../Components/elements/Display/ToastModal"
import Input from "../../../../Components/elements/Forms/Input"
import WalletHeader from "../../../../Components/elements/Hero/WalletHeader"
import TransactionsTable from "../Wallet/TransactionTable"
import {
  Community,
  CommunityWallet,
  Member,
  Transaction,
  UserAccountType,
  WalletHeaderProps,
  SearchComMember
} from "../../../../types"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { CgClose } from "react-icons/cg"
import Textarea from "../../../../Components/elements/Forms/TextArea"
import CustomSwitch from "../../../../Components/elements/Forms/Switch"
import {
  InviteToCommunityApi,
  getCommunityDetailsApi,
  notificationsApi,
  getCommunityMembersApi,
  EditCommunityApi,
  getWalletApi,
  addPermissionApi,
  removePermissionApi,
  GetCommunityWalletTransactionHistoryApi,
  sendNotificationApi,
  searchCommunityApi,
} from "../../../../Services"
import { toast } from "react-toastify"
import { BeatLoader, BounceLoader, CircleLoader } from "react-spinners"
import Pagination from "../../../../Components/elements/Pagination"
import FilterComponent from "../../../../Components/elements/Display/FilterTransaction"
import MemberPagination from "../../../../Components/elements/Pagination/MemberPagination"

interface barSwitchProps {
  loggingIn: boolean
  fundingSubWallet: boolean
  fundingCommunityWallet: boolean
  creatingEscrow: boolean
  joiningEscrow: boolean
  makingLoanRequest: boolean
  [key: string]: boolean
}

const barSwitchData = [
  { label: "Bar from logging in", name: "loggingIn" },
  { label: "Bar from funding sub-wallet", name: "fundingSubWallet" },
  {
    label: "Bar from funding community wallet",
    name: "fundingCommunityWallet",
  },
  { label: "Bar from creating escrow", name: "creatingEscrow" },
  { label: "Bar from joining escrow", name: "joiningEscrow" },
  { label: "Bar from making loan request", name: "makingLoanRequest" },
]

type EmailFormData = {
  emailAddress: string
}
interface EditCommunityFormData {
  communityName: string
  communityDescription: string
  communityImage: any
}
interface SendNotificationFormData {
  notificationMessage: string
  notificationTitle: string
}

// Schema for editing community form
export const editCommunitySchema = yup.object().shape({
  communityName: yup.string().required("Community name is required"),
  communityDescription: yup
    .string()
    .required("Community description is required"),
  communityImage: yup.mixed().required("Community image is required"),
})

// Schema for email form
export const emailSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
})

// Schema for send notification form
export const sendNotificationSchema = yup.object().shape({
  notificationTitle: yup.string().required("Subject is required"),
  notificationMessage: yup.string().required("Message is required"),
})

const CommunityDetails = () => {
  const {
    register: emailRegister,
    handleSubmit: handleEmailSubmit,
    setValue: emailSetValue,
    formState: { errors: emailErrors },
  } = useForm<EmailFormData>({
    resolver: yupResolver(emailSchema),
  })

  const {
    register: editRegister,
    handleSubmit: handleEditSubmit,
    setValue: editSetValue,
    formState: { errors: editErrors },
  } = useForm<EditCommunityFormData>({
    resolver: yupResolver(editCommunitySchema),
  })

  const {
    register: notificationRegister,
    handleSubmit: handleSendNotification,
    formState: { errors: notiErrors },
  } = useForm<SendNotificationFormData>({
    resolver: yupResolver(sendNotificationSchema),
  })

  const navigate = useNavigate()
  const [communityDetails, setCommunityDetails] = useState<Community>()
  const [communityWallet, setCommunityWallet] = useState<CommunityWallet>()
  const [openModal, setOpenModal] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [openFailureToast, setOpenFailureToast] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openCommunityWallet, setOpenCommunityWallet] = useState(false)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [openSelectedMembersModal, setOpenSelectedMembersModal] =
    useState(false)
  const [barLoginReasonModal, setBarLoginReasonModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [dateRangeValue, setDateRangeValue] = React.useState([])
  const { communityId } = useParams()
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const [communityMembersList, setCommunityMembersList] = useState<Member[]>([])
  const [filteredMembersList, setFilteredMembersList] = useState<Member[]>([]);
  const [isSelectMode, setIsSelectMode] = useState(false)
  const [barSwitch, setBarSwitch] = useState<barSwitchProps>({
    loggingIn: false,
    fundingSubWallet: false,
    fundingCommunityWallet: false,
    creatingEscrow: false,
    joiningEscrow: false,
    makingLoanRequest: false,
  })
  const [notificationsData, setNotificationsData] = useState([])
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [toastType, setToastType] = useState("members")
  const [memberLoading, setMemberLoading] = useState(false)
  const [loadingg, setLoadingg] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [transPageData, setTransPageData] = useState<any>()
  const [transPageNum, setTransPageNum] = useState(1)
  const [refreshComponent, setRefreshComponent] = useState(false)
  const [emailList, setEmailList] = useState<string[]>([])
  const [currentEmail, setCurrentEmail] = useState<string>("")
  const [invalidEmailMessage, setInvalidEmailMessage] = useState<string>("")
  const [communityImagePreview, setCommunityImagePreview] = useState<
    string | null
  >(null)
  const [transactionType, setTransactionType] = useState<string>('');
  const [transactionStatus, setTransactionStatus] = useState<string>('');
  const [searchData, setSearchData] = useState<string | null>(null)
  const debouncedSearchData = useDebounce(searchData || "", 500);
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData(e.target.value);
  };
  // const handleSwitchChange = (name: keyof barSwitchProps) => {
  //   setBarSwitch((prevStates) => ({
  //     ...prevStates,
  //     [name]: !prevStates[name],
  //   }))
  // }

  const resetSwitches = () => {
    setBarSwitch({
      loggingIn: false,
      fundingSubWallet: false,
      fundingCommunityWallet: false,
      creatingEscrow: false,
      joiningEscrow: false,
      makingLoanRequest: false,
    })
  }

  const toggleSelectMode = () => {
    setIsSelectMode((prev) => !prev)
    setSelectedMembers([])
  }

  const applyFilter = async (type: string, status: string) => {
    console.log('Selected Transaction Type:', type);
    console.log('Selected Transaction Status:', status);

    if (!communityId) {
      console.log("communityId is undefined");
      return;
    }
  
    try {
      setLoadingFilter(true);
      const response2 = await GetCommunityWalletTransactionHistoryApi(
        communityId,
        transPageNum,
        type, 
        status
      );
      setTransactionsData(response2.transactions[0]);
      setLoadingFilter(false);
      setOpenFilterModal(false);
      setOpenCommunityWallet(true);
      setTransPageData(response2.metadata)
    } catch (error) {
      setLoadingFilter(false);
      console.error(error);
    }

  };
  
   const handleFilterChange = () => {
    applyFilter(transactionType, transactionStatus);
  };

  const handleClearFilter = () => {
    setTransactionType('');
    setTransactionStatus('');
    applyFilter('', ''); 
  };

    const activeFiltersCount = [transactionType, transactionStatus].filter(Boolean).length;

  const getNotification = async () => {
    try {
      const response = await notificationsApi()
      console.log(response, "Notifications")
    } catch (error) {
      console.log(error)
    }
  }

  const transformSearchResult = (searchResult: SearchComMember) => ({
      id: searchResult.memberData.id,
      communityLeader: searchResult.communityLeader,
      isMember: searchResult.memberData.isMember,
      registered: searchResult.memberData.registered,
      loanInterestRate: searchResult.memberData.loanInterestRate,
      createdAt: searchResult.memberData.createdAt,
      updatedAt: searchResult.memberData.updatedAt,
      user: {
        id: searchResult.memberData.user.id,
        accountType: searchResult.memberData.user.accountType,
        username: searchResult.memberData.user.username,
        fullName: searchResult.memberName,  
        firstName: searchResult.memberData.user.firstName,
        lastName: searchResult.memberData.user.lastName,
        emailAddress: searchResult.memberData.user.emailAddress,
        password: searchResult.memberData.user.password,
        dateOfBirth: searchResult.memberData.user.dateOfBirth,
        phoneNumber: searchResult.memberData.user.phoneNumber,
        gender: searchResult.memberData.user.gender,
        otp: searchResult.memberData.user.otp,
        otpExpiry: searchResult.memberData.user.otpExpiry,
        profilePictureFileKey: searchResult.memberData.user.profilePictureFileKey,
        isActive: searchResult.memberData.user.isActive,
        isBlocked: searchResult.memberData.user.isBlocked,
        isSuspended: searchResult.memberData.user.isSuspended,
        createdAt: searchResult.memberData.user.createdAt,
        updatedAt: searchResult.memberData.user.updatedAt,
      },
      community: {
        id: searchResult.memberData.community.id,
        communityDescription: searchResult.memberData.community.communityDescription,
        communityImageFileKey: searchResult.memberData.community.communityImageFileKey,
        communityName: searchResult.memberData.community.communityName,
        createdAt: searchResult.memberData.community.createdAt,
        updatedAt: searchResult.memberData.community.updatedAt,
      },
      memberImageUrl: searchResult.memberImageUrl
  });

  useEffect(() => {
    getNotification()
  }, [])
 
  useEffect(() => {
    const fetchSearchedMembers = async () => {
      if (!searchData) {
        setFilteredMembersList(communityMembersList);
        return;
      }
      setMemberLoading(true)
      try {
        if (communityId && debouncedSearchData) {
          const response = await searchCommunityApi(communityId, debouncedSearchData);
          console.log(response);
          const transformedData = response.map(transformSearchResult);

  // Perform array manipulation to move the community leader to the first position
  const updatedMembersList = transformedData.slice();
  const communityLeaderIndex = updatedMembersList.findIndex((member: Member) => member.communityLeader);
  if (communityLeaderIndex !== -1) {
      const communityLeader = updatedMembersList.splice(communityLeaderIndex, 1)[0];
      updatedMembersList.unshift(communityLeader);
  }

          setFilteredMembersList(updatedMembersList);
        } else {
          console.log("communityId is undefined");
        }
      } catch (error) {
        // setLoading(false);
        console.error(error);
      }finally {
        setMemberLoading(false)
      }
    };
  
    fetchSearchedMembers();
  }, [communityId, debouncedSearchData]); 

  const submitNotification: SubmitHandler<SendNotificationFormData> = async (data) => {
    setToastType("notification")
    try {
      if (communityId) {
      setSubmitting(true)
      console.log(data)

      console.log("FormData before sending:", data)

      const response = await sendNotificationApi(communityId, data)
      console.log(response)
      setOpenToast(true)
      // reset()
      setSubmitting(false)
      setOpenNotificationModal(false)
      }else {
        console.log("communityId is undefined")
      }
    } catch (error: any) {
      console.log(error)
      setOpenNotificationModal(false)
      setOpenFailureToast(true)
      setSubmitting(false)
      // reset()
    }
  }

  useEffect(() => {
    const fetchCommunityDetails = async () => {
      setLoading(true)
      try {
        if (communityId) {
          const response = await getCommunityDetailsApi(communityId);
          console.log(response, "community data");
 
          setCommunityDetails(response);
  
          // Set default values for form fields after setting community details
          if (response) {
            editSetValue("communityName", response.communityName)
            editSetValue("communityDescription", response.communityDescription)
            setCommunityImagePreview(response.communityImageURL)
          }

          setLoading(false)
        } else {
          console.log("communityId is undefined")
        }
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    };
  
    fetchCommunityDetails();
  }, [communityId, refreshComponent, editSetValue]); 

  useEffect(() => {
    const fetchCommunityMembers = async () => {
       setMemberLoading(true)
      try {
        if (communityId) {
          const res = await getCommunityMembersApi(communityId, pageNum);
          console.log(res, "members data");
        setPageData(res.metadata)

                // Perform array manipulation to move the community leader to the first position
                const updatedMembersList = res.members.slice();
                const communityLeaderIndex = updatedMembersList.findIndex((member: Member) => member.communityLeader);
                if (communityLeaderIndex !== -1) {
                    const communityLeader = updatedMembersList.splice(communityLeaderIndex, 1)[0];
                    updatedMembersList.unshift(communityLeader);
                }
        
          setCommunityMembersList(updatedMembersList);
      setFilteredMembersList(updatedMembersList);

           setMemberLoading(false)
        } else {
          console.log("communityId is undefined")
        }
      } catch (error) {
        setMemberLoading(false)
        console.error(error)
      }
    };
  
    fetchCommunityMembers();
  }, [communityId, refreshComponent, pageNum]); 
  
  useEffect(() => {
    const fetchCommunityWallet = async () => {
      // setLoading(true);
      try {
        if (communityId) {
          const response = await getWalletApi(communityId)
          const response2 = await GetCommunityWalletTransactionHistoryApi(
            communityId,
            transPageNum,
          )
          console.log(response)
          console.log(response2.transactions[0])
          setCommunityWallet(response)
          setTransactionsData(response2.transactions[0])
      setTransPageData(response2.metadata)
          // setLoading(false);
        } else {
          console.log("communityId is undefined")
        }
      } catch (error) {
        // setLoading(false);
        console.error(error)
      }
    };
  
    fetchCommunityWallet();
  }, [communityId, transPageNum]); 
  
  const getPermissionNameFromSwitchName = (switchName: string): string => {
    // Mapping switch names to permission names
    const switchToPermissionMap: { [key: string]: string } = {
      loggingIn: "login",
      fundingSubWallet: "fund_wallet",
      fundingCommunityWallet: "fund_community_wallet",
      creatingEscrow: "create_escrow",
      joiningEscrow: "join_escrow",
      makingLoanRequest: "make_loan_request",
    };

    return switchToPermissionMap[switchName];
  };

  const handleMemberSelection = (memberId: string) => {
    const updatedSelectedMembers = [...selectedMembers];
    if (updatedSelectedMembers.includes(memberId)) {
      // Deselect member if already selected
      const index = updatedSelectedMembers.indexOf(memberId);
      updatedSelectedMembers.splice(index, 1);
    } else {
      // Select member if not already selected
      updatedSelectedMembers.push(memberId);
    }
    setSelectedMembers(updatedSelectedMembers);
  };
  
  
  console.log("members", communityMembersList)
  console.log("filtered members", filteredMembersList)
  const role = filteredMembersList?.map((member) =>
    member?.communityLeader === true
      ? "Community Leader"
      : "Member",
  )

  const validateEmail = (email: string): boolean => {
    const isValid = /\S+@\S+\.\S+/.test(email)
    return isValid
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value)
    setInvalidEmailMessage("")
  }

  const addEmail = () => {
    const trimmedEmail = currentEmail.trim();
    const hasCommas = trimmedEmail.includes(',');
  
    if (hasCommas) {
      const emailsArray = trimmedEmail.split(',').map(email => email.trim());
      const invalidEmails = emailsArray.filter(email => !validateEmail(email));
      const uniqueValidEmails = emailsArray.filter((email, index, self) =>
        validateEmail(email) && self.indexOf(email) === index && !emailList.includes(email)
      );
  
      if (invalidEmails.length > 0) {
        setInvalidEmailMessage("One or more invalid email format");
      } else {
        setEmailList(prevList => [...prevList, ...uniqueValidEmails]);
        setCurrentEmail("");
        setInvalidEmailMessage("");
      }
    } else if (trimmedEmail && !emailList.includes(trimmedEmail)) {
      if (validateEmail(trimmedEmail)) {
        setEmailList(prevList => [...prevList, trimmedEmail]);
        setCurrentEmail("");
        setInvalidEmailMessage("");
      } else {
        setInvalidEmailMessage("Invalid email format");
      }
    }
  };
  
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "," || e.key === "Enter") {
      e.preventDefault()
      addEmail()
    }
  }

  const removeEmail = (index: number) => {
    setEmailList((prevList) => prevList.filter((_, i) => i !== index))
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setToastType("members")
    event.preventDefault();

    const trimmedEmail = currentEmail.trim();
    const hasCommas = trimmedEmail.includes(',');
  
    
  
if (currentEmail){
if (validateEmail(currentEmail.trim())){
  if (hasCommas) {
    setInvalidEmailMessage("Please press Enter or Comma to preview emails before sending");
    return;
  }
}else {
  setInvalidEmailMessage("Invalid email format")
  return;
}}

    if (emailList.length === 0 && currentEmail.trim() && validateEmail(currentEmail.trim())) {
      // If emailList is empty and there's a valid email in currentEmail, send that email directly

      try {
        setLoadingg(true);
        const payload = { emailAddress: [currentEmail.trim()] };
  
        console.log(payload);
  
        const res = await InviteToCommunityApi(communityId, payload);
        console.log(res);
        // toast.success(res.message);
        setOpenToast(true);
        setCurrentEmail("");
        setInvalidEmailMessage("");
        setOpenModal(false);
      setRefreshComponent((prev) => !prev)

      } catch (error: any) {
        console.log(error);
        setOpenModal(false)
        setOpenFailureToast(true)
        setInvalidEmailMessage("");
        setCurrentEmail("");
        // (error.response?.data?.error?.message || "An error occurred");
      } finally {
        setLoadingg(false);
      }
    } else {
  
      try {
        setLoadingg(true);
        const payload = { emailAddress: emailList };
  
        console.log(payload);
  
        const res = await InviteToCommunityApi(communityId, payload);
        console.log(res);
        // toast.success(res.message);
        setOpenToast(true);
        setEmailList([]);
        setCurrentEmail("");
        setInvalidEmailMessage("");
        setOpenModal(false);
      setRefreshComponent((prev) => !prev)

      } catch (error: any) {
        console.log(error);
        setOpenModal(false)
        setOpenFailureToast(true)
        setEmailList([]);
        setCurrentEmail("");
        setInvalidEmailMessage("");
        // toast.error(error.response?.data?.error?.message || "An error occurred");
      } finally {
        setLoadingg(false);

      }
    }
  };
  

  const handleEditCommunitySubmit: SubmitHandler<
  EditCommunityFormData
> = async (data) => {
  setEditLoading(true)
  setToastType("update")
  try {
    // Prepare FormData
    const formData = new FormData()
    if (data.communityName !== communityDetails?.communityName) {
      formData.append("communityName", data.communityName);
    }
    if (data.communityDescription !== communityDetails?.communityDescription) {
      formData.append("communityDescription", data.communityDescription);
    }

    // Append communityImage files to FormData
    console.log("Image file:", data.communityImage[0])
    if (data.communityImage.length > 0) {
      formData.append("communityImage", data.communityImage[0])
    }
    console.log("FormData before sending:", formData)
    // Submit formData to API
    const response = await EditCommunityApi(communityId, formData)
    console.log("Edit community response:", response)
    // setRefreshComponent((prev) => !prev)
    setOpenEditModal(false)
    setOpenToast(true)
  } catch (error) {
    console.error("Error submitting form:", error)
    setOpenFailureToast(true)
  }finally{
    setEditLoading(false)
  }

};

const handleSwitchChange = async (name: keyof barSwitchProps) => {
  const newValue = !barSwitch[name]
  const permissionName = getPermissionNameFromSwitchName(name as string)

        // Iterate over selected members
        await Promise.all(selectedMembers.map(async memberId => {
          setIsLoading(true);
          let displayName = '';
  try {
    if (newValue) {
      await removePermissionApi(communityId, memberId, {
        permissions: [permissionName],
      })
    } else {
      await addPermissionApi(communityId, memberId, {
        permissions: [permissionName],
      })
    }

    setBarSwitch((prevStates) => ({
      ...prevStates,
      [name]: newValue,
    }))

 // Get the member's first name and last name
 const member = filteredMembersList?.find(member => member.user.id === memberId)?.user;
 const firstName = member?.firstName || '';
 const lastName = member?.lastName || '';

 // Determine the name to display
 displayName = firstName || lastName ? `${firstName} ${lastName}` : 'No name';

    // Notify success
    toast.success(`Permissions updated successfully for member ${displayName}`);
  } catch (error:any) {
    // Notify error
    toast.error(`Failed to update permissions for member ${displayName}: ${error.message}`);
  }
}));
}


  const handleSuspendCommunityMember = async () => {
    try {
      // Construct the payload based on switch values
      const permissionsToAdd: string[] = [];
      const permissionsToRemove: string[] = [];
      Object.entries(barSwitch).forEach(([switchName, switchValue]) => {
        const permissionName = getPermissionNameFromSwitchName(switchName);
        if (switchValue) {
          permissionsToAdd.push(permissionName);
        } else {
          permissionsToRemove.push(permissionName);
        }
      });
  
      // Iterate over selected members
      await Promise.all(selectedMembers.map(async memberId => {
        setIsLoading(true);
        let displayName = '';
        try {
          // Call the API to add permissions
          if (permissionsToRemove.length > 0) {
            await addPermissionApi(communityId, memberId, { permissions: permissionsToRemove });
          }
  
          // Call the API to remove permissions
          if (permissionsToAdd.length > 0) {
            await removePermissionApi(communityId, memberId, { permissions: permissionsToAdd });
          }

      // Get the member's first name and last name
      const member = filteredMembersList?.find(member => member.user.id === memberId)?.user;
      const firstName = member?.firstName || '';
      const lastName = member?.lastName || '';
  
      // Determine the name to display
      displayName = firstName || lastName ? `${firstName} ${lastName}` : 'No name';
  
          // Notify success
          toast.success(`Permissions updated successfully for member ${displayName}`);
        } catch (error:any) {
          // Notify error
          toast.error(`Failed to update permissions for member ${displayName}: ${error.message}`);
        }
      }));
  
      // Close the modal after processing all selected members
      setOpenSelectedMembersModal(false);
      setConfirmModal(false);
      setIsLoading(false);
      resetSwitches();
      toggleSelectMode();
    } catch (error:any) {
      // Notify error
      toast.error(`Failed to update permissions: ${error.message}`);
    }
  };

  const backgroundImage = communityDetails?.communityImageURL
  console.log("communityDetails", backgroundImage)



const communityLeader = communityMembersList?.find(member => member.communityLeader);

let communityLeaderName = "No Community Leader";
let communityLeaderImage = "";

if (communityLeader) {
    const { firstName, lastName } = communityLeader.user;
    const { memberImageUrl } = communityLeader;
    if (firstName || lastName) {
        communityLeaderName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    } else {
        communityLeaderName = "No name";
    }
    communityLeaderImage = memberImageUrl;
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }
  
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-UK",
    options,
  )
  return formattedDate
}
const memberCount = communityDetails?.members?.length ?? 0;

const getMainSuccessText = (toastType: string) => {
  if (toastType === "members") {
    return "Member(s) invited successfully";
  }
  if (toastType === "update") {
    return "You’ve successfully updated a community";
  }
  return "Notification sent successfully";
};

const getMainFailureText = (toastType: string) => {
  if (toastType === "members") {
    return "Member(s) invitation failed";
  }
  if (toastType === "update") {
    return "Update failed";
  }
  return "Sending Notification failed";
};

  return (
    
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <>
          <div className="flex flex-col mt-4  md:flex-row justify-between">
            <div className="md:w-[62%]">
              <GoBack link="/app/communities" />
              <Hero imageUrl={backgroundImage} />
              <div className=" flex flex-col md:flex-row mt-3 justify-between items-center ">
              {communityLeaderName.toLowerCase() !== "no community leader" ?
                <div className="flex mx-2 items-center">
                  <img
                    src={communityLeaderImage}
                    alt="profile"
                    className="w-12 h-12 rounded-full ring-2 ring-white mt-3 mb-0"
                  />
                  <div className="mx-2">
                    <p className="text-primary text-[10px] font-bold">{communityLeaderName}</p>
                    <p className=" border rounded-2xl mt-0.5 text-[8px] text-center bg-[#FFF8ED] text-[#FFA412] px-2">
                      Community Leader
                    </p>
                  </div>
                </div>
                : 
                <div>No Community Leader</div>
      }

                <div className="flex lg:flex-row space-x-1 flex-col">
                  <div className="flex m-2 space-x-1">
                    <button
                      onClick={() => setOpenModal(true)}
                      type="button"
                      className="flex items-center justify-center border h-10 text-primary rounded-full px-2 gap-1"
                    >
                      <RiUserAddLine className="" />
                      Invite Members
                    </button>
                  </div>

                  <div className="flex space-x-1">
                    <button
                      type="button"
                      onClick={() => setOpenCommunityWallet(true)}
                      className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                      style={{
                        background:
                          "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                      }}
                    >
                      <img
                        src={WalletIcon}
                        alt="wallet icon"
                        className="w-full h-full"
                        style={{ borderRadius: "100%" }}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setOpenEditModal(true)}
                      className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                      style={{
                        background:
                          "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                      }}
                    >
                      <img
                        src={EditCom}
                        alt="edit icon"
                        className="w-full h-full"
                        style={{ borderRadius: "100%" }}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setOpenNotificationModal(true)}
                      className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                      style={{
                        background:
                          "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                      }}
                    >
                      <img
                        src={NotificationIcon}
                        alt="notification icon"
                        className="w-full h-full"
                        style={{ borderRadius: "100%" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-5">
                <p className=" text-[18px] text-black font-bold">Description</p>
                <p className="text-xs text-black">
                  {communityDetails?.communityDescription}
                </p>
              </div>
            </div>
            <div className="pl-2 md:w-[35%]">
              <div>
                <p className="text-[16px] text-black font-bold">
                  List of community members
                </p>
                <div className="flex text-[10px] justify-between items-center mb-2">
                  <p>
                    <span className="text-primary font-bold mr-1">
                      {memberCount}
                    </span>
                    {memberCount > 1 ? "community members" : "community member"}
                  </p>
                  {isSelectMode ? (
                    <div>
                      <button
                        type="button"
                        className="text-[#8E8E8E]"
                        onClick={toggleSelectMode}
                      >
                        Cancel
                      </button>
                      {selectedMembers.length > 0 && (
                        <button
                          type="button"
                          className="text-white p-1 rounded-md ml-2"
                          style={{
                            background:
                              "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                          }}
                          onClick={() => {
                            setOpenSelectedMembersModal(true)
                          } }
                        >
                          Continue
                        </button>
                      )}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="text-primary"
                      onClick={toggleSelectMode}
                    >
                      Select members
                    </button>
                  )}
                </div>
              </div>
              <div className="rounded-xl p-3 bg-white">
                <div className="">
                  <Search 
                         type="text"
                         value={searchData || ""}
                         placeholder="Search"
                         onChange={handleSearchChange}
                  />
                </div>
                {memberLoading ? 
        <div className="flex justify-center items-center h-[80vh]">
                <BeatLoader color="#3B82F6"/> 
                </div>
                :
                (filteredMembersList?.map((member, index) => (
                  <div key={index} className="flex items-center">
                    {isSelectMode && (
                      <input
                        type="checkbox"
                        checked={selectedMembers.includes(
                          member?.user?.id || "null",
                        )}
                        onChange={() =>
                          handleMemberSelection(member?.user?.id || "null")
                        }
                        className="mr-2 cursor-pointer"
                      />
                    )}
                    <CommunityMembers
                      key={index}
                      communityMembersList={filteredMembersList}
                      communityId={communityId}
                      name={(member?.user?.firstName) || (member?.user?.lastName) ? `${member?.user?.firstName ?? ""} ${member?.user?.lastName ?? ""}` : "No name"}
                      image={member?.memberImageUrl || "null"}
                      role={role[index] || "null"}
                      date={member?.createdAt || "null"}
                      memberId={member?.user?.id || "null"}
                      isSelectMode={isSelectMode} 
                      setRefreshComponent={setRefreshComponent}

                      />

                  </div>
                )))
                      }
                 <div className="flex justify-end mt-3 border-t-2 border-[#D1D1D1]">
              <MemberPagination pageData={pageData} setPageNum={setPageNum} />
            </div>


              </div>
            </div>
          </div>
          <ModalPanel closeModal={() => setOpenModal(false)} open={openModal}>
            <div className="">
              <div className="flex justify-between">
                <p className="text-black text-[18px] font-bold">
                  Invite Members
                </p>
                <button
                  type="button"
                  className="text-black"
                  onClick={() => {
                    setOpenModal(false)
                    setEmailList([])
                    setCurrentEmail("")
                    setInvalidEmailMessage("")
                  }}
                >
                  <CgClose size={25} />
                </button>
              </div>
              <div className="mb-6 mr-20">
                Enter email addresses of members you want to invite
              </div>
              <form onSubmit={onSubmit} className="flex flex-col ">
                <div className="pt-3 pb-1">
                  <Input
                    name="inviteEmail"
                    value={currentEmail}
                    error={emailErrors.emailAddress?.message}
                    label="Email Address"
                    placeholder="Type email and press Enter or comma"
                    className="bg-off-white"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                {invalidEmailMessage && (
                  <p className="text-xs text-red-500">{invalidEmailMessage}</p>
                )}
                <div className="flex flex-wrap gap-2 mt-2">
                  {emailList.map((email, index) => (
                    <div
                      key={index}
                      className="bg-[#EEF6FF] p-2 px-3 flex gap-2 rounded-full"
                    >
                      <p className="text-primary">{email}</p>
                      <button type="button" onClick={() => removeEmail(index)}>
                        <CgClose size={15} />
                      </button>
                    </div>
                  ))}
                </div>

                  <div className="flex justify-end mt-28">
                    <MainButton type="submit" isLoading={loadingg}>
                      Invite Members
                    </MainButton>
                  </div>
                </form>
              </div>
            </ModalPanel>
            <ModalPanel
        closeModal={() => setConfirmModal(false)}
        open={confirmModal}
        modalSize="md:w-[30%] lg:w-[30%] xl:w-[30%]"
      >
        <div className="flex flex-col justify-center my-7 mx-4 ">
          <p className="mb-10 text-black text-[15px] text-center">
            {`Are you sure you want to suspend members from ${communityDetails?.communityName} ?`}
          </p>
          <div className="flex justify-between">
            <MainButton className="mr-2" onClick={handleSuspendCommunityMember} isLoading={isLoading}>Yes</MainButton>
            <OutlineButton
              onClick={() => setConfirmModal(false)}
              className=" ml-2 bg-[#EEF6FF] border-2 border-primary rounded-full hover:text-black "
            >
              No
            </OutlineButton>
          </div>
        </div>
      </ModalPanel>
            <ModalPanel
              closeModal={() => setOpenNotificationModal(false)}
              open={openNotificationModal}
            >
              <div className="">
                <div className="flex justify-between">
                  <p className="text-black text-[20px] font-bold">Send Notification</p>
                  <button
                    type="button"
                    className="text-black"
                    onClick={() => setOpenNotificationModal(false)}
                  >
                    <CgClose size={25} />
                  </button>
                </div>
                <div className="mb-6 mr-20">
                  Enter a notification for all members of this community
                </div>
                <form onSubmit={handleSendNotification(submitNotification)} className="flex flex-col ">
                  <div className="pt-3 pb-1">
                    <Input
                     register={notificationRegister("notificationTitle", {
                      required: "Subject is required",
                    })}
                      name="notificationTitle"
                      label="Subject"
                      error={notiErrors.notificationTitle?.message}
                      placeholder="Enter notification subject"
                      className="bg-off-white" />
                  </div>
                  <div className="py-3">
                    <Textarea
                      register={notificationRegister("notificationMessage", {
                        required: "Message is required",
                      })}
                      name="notificationMessage"
                      label="Message"
                      error={notiErrors.notificationMessage?.message}
                      placeholder="Enter message"
                      className="bg-off-white" />
                  </div>

                <div className="flex justify-end mt-4">
                  <MainButton type="submit" isLoading={submitting}>Send</MainButton>
                </div>
              </form>
            </div>
          </ModalPanel>
          <ModalPanel
            closeModal={() => setOpenCommunityWallet(false)}
            open={openCommunityWallet}
            modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
          >
            <div className="">
              <div className="relative">
                <WalletHeader
                  totalBalance={communityWallet?.availableBalance}
                  imageUrl={Walletbg}
                  updatedAt={communityWallet?.updatedAt}
                />
                <div className="absolute top-5 left-5  z-10">
                  <GoBack className="text-white" link="/app/communities" />
                </div>
                <button
                  type="button"
                  className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-3 right-7  z-10"
                  style={{ background: "rgba(0, 0, 0, 0.40)" }}
                  onClick={() => setOpenCommunityWallet(false)}
                >
                  <CgClose size={20} />
                </button>
              </div>
              <div className="flex mt-32 justify-between items-center">
                <p className="text-black text-[15px] font-bold">
                  Transactions History
                </p>
                <button
                  type="button"
                  onClick={() => {
                  setOpenCommunityWallet(true)
                  setOpenFilterModal(true)}
                  }
                  className="flex items-center bg-primary h-10 text-white rounded-md p-2"
                >
                  <HiOutlineAdjustmentsHorizontal className="mr-2" />
                  Filter transaction
                  {activeFiltersCount > 0 && (
            <span
              className=" bg-white text-primary text-xs rounded-full h-5 w-5 flex items-center justify-center"
              style={{ marginLeft: '5px',}}
            >
              {activeFiltersCount}
            </span>
          )}
                </button>
              </div>

              <TransactionsTable 
              transactions={transactionsData.map((transaction) => ({
                ...transaction,
                date: transaction.createdAt
                  ? formatDate(transaction.createdAt)
                  : "",
                status: transaction.transactionType || "",
                amount: transaction.transactionAmount || 0,
                description: transaction.transactionStatus || "",
                // details: transaction.transactionDetails || "",
              }))}
              setTransPageNum={setTransPageNum}
              transPageData={transPageData}
          emptyTrans= {transactionsData?.length === 0}
              />
            </div>
          </ModalPanel>

          <ModalPanel
            closeModal={() => setOpenEditModal(false)}
            open={openEditModal}
            className="max-h-80"
          >
            <form onSubmit={handleEditSubmit(handleEditCommunitySubmit)}>
              <div>
                <div className="flex justify-between mb-3">
                  <p className="text-black text-[18px] font-bold">
                    Edit Community
                  </p>
                  <button
                    type="button"
                    className="text-black"
                    onClick={() => setOpenEditModal(false)}
                  >
                    <CgClose size={25} />
                  </button>
                </div>
                {/* <div className="my-3 mr-20 text-[13px]">
        A minimum of 1 community and a maximum of 3 communities are allowed
        to be created
      </div> */}
                <div className="flex flex-col">
                  <div className="py-3">
                    <Input
                      register={editRegister("communityName", {
                        required: "Community Name is required",
                      })}
                      name="communityName"
                      label="Community Name"
                      error={editErrors.communityName?.message}
                      placeholder="Enter community name"
                      className="bg-white"
                    />
                  </div>
                  <div className="py-3">
                    <Textarea
                      register={editRegister("communityDescription", {
                        required: "Community Description is required",
                      })}
                      name="communityDescription"
                      label="Description"
                      error={editErrors.communityDescription?.message}
                      placeholder="Enter description"
                      className="bg-white"
                    />
                  </div>

                  <div className="py-3 pb-6">
                    <div className="border-2 border-primary rounded-md">
                      <label
                        htmlFor="communityImage"
                        className="cursor-pointer"
                      >
                        {communityImagePreview ? (
                          <img
                            src={communityImagePreview}
                            alt="community"
                            style={{
                              width: "100%",
                              height: "300px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <span>Upload Community Image</span>
                        )}
                      </label>
                      <input
                        id="communityImage"
                        type="file"
                        accept="image/*"
                        {...editRegister("communityImage", {
                          required: "Community image is required",
                        })}
                        // onChange={(e) => handleImageChange(e.target.files as FileList)}
                        onChange={({ target: { files } }) => {
                          if (files && files.length > 0) {
                            const dataTransfer = new DataTransfer()
                            Array.from(files).forEach((file) => {
                              dataTransfer.items.add(file)
                            })
                            const fileList = dataTransfer.files
                            if (fileList.length > 0) {
                              editSetValue("communityImage", fileList)
                              // Set communityImagePreview to the value of the selected image file
                              const reader = new FileReader()
                              reader.onloadend = () => {
                                setCommunityImagePreview(
                                  reader.result as string,
                                )
                              }
                              reader.readAsDataURL(fileList[0])
                            }
                          }
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <MainButton isLoading={editLoading} type="submit">Update Group</MainButton>
                </div>
              </div>
            </form>
          </ModalPanel>

          <ModalPanel
            closeModal={() => {
              setOpenSelectedMembersModal(false)
              resetSwitches()
              toggleSelectMode()
            }
            }
            open={openSelectedMembersModal}
          >
            <div className="">
              <div className="flex justify-between">
                <p className="text-black text-[20px] font-bold">
                  Selected Members
                </p>
                <button
                  type="button"
                  className="text-black"
                  onClick={() => {
              setOpenSelectedMembersModal(false)
              resetSwitches()
              toggleSelectMode()
            }}
                >
                  <CgClose size={25} />
                </button>
              </div>
              <p className="text-black text-[12px] mb-4">
                {selectedMembers?.length} member(s) selected in total
              </p>

                <div className="mt-6">
                  {barSwitchData.map((barItem, index) => (
                    <CustomSwitch
                      key={index}
                      checked={barSwitch[barItem.name]}
                      onChange={() => handleSwitchChange(barItem.name as keyof barSwitchProps)}
                      label={barItem.label} />
                  ))}
                </div>
                <div className="my-6">
                  <MainButton
                  // onClick={() => 
                  //   setConfirmModal(true)}
                  >
                    Suspend Community Member
                  </MainButton>
                </div>
              </div>
            </ModalPanel>
            <ModalPanel
              open={barLoginReasonModal}
              closeModal={() => setBarLoginReasonModal(false)}
            >
              <div className="mx-1">
                <div className="flex justify-between ">
                  <div>
                    <p className="text-black text-[18px] font-bold mb-0">
                      Reason for suspense
                    </p>
                    <p className="text-[12px] mt-0">
                      Enter duration and reason for suspense
                    </p>
                  </div>
                  <button
                    type="button"
                    className="text-black"
                    onClick={() => setBarLoginReasonModal(false)}
                  >
                    <CgClose size={25} />
                  </button>
                </div>
                <div className="z-100 mt-4">
                  <p className="text-12px font-bold">Duration</p>
                  <DateRangePicker
                    size="lg"
                    style={{ zIndex: "9999px" }}
                    block
                    placeholder="Duration"
                    // value={dateRangeValue}
                    // onChange={setDateRangeValue}
                    format="yyyy-MM-dd"
                    showOneCalendar />
                </div>

              <div className="py-3">
                <Textarea
                  label="Reason"
                  name="reason"
                  placeholder=" Enter reason"
                  className="bg-off-white"
                />
              </div>

              <div className="my-6">
                <MainButton className="">Temporarily Replace Admin</MainButton>
              </div>
            </div>
          </ModalPanel>
          <ModalPanel closeModal={() => setOpenFilterModal(false)} open={openFilterModal}>
        <div className="">
          <div className="flex justify-between">
            <p className="text-black text-[18px] font-bold">Filter Transactions</p>
            <button
              type="button"
              className="text-black"
              onClick={() => {
                setOpenFilterModal(false)
                setOpenCommunityWallet(true)
              }}
            >
              <CgClose size={25} />
            </button>
          </div>
        
          <FilterComponent
           onFilterChange={handleFilterChange} 
           loadingFilter={loadingFilter}
           transactionType={transactionType}
           transactionStatus={transactionStatus}
           setTransactionType={setTransactionType}
           setTransactionStatus={setTransactionStatus}
           onClearFilter={handleClearFilter}
          />

        </div>
      </ModalPanel>
      <ResultToast
        image={SuccessIcon} 
        mainText={getMainSuccessText(toastType) }
        buttonText="Ok" 
        closeModal={() => {
          setOpenToast(false)
    setRefreshComponent((prev) => !prev)
        }
      }
        open={openToast}
/>
          <ResultToast
        image={FailureIcon} 
        mainText={getMainFailureText(toastType)}
        buttonText="Try Again" 
        closeModal={() => setOpenFailureToast(false)}
        open={openFailureToast}
/>
        </>
      )}
    </div>
  )
}

export default CommunityDetails
