import React, { useState } from "react"
import AuthTwo from "../../../Auth/AuthTwo"
import { MainButton } from "../../../../Components/elements/Button"
import Input from "../../../../Components/elements/Forms/Input"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, SubmitHandler } from "react-hook-form"
import { ChangePasswordApi } from "../../../../Services"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { toast } from "react-toastify"

type FormProps = {
  oldPassword: string
  newPassword: string
  newPasswordConfirmation: string
}
const schema = yup.object().shape({
  oldPassword: yup.string()
  .required("Old Password is required"),
  newPassword: yup.string()
  .required("New Password is required")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character",
    )
    .min(8, "Password must be at least 8 characters"),
  newPasswordConfirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
})

const PasswordSettings = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
  })

  const [loading, setLoading] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [changePassword, setChangePassword] = useState(false)

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword)
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      setLoading(true)
      console.log(data)
      const res = await ChangePasswordApi(data)
      reset()
      console.log(res)
      toast.success(res.message)
    } catch (error:any) {
      console.log(error)
      toast.error(error.response?.data?.error?.message || "An error occurred")
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="p-3 bg-white rounded-lg mr-3">
        <h5 className="text-lg font-bold text-black">Password Settings</h5>
        <p className="text-xs text-black">Update your password settings</p>
      </div>
      <div>
        <div className="w-full mt-8 flex items-center justify-center">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[60%]">
            <div className="py-3 relative">
              <Input
               register={
                register("oldPassword", {
                  required: "Old Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })
               }
               name="oldPassword"
                error={errors.oldPassword?.message}
                label="Old Password"
                placeholder="Enter old password"
                type={showOldPassword ? "text" : "password"}
                className="bg-white border-white"
              />
               <button
              type="button"
              className={`${
                errors.oldPassword ? "bottom-10" : "bottom-5"
              } absolute right-3 transform -translate-y-1/2`}
              onClick={toggleOldPasswordVisibility}
            >
              {showOldPassword ? (
                <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
              ) : (
                <AiOutlineEye className="text-gray-400 text-1.5xl" />
              )}
            </button>
            </div>
            <div className="py-3 relative">
              <Input
               register={
                register("newPassword", {
                  required: "New Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })
               }
               name="newPassword"
                error={errors.newPassword?.message}
                label="New Password"
                placeholder="Enter new password"
                type={showPassword ? "text" : "password"}
                className="bg-white border-white"
              />
              <button
              type="button"
              className={`${
                errors.newPassword ? "bottom-10" : "bottom-5"
              } absolute right-3 transform -translate-y-1/2`}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
              ) : (
                <AiOutlineEye className="text-gray-400 text-1.5xl" />
              )}
            </button>
            </div>
            <div className="py-3 relative">
              <Input
              register={
                register("newPasswordConfirmation", {
                  required: "Confirm Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })
              }
             name="newPasswordConfirmation"
                error={errors.newPasswordConfirmation?.message}
                label="Confirm New Password"
                placeholder="Enter confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                className="bg-white border-white"
              />
              <button
              type="button"
              className={`${
                errors.newPasswordConfirmation ? "bottom-10" : "bottom-5"
              } absolute right-3 transform -translate-y-1/2`}
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
              ) : (
                <AiOutlineEye className="text-gray-400 text-1.5xl" />
              )}
            </button>
            </div>
            <div className="py-4">
              <p className="text-primary text-xs ">Password must contain</p>
              <ul className="list-disc ml-4">
                <li className="text-[11px] text-dark-gray">
                  Should contain 8 characters
                </li>
                <li className="text-[11px] text-dark-gray">
                  1 special character
                </li>
                <li className="text-[11px] text-dark-gray">
                  At least 1 capital letter
                </li>
                <li className="text-[11px] text-dark-gray">
                  At least 1 number
                </li>
              </ul>
            </div>
            <div className="py-5">
              <MainButton type="submit" isLoading={loading}>Update Password</MainButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasswordSettings
