import React, { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "../../Components/elements/Forms/Input"
import { MainButton } from "../../Components/elements/Button"
import { ChangePasswordApi } from "../../Services"
import { toast } from "react-toastify"
import { useLocation } from "react-router"
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"

type PageProps = {
  handleNext: () => void
}

type FormProps = {
  newPassword: string
  confirmPassword: string
}

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character",
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
})

function AuthTwo({ handleNext }: PageProps) {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
  })

  const location = useLocation()
  const password = location?.state?.password

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      setLoading(true)
      console.log("Form Data:", data)

      const res = await ChangePasswordApi({
        newPassword: data.newPassword,
        newPasswordConfirmation: data.confirmPassword,
        oldPassword: password,
      })
      console.log("Change Password API Response:", res)
      toast.success(res.message)
      reset()
      handleNext()

    } catch (error:any) {
      console.error("Error:", error)
      toast.error(error.response?.data?.error?.message || "An error occurred")
      // handleNext()
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[60%]">
        <h3 className="text-primary text-2xl">New Password</h3>
        <p className="text-md">Create new password</p>
        <div className="flex py-5">
          <div className="flex-[.5] mr-1 bg-primary h-1 rounded-sm" />
          <div className="flex-[.5] ml-1 bg-[#ccc] h-1 rounded-sm" />
        </div>
        <div className="py-3 relative">
          <div>
            <label htmlFor="newPassword" className="pb-1 text-sm font-bold ">
              New Password
            </label>
            <input
              {...register("newPassword")}
              id="newPassword"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              className={`${
                errors.newPassword
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            <button
              type="button"
              className={`${
                errors.newPassword ? "bottom-10" : "bottom-5"
              } absolute right-3 transform -translate-y-1/2`}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
              ) : (
                <AiOutlineEye className="text-gray-400 text-1.5xl" />
              )}
            </button>
          </div>

          {errors.newPassword && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.newPassword.message}
            </p>
          )}
        </div>
        <div className="py-3 relative">
          <div>
            <label
              htmlFor="confirmPassword" 
              className="pb-1 text-sm font-bold "
            >
              Confirm New Password
            </label>
            <input
              {...register("confirmPassword")}
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter confirm Password"
              className={`${
                errors.confirmPassword
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-[12px] pt-1">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
          <button
            type="button"
            className={`${
              errors.confirmPassword ? "bottom-10" : "bottom-5"
            } absolute right-3 transform -translate-y-1/2`}
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? (
              <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
            ) : (
              <AiOutlineEye className="text-gray-400 text-1.5xl" />
            )}
          </button>
        </div>
        <div className="py-4">
          <p className="text-primary text-md ">Password must contain</p>
          <ul className="list-disc ml-4">
            <li className="text-xs text-dark-gray">
              Should contain 8 characters
            </li>
            <li className="text-xs text-dark-gray">1 special character</li>
            <li className="text-xs text-dark-gray">
              At least 1 capital letter
            </li>
            <li className="text-xs text-dark-gray">At least 1 number</li>
          </ul>
        </div>
        <div className="py-5">
          <MainButton type="submit" isLoading={loading}>Update Password</MainButton>
        </div>
      </form>
    </div>
  )
}

export default AuthTwo
