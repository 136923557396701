import React from 'react';
import { Switch } from "@headlessui/react"

interface CustomSwitchProps {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange, label }) => (
    <div className="mt-2 flex justify-between items-center">
      <p className="text-[15px] text-black font-[600] pl-3">{label}</p>
      <Switch
        checked={checked}
        onChange={onChange}
        style={{
          background: checked
            ? 'linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%)'
            : 'white',
        }}
        className={`${
          checked ? 'bg-primary border-primary' : 'bg-white'
        } relative inline-flex h-[34px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-dark-gray transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span
          aria-hidden="true"
          className={`${
            checked ? 'translate-x-8 bg-white' : 'translate-x-0.5'
          } pointer-events-none inline-block h-[28px] w-[28px] mt-0.5 transform rounded-full bg-dark-gray shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );

export default CustomSwitch;
