import React from "react"
import SidebarLayout from "./SidebarLayout"
import { useNavigate } from "react-router-dom"
import useCurrentPath from "../../../Hooks/useCurrentPath"
import {
  adminSidebarLinks,
  superAdminSidebarLinks,
  sidebarBottom,
} from "./data"
import { useSelector } from "react-redux"
import { UserAccountType } from "../../../types"

const Sidebar = () => {
  const currentPath = useCurrentPath()
  const navigate = useNavigate()
  const activeLink = useCurrentPath()
  console.log(activeLink[2]);
  
  const { user } = useSelector((state: any) => state.auth)

  const accountType = user ? UserAccountType.COMMUNITY_ADMIN : UserAccountType.SUPER_ADMIN

  const sidebarLinks =
    currentPath[1] === "superadmin" ? superAdminSidebarLinks : adminSidebarLinks


  const sidebarBottomLinks = sidebarBottom[accountType as UserAccountType] || []

  const handleLinkClick = (url: string) => {
    if (url === "logout") {
      handleLogout();
    } else {
      navigate(url);
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/"
  }

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        {sidebarLinks.map(({ id, title, image, url }) => (
          <div
            onClick={() => navigate(url)}
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              navigate(url)
            }}
            key={id}
            className=" py-[1px] "
            style={{
              background:
                url === activeLink[2]
                  ? "linear-gradient(270deg, #FFF -47.16%, rgba(255, 255, 255, 0.00) 72.88%)"
                  : "",
            }}
          >
            <SidebarLayout title={title} image={image} />
          </div>
        ))}
      </div>

      <div className="mt-auto">
        {sidebarBottomLinks.map(({ id, title, image, url }) => (
          <div
            onClick={() => handleLinkClick(url)}
            role="button"
            tabIndex={0}
            onKeyDown={() => handleLinkClick(url)}
            key={id}
            className=" py-[1px] "
            style={{
              background:
                url === activeLink[2]
                  ? "linear-gradient(270deg, #FFF -47.16%, rgba(255, 255, 255, 0.00) 72.88%)"
                  : "",
            }}
          >
            <SidebarLayout title={title} image={image} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar
