import React from "react"
import ModalPanel from "../../../Components/elements/Modal/Index"
import { BsThreeDots } from "react-icons/bs"
import { NoItem } from "../../../Assets"

interface CommunityLeadersProps {
  image?: string
  name?: string
  role?: string
  // date: string
}

const CommunityLeaders: React.FC<CommunityLeadersProps> = ({
  image,
  name,
  role,
}) => {
  // Check if any of the required props is not available
  if (!name || !role) {
    return (
      <div className="flex flex-col items-center justify-center mt-8 h-[200px]">
        <img src={NoItem} alt="No item" />
        <p className="text-black text-lg font-bold ml-2">No Leader yet</p>
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <div className="flex items-center flex-grow">
        <img
          src={image}
          alt="profile"
          className="w-12 h-12 rounded-full ring-2 ring-white mt-3 mb-0"
        />
        <div className="mx-2 flex-grow-1 flex">
          <div>
            <p className="text-primary text-[12px] font-bold mb-1">{name}</p>
            <p
              className={`rounded-2xl text-[10px] mt-0 text-[#8E8E8E] ${
                role.toLowerCase() === "community leader"
                  ? "text-[#FFA412] border bg-[#FFF8ED] px-2"
                  : ""
              } `}
            >
              Community Leader: <span className="text-primary"> {role}</span>
            </p>
          </div>
        </div>
      </div>{" "}
      {/* <BsThreeDots className=" w-[30px] h-[30px] justify-end" /> */}
    </div>
  )
}

export default CommunityLeaders
