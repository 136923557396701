import React, { ChangeEvent } from "react"

interface FormTextareaProps {
  id?: string
  label?: string
  name?: string
  value?: string
  register?: any
  placeholder?: string
  error?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  className?: string
  disabled?: boolean
  rows?: number
}

const Textarea: React.FC<FormTextareaProps> = ({
  id,
  label,
  name,
  rows,
  value,
  placeholder,
  register,
  error,
  onChange,
  className = "",
  disabled = false,
}) => (
  <div className="flex flex-col">
    <label className="pb-1 text-sm font-bold text-gray-800" htmlFor={name}>
      {label || ""}
    </label>
    <textarea
      className={`w-full h-250px p-2 ${
        error
          ? "border-red-500 bg-primary-gradient focus:ring-red-500 focus:border-red-500"
          : "border-dark-gray focus:ring-dark-gray focus:border-dark-gray"
      } border rounded font-latoRegular text-gray-500 text-sm appearance-none outline-none ${className}`}
      id={name}
      {...register}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      rows={rows || 5}
      cols={8}
    />
    {error && (
      <p className="mt-1 text-xs font-latoRegular text-red-500">{error}</p>
    )}
  </div>
)

export default Textarea
