import axiosInstance from "../../Utils/Https"

export const CreateNewEscrowGroupApi = async (
  payload: any,
  communityId: string,
) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.interceptors.request.use((config) => {
    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`
    }
    return config
  })

  const url = `communities/${communityId}/create-escrow-group`
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const res = await axiosInstance.post(url, payload, config)
  console.log(res.data)
  return res.data
}

export const viewEscrowApi = async (page: number) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = `escrow/community-escrow-groups?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  console.log(res.data)
  return res.data
}

export const viewEscrowByMemberIdApi = async (memberId: string) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = `escrow/escrow-groups?memberUserId=${memberId}`
  const res = await axiosInstance.get(url)
  console.log(res.data)
  return res.data
}


export const viewEscrowMembersApi = async (escrowId: string, 
  page: number, limit: number
  ) => {
  const url = `escrow/${escrowId}/members?page=${page}&limit=${limit}`
  const res = await axiosInstance.get(url)
  console.log(res.data)
  return res.data
}

export const searchEscrowMembersApi = async (escrowId: string, searchData : string) => {
  const url = `/escrow/${escrowId}/members/search?name=${searchData}`
  const res = await axiosInstance.get(url)
  console.log(res.data)
  return res.data
}

export const GetWithdrawalRequestsApi = async (
  escrowId: string,
  page: number,
) => {
  const url = `/escrow/${escrowId}/escrow-withdrawal-requests?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  console.log(res.data)
  return res.data
}