import axiosInstance from "../../Utils/Https"
import { User } from "../../types";
import { toast } from 'react-toastify';

type TSignInRequest = { email: string; password: string }
type TSigninResponse = {
  message: string
  user: User
  accessToken: string
  registered: boolean
}

export const UserSignInApi = async (
  payload: TSignInRequest,
): Promise<TSigninResponse> => {
  const url = "login"
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const UserSignUpApi = async (payload: any) => {
  const bearerToken = localStorage.getItem("atk")

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = "register"
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const ResetUserPasswordApi = async (payload: any) => {
  const bearerToken = localStorage.getItem("atk")

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = "reset-password"
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const InviteNewAdminApi = async (payload: any) => {
  const bearerToken = localStorage.getItem("atk")

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = "invite-new-user"
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const ForgotPasswordApi = async (emailAddress: string) => {
  try {
    const url = "forgot-password";
    const res = await axiosInstance.post(url, { emailAddress });
    toast.success(res.data.message)
    return res.data;
} catch (err: any) {
  toast.error(err.response.data.message)
    throw err;
    
}
}

export const ChangePasswordApi = async (payload: any) => {

  const url = "change-password";
  const res = await axiosInstance.post(url, payload);
  return res.data;
} 

export const ResendResentToken = async (payload: any) => {
  const url = "reset-password/resend-token";
  const res = await axiosInstance.post(url, payload);
  return res.data;
}

export const VerifyOtp = async (payload: any) => {
  const url = "auth/verify-otp";
  const res = await axiosInstance.post(url, payload);
  return res.data;
}

