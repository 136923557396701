import React, { useEffect, useState } from "react"
import { Profile } from "../../../../Assets"
import Table from "../../../../Components/elements/Table"
import GoBack from "../../../../Components/elements/Display/GoBack"
import { viewEscrowApi } from "../../../../Services";
import { EscrowGroup, EscrowGroupDetails } from "../../../../types";
import { BarLoader, BounceLoader, ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import Pagination from "../../../../Components/elements/Pagination";

const EscrowGroups: React.FC = () => {
  const [escrowGroups, setEscrowGroups] = useState<EscrowGroupDetails[] >([])
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>();
  const [pageNum, setPageNum] = useState(1);


  const getViewEscrowApi = async () => {
    try {
      setLoading(true)
      const res = await viewEscrowApi(pageNum)
      console.log(res)
      setEscrowGroups(res.data)
      setLoading(false)
      setPageData(res.metadata)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getViewEscrowApi()
  }, [pageNum])

  const Head = [
    { key: "no", label: "No." },
    { key: "communityName", label: "Escrow name" },
    { key: "member", label: "Escrow Admin" },
    { key: "escrowType", label: "Escrow type" },
    { key: "members", label: "No. of member" },
  ]

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
    <div className="mt-6">
      <GoBack link="/app/communities" />
      <p className="font-bold text-black text-[18px] mt-4">

        List of Escrow Groups
      </p>
      <p className="text-[12px] mt-0">
        You have <span className="text-primary font-bold mr-1"> {escrowGroups?.length}</span> escrow groups
      </p>
      <Table
        headers={Head}
        data={escrowGroups?.map((item: any, index: number) => ({
          id: item.id,
          no: index + 1,
          communityName: item.escrowName,
          member: item.escrowCreator,
          escrowType: item.escrowType,
          members: {
            avatarUrls: item.imageOfFirstThreeMembers.map(
              (item: any) => item.pfpImageUrl
            ),
            count: Math.max(
              item.numberOfMembers - 3,
              0,
            ),
          },
          // communityAdmin: item.escrowAdmin,
        }))}
      />
    </div>
    )}
       <div className="flex justify-center mt-5">
        <Pagination pageData={pageData} setPageNum={setPageNum} />
      </div>
    </div>
  )
}
export default EscrowGroups
