import React, { useEffect, useState } from "react"
import Table from "../../../Components/elements/Table"
import { Profile } from "../../../Assets"
import {
  GetSuperAdminDashboardApi,
  GetSuperAdminEscrowGroupsApi,
} from "../../../Services"
import { EscrowGroupData } from "../../../types"
import { BounceLoader } from "react-spinners"
import Pagination from "../../../Components/elements/Pagination"

const SuperAdminEscrowGroups: React.FC = () => {
  const [escrowGroups, setEscrowGroups] = useState<EscrowGroupData[]>([])
  const [dashboardData, setDashboardData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const fetchEscrowGroups = async () => {
    try {
      const res = await GetSuperAdminEscrowGroupsApi(pageNum)
      const res2 = await GetSuperAdminDashboardApi()
      console.log(res)
      setEscrowGroups(res.data)
      setDashboardData(res2)
      setLoading(false)
      setPageData(res.metaData)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEscrowGroups()
  }, [pageNum])

  const Head = [
    { key: "no", label: "No." },
    { key: "communityName", label: "Escrow name" },
    { key: "members", label: "Members" },
    { key: "communityAdmin", label: "Escrow Admin" },
  ]

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <>
          <div className="mt-6">
            <p className="font-bold text-black text-[18px] mt-4">
              List of Escrow Groups
            </p>
            <p className="text-[12px] mt-0">
              You have{" "}
              <span className="text-primary font-bold">
                {dashboardData.numberOfIntraEscrow}
              </span>{" "}
              Escrow Groups
            </p>
            <Table
              headers={Head}
              data={escrowGroups.map((item: any, index: number) => ({
                id: item.escrowId,
                no: index + 1,
                communityName: item.escrowName,
                members: item.escrowMemberData.totalNumberOfMembers,
                communityAdmin: item.escrowAdmin,
              }))}
            />
          </div>
          <div className="flex justify-center mt-5">
            <Pagination pageData={pageData} setPageNum={setPageNum} />
          </div>
        </>
      )}
    </div>
  )
}
export default SuperAdminEscrowGroups
