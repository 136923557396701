import axiosInstance from "../../Utils/Https"

export const GetSingleCommunityApi = async (
  communityId: string,
  page: number,
) => {
  const url = `super-admin/communities/${communityId}?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetAllCommunityMembersApi = async (communityId: string) => {
  const url = `communities/${communityId}/members`
  const res = await axiosInstance.get(url)
  return res.data
}

export const SuspendCommunityAdminApi = async (id: string) => {
  const url = `community-admins/${id}/suspend-admin?replacementId`
  const res = await axiosInstance.post(url)
  return res.data
}

export const AppointCommunityAdminApi = async (
  communityId: string,
  memberId: string,
) => {
  const url = `communities/${communityId}/members/${memberId}/appoint-admin`
  const res = await axiosInstance.post(url)
  return res.data
}

export const GetCommunityWalletTransactionHistoryApi = async (
  communityId: string,
  page: number,
  type?: undefined | string,
  status?: undefined | string,
) => {
  let url = `communities/${communityId}/wallet/transaction-history?page=${page}&limit=10`
  if (type) {
    url += `&transactionType=${type}`;
  }
  
  if (status) {
    url += `&transactionStatus=${status}`;
  }
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSuperAdminDashboardApi = async () => {
  const url = `super-admin/dashboard`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSuperAdminCommunityFullListApi = async () => {
  const url = `super-admin/communities`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSuperAdminCommunityListApi = async (page?: number) => {
  const url = `super-admin/communities?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const appointCommunityAdminApi = async (
  communityId: string,
  memberId: string,
) => {
  const url = `communities/${communityId}/members/${memberId}/appoint-admin`
  const res = await axiosInstance.post(url)
  return res.data
}

export const GetCommunityAdminListApi = async (page: number) => {
  const url = `super-admin/community-admins?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSuperAdminEscrowGroupsApi = async (page?: number) => {
  const url = `super-admin/escrow-groups?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const escrowGroupDetailsApi = async (escrowId: string) => {
  const url = `/escrow/${escrowId}`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSuperAdminInterCommunityEscrowGroupsApi = async (
  page: number,
) => {
  const url = `super-admin/inter-community-escrow-groups?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const interCommunityEscrowGroupDetailsApi = async (escrowId: string) => {
  const url = `escrow/${escrowId}`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetInterEscrowMembersApi = async (
  communityId: string,
  escrowId: string,
  page: number,
  limit: number,
) => {
  const url = `escrow/${escrowId}/members?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetCommunityWithdrawalRequestsApi = async (
  communityId: string,
  page: number,
) => {
  const url = `super-admin/${communityId}/withdrawal-requests?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetAllLeadersApi = async (page: number) => {
  const url = `get-all-leaders?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const reviewWithdrawalRequestApi = async (
  withdrawalRequestId: string,
  verdict: boolean,
) => {
  const url = `/super-admin/withdrawal-requests/${withdrawalRequestId}/review?verdict=${verdict}`;
  const res = await axiosInstance.get(url)
  return res.data
}

export const sendNotificationToCommunityApi = async (payload: any) => {
  const url = `super-admin/send-notification-to-community`
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const removeAdminPermissionApi = async (
  adminUserId: string,
  payload: any,
) => {
  const url = `super-admin/${adminUserId}/permissions/remove-permissions`
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const addAdminPermissionApi = async (adminUserId: string, payload: any) => {
  const url = `super-admin/${adminUserId}/permissions/add-permissions`
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const getAdminPermissionsApi = async (adminUserId: string) => {
  const url = `/${adminUserId}/get-user-permissions`
  const res = await axiosInstance.get(url)
  return res.data
}

export const sendNotificationToSingleCommunityApi = async (payload: any) => {
  const url = `super-admin/${payload.communityIds}/send-notification-to-single-community`
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const suspendCommunityAdminApi = async (communityAdminUserId: string) => {
  const url = `super-admin/community-admins/${communityAdminUserId}/suspend-admin`
  const res = await axiosInstance.patch(url)
  return res.data
}

export const deleteCommunityAdminApi = async (communityAdminUserId: string) => {
  const url = `super-admin/community-admins/${communityAdminUserId}/delete-admin`
  const res = await axiosInstance.patch(url)
  return res.data
}

export const replaceCommunityAdminApi = async (communityId: string) => {
  const url = `super-admin/${communityId}/replace-admin`
  const res = await axiosInstance.patch(url)
  return res.data
}