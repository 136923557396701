import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Profile, WalletIcon, EscrowGroupImage } from "../../../Assets"
import GoBack from "../../../Components/elements/Display/GoBack"
import SearchInput from "../../../Components/elements/Forms/Search"
import CommunityMembers from "../../../Components/elements/Display/CommunityList"
import { BounceLoader } from "react-spinners"
import {
  GetCommunityWithdrawalRequestsApi,
  escrowGroupDetailsApi,
  viewEscrowMembersApi,
} from "../../../Services"
import { EscrowGroupDetails, WithdrawalRequestProps } from "../../../types"
import Table from "../../app/CommunityAdmin/EscrowGroups/EscrowTable"

const SuperAdminEscrowDetails = () => {
  const location = useLocation()
  const { rowData } = location.state || {}

  console.log(rowData) // Access passed data from location state
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageData, setPageData] = useState<any>()
  const [dashboardData, setDashboardData] = useState<any>(null)
  const [pageNum, setPageNum] = useState(1)
  const [members, setMembers] = useState<any[]>([])
  const [escrowGroupData, setEscrowGroupData] = useState<EscrowGroupDetails>()
  const [withdrawalRequestsData, setWithdrawalRequestsData] = useState<
    WithdrawalRequestProps[]
  >([])
  const [searchTerm, setSearchTerm] = useState<string>("")

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options,
    )
    return formattedDate
  }

  const fetchMembers = async () => {
    try {
      setLoading(true)
      const res = await viewEscrowMembersApi(rowData.id, 1, 20)
      const res2 = await escrowGroupDetailsApi(rowData.id)
      const res3 = await GetCommunityWithdrawalRequestsApi(rowData.id, pageNum)
      console.log(res3)

      setMembers(res)
      setEscrowGroupData(res2)
      setWithdrawalRequestsData(res3)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchMembers()
  }, [])

  const data = withdrawalRequestsData.map((request, index) => ({
    no: index + 1,
    memberProfile: {
      memberName: request.accountDetails.accountName,
      image: Profile,
    },
    withdrawalAmount: request.amount,
    // role :
  }))

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <BounceLoader color="#3B82F6" />
      </div>
    )
  }

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const Head = [
    { key: "no", label: "No." },
    { key: "memberProfile", label: "Member Name" },
    { key: "withdrawalAmount", label: "Withdrawal Amount" },
  ]

  const filteredMembers = members.filter(
    (member) =>
      member.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.lastName.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <div className="flex flex-col mt-4 md:flex-row justify-between">
      <div className="mt-2 md:w-[62%]">
        <GoBack link="/superadmin/escrowGroups" />
        <div className="flex">
          <img src={EscrowGroupImage} alt="profile" className="mt-3 mb-0" />
          <div className="flex flex-col self-center m-3">
            <p className="text-black text-2xl mb-3 font-bold">
              {rowData.communityName}
            </p>
            <div className="flex items-center gap-3">
              <div className="border-2 border-primary flex pr-6 rounded-full pl-0 py-0 justify-between items-center">
                <img
                  src={Profile}
                  alt="profile"
                  className="w-12 h-12 rounded-full"
                />
                <div className="pl-2">
                  <p className="text-primary font-bold">
                    {rowData.communityAdmin}
                  </p>
                  <p className="mt-0">Admin</p>
                </div>
              </div>
              <Link
                to={`/superadmin/EscrowSuperAdminWallet/${rowData.id}`}
                state={{ escrowGroupData }}
              >
                <img
                  src={WalletIcon}
                  alt="wallet icon"
                  className="bg-primary rounded-full w-[50px] h-[50px] p-3"
                  style={{
                    borderRadius: "100%",
                    background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%)`,
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="border-primary mt-6 rounded-md p-2 flex justify-around border-2">
          <div className="flex flex-col items-center">
            <p className="text-xs text-black">Target amount</p>
            <p className="text-xl text-primary font-bold">
              {escrowGroupData?.escrowTargetAmount || 0}
            </p>
          </div>
          <div className="border-r-2 my-1 border-primary">&nbsp;</div>
          <div className="flex flex-col items-center">
            <p className="text-xs text-black">Escrow Expiration</p>
            <p className="text-xl text-primary font-bold">
              {formatDate(escrowGroupData?.escrowExpirationDate || "None")}
            </p>
          </div>
          <div className="border-r-2 my-1 border-primary">&nbsp;</div>
          <div className="flex flex-col items-center">
            <p className="text-xs text-black">Progress</p>
            <p className="text-xl text-primary font-bold">
              {escrowGroupData?.escrowStatus || 0}
            </p>
          </div>
        </div>

        <div className="flex flex-col mt-7">
          <p className="text-[18px] text-black font-bold">Description</p>
          <p className="text-xs text-black">
            {escrowGroupData?.escrowGroupDescription || "No description"}
          </p>
        </div>

        <div className="flex flex-col mt-5">
          <p className="text-[18px] text-black font-bold">
            Withdrawal requests
          </p>
          <div className="justify-between items-center flex">
            <p className="text-[11px]">
              You have{" "}
              <span className="text-primary font-bold">
                {withdrawalRequestsData.length}
              </span>{" "}
              withdrawal requests
            </p>
          </div>

          <Table
            headers={Head}
            data={data.map((item) => ({
              ...item,
              no: `#${item.no}`,
            }))}
          />
        </div>
      </div>
      <div className="pl-2 md:w-[35%]">
        <div>
          <p className="text-[16px] text-black font-bold">
            List of Escrow member(s)
          </p>
          <div className="flex text-[10px] justify-between items-center mb-2">
            <p>
              <span className="text-primary font-bold">
                {filteredMembers.length}
              </span>{" "}
              Escrow members
            </p>
          </div>
        </div>
        <div className="rounded-xl p-3 bg-white">
          <div>
            <SearchInput
              placeholder="Search members"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {filteredMembers.map((member, index) => (
            <CommunityMembers
              key={member.id}
              image={member.profilePictureFileKey}
              initials={member.userInitials}
              name={`${member.firstName} ${member.lastName}`}
              role={
                member?.userDetails?.accountType === "COMMUNITY_MEMBER"
                  ? "Community Leader"
                  : "Member"
              }
              communityMembersList={[]}
              date={member?.createdAt || "null"}
              emailAddress={member.emailAddress || ""}
              phoneNumber={member.phoneNumber || ""}
              communityId={member?.community?.id}
              id={member.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SuperAdminEscrowDetails
