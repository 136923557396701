import React, { useEffect, useState } from "react"
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import * as yup from "yup"
import { HiOutlineCamera } from "react-icons/hi2"
import { BlankPic } from "../../../../Assets"
import Input from "../../../../Components/elements/Forms/Input"
import Textarea from "../../../../Components/elements/Forms/TextArea"
import { MainButton } from "../../../../Components/elements/Button"
import { EditProfileApi, UserProfileApi } from "../../../../Services"
import { User } from "../../../../types"
import { toast } from "react-toastify"


interface FormData {
  avatar: FileList | null | string
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
}

const schema = yup.object().shape({
  avatar: yup
    .mixed()
    .required("Avatar is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string()
  .matches(
    /^(0|\+?234)\d{10}$/,
    "Please enter a valid phone number"
  )
  .required("Phone Number is required")
})

const EditProfile: React.FC = () => {
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
  })
  
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileList = new DataTransfer();
      fileList.items.add(file);
      setValue("avatar", fileList.files);
      setAvatarPreview(URL.createObjectURL(file));
      console.log("File selected: ", file); // Log selected file
      console.log("File sending: ", fileList.files); // Log fileList file
      console.log("FileList length: ", fileList.files.length); // Log FileList length
    }
  };
  
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoading(true)
      console.log(data, "data")
  
       const formData = new FormData();
       if (data.avatar && data.avatar.length > 0) {
          const avatarFile = data.avatar[0]; // Access the first file directly
    formData.append("avatar", avatarFile);
      } else {
        console.log("No avatar file found");
      }
       formData.append("firstName", data.firstName || "");
       formData.append("lastName", data.lastName || "");
       formData.append("phoneNumber", data.phoneNumber || "");

   formData.forEach((value, key) => {
    console.log(key, value);
  });

    console.log(formData);

    const response = await EditProfileApi(formData)
      console.log(response)
      toast.success(response.message)

    } catch (error: any) {
      console.error(error)
      toast.error(error.response?.data?.message || "An error occurred")
    }finally {
      setLoading(false)
    }
  }

  const onError: SubmitErrorHandler<FormData> = (errors, e) => {
    console.error(errors, e)
  }

  const getUserProfileApi = async () => {
    try {
      const res = await UserProfileApi()
      console.log(res)

      if (res) {
        setValue("firstName", res.firstName || null)
        setValue("lastName", res.lastName || null)
        setValue("phoneNumber", res.phoneNumber || null)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserProfileApi()
  }, [])

  return (
    <div>
      <div className="p-3 bg-white rounded-lg mr-3">
        <h5 className="text-lg font-bold text-black">Edit Profile</h5>
        <p className="text-xs text-black">
          Edit and save your personal details
        </p>
      </div>
      <div className="mt-5">
        <form
          className="max-w-xl mx-auto"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="mb-4 relative flex justify-center">
            <div className="w-20 h-20 rounded-full overflow-hidden border-2 border-primary relative">
              <input
                type="file"
                {...register("avatar", {
                  required: "Avatar is required",
                })}
                name="avatar"
                onChange={handleAvatarChange}
                className="w-full bg-white h-full opacity-0 absolute top-0 left-0 cursor-pointer"
              />
              {avatarPreview ? (
                <img
                  src={avatarPreview}
                  alt="Avatar"
                  className="w-full h-full object-contain"
                />
              ) : (
                <div className="w-full h-full  flex items-center justify-center">
                  <img
                    src={BlankPic}
                    alt="blank"
                    className="p-3 bg-white object-contain"
                  />
                </div>
              )}
              {errors.avatar && (
                <span className="text-red-500 text-sm mt-1 ">
                  {errors.avatar.message}
                </span>
              )}
            </div>
            <HiOutlineCamera
              size={30}
              className=" text-white rounded-full p-1.5 cursor-pointer ring-2 ring-white absolute bottom-0 right-60"
              style={{
                background:
                  "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
              }}
            />
          </div>
          <div className="py-3">
            <Input
              register={register("firstName", {
                required: "First Name is required",
              })}
              name="firstName"
              error={errors.firstName?.message}
              label="First Name"
              placeholder="Enter First Name"
              className="bg-white border-white"
            />
          </div>
          <div className="py-3">
            <Input
              register={register("lastName", {
                required: "Last Name is required",
              })}
              name="lastName"
              error={errors.lastName?.message}
              label="Last Name"
              placeholder="Enter Last Name"
              className="bg-white border-white"
            />
          </div>
          <div className="py-3">
            <Input
              register={register("phoneNumber", {
                required: "Phone Number is required",
              })}
              name="phoneNumber"
              error={errors.phoneNumber?.message}
              label="Phone Number"
              type="number"
              placeholder="Enter Phone Number"
              className="bg-white border-white"
            />
          </div>

          <div className="my-8">
            <MainButton type="submit" isLoading={loading}>Save</MainButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditProfile
