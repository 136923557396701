import React, { useEffect, useReducer } from "react"

import { Outlet, useNavigate } from "react-router-dom"
import { AiFillCaretDown } from "react-icons/ai"
import { FundsWalletIcon } from "../../Assets"
import useCurrentPath from "../../Hooks/useCurrentPath"

const AuthLayout = (props: any) => {
  const currentPath = useCurrentPath()
  // console.log("Current Path:", CurrentPath)
  const navigate = useNavigate()
  const initialState = {
    openMobileSideBar: false,
  }
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState,
  )
  const { openMobileSideBar } = state

  useEffect(
    () =>
      // Cleanup method
      () => {
        setState({
          ...initialState,
        })
      },
    [],
  )

  return (
    <div className="fixed grid w-screen  h-screen rounded-lg md:grid-cols-2 ">
      <div className="relative hidden p-5 flex-col justify-between bg-gradient-to-r from-[#0078FF] to-[#0045D8] md:flex ">
        <div
          className="absolute top-0 left-0 w-full h-full z-10"
          style={{
            background:
              "linear-gradient(96deg, rgba(0, 120, 255, 0.00) 28.69%, #0045D8 94.78%)",
          }}
        />
        <div className="pl-12">
          <img src={FundsWalletIcon} alt="logo" className="w-20 h-20" />
        </div>
        <div>
          <div className="pt-10 pl-5">
            <p className="text-white text-3xl">
              Welco<span className="border-b-4">me</span>
            </p>
            <div />
          </div>
          <div className="pl-5">
            <h1
              className=" leading-60 md:text-[110px] lg:text-[150px] text-6xl font-bold md:leading-80"
              style={{
                background:
                  "linear-gradient(96deg, rgba(0, 120, 255, 0.00) 28.69%, #0045D8 94.78%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                lineHeight: "140px",
                fontWeight: "bolder",
                color: "#fff",
              }}
            >
              {currentPath[1] === "superadmin" ? (
                <>
                  Super <br />
                  <div className="pl-4">Admin</div>
                </>
              ) : (
                <>
                  Comm <br />
                  unity <br />
                  Admin
                </>
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between px-4 overflow-scroll overflow-x-hidden  md:items-around ">
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout
