import React from "react"
import { WalletHeaderProps } from "../../../types"
import { RxTarget } from "react-icons/rx"
import { FaRegClock } from "react-icons/fa"

const WalletHeader: React.FC<WalletHeaderProps> = ({
  totalBalance,
  availableBalance,
  withdrawableBalance,
  totalEscrowBalance,
  targetAmount,
  escrowDuration,
  imageUrl,
  updatedAt,
}) => {
  function formatNumber(number?: number): string {
    return number !== undefined ? number.toLocaleString() : ""
  }

  function formatAMPM(date: Date): string {
    const hours = date.getHours() % 12 || 12 // Set hours to 12 if it is 0
    const minutes = date.getMinutes().toString().padStart(2, "0") // Ensure minutes are always two digits

    const ampm = date.getHours() >= 12 ? "pm" : "am"
    const strTime = `${hours}:${minutes}${ampm}`

    return strTime
  }

  function formatLastUpdated(updatedAt: string): string {
    const date = new Date(updatedAt)
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${formatAMPM(date)}`
    return formattedDate
  }

  return (
    <div className="relative mt-5">
      <div />
      <div
        className="inset-0 z-0"
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "262px",
          width: "100%",
          borderRadius: "20px",
        }}
      >
        <div className="relative z-10 p-6 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center">
            <div className="flex justify-center text-white text-sm ">
              {totalEscrowBalance
                ? "Total Escrow Balance"
                : totalBalance
                ? "Total Wallet Balance"
                : ""}
            </div>
            <div className="text-white text-6xl font-bold my-4">
              {totalEscrowBalance
                ? `₦${formatNumber(totalEscrowBalance)}`
                : `₦${formatNumber(totalBalance)}`}
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-white text-[13px] font-light">
              {escrowDuration
                ? "Escrow Duration"
                : availableBalance
                ? "Withdrawable Balance"
                : ""}
            </div>

            <div className="text-white text-5xl font-bold my-4">
              {totalEscrowBalance
                ? `₦${formatNumber(totalEscrowBalance)}`
                : availableBalance
                ? `₦${formatNumber(availableBalance)}`
                : ""}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center text-white text-[13px] font-light">
          <p>Last Updated {updatedAt ? formatLastUpdated(updatedAt) : "now"}</p>
        </div>
      </div>
    </div>
  )
}

export default WalletHeader
