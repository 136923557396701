import React, { ChangeEvent, useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import {
  Profile,
  CommunityIMG,
  WalletIcon,
  EditCom,
  NotificationIcon,
  EscrowGroupImage,
  DeleteIcon,
  Walletbg,
  ProfileWoman,
  ProfileLady,
} from "../../../../Assets"
import {
  MainButton,
  OutlineButton,
} from "../../../../Components/elements/Button"
import GoBack from "../../../../Components/elements/Display/GoBack"
import SearchInput from "../../../../Components/elements/Forms/Search"
import Hero from "../../../../Components/elements/Hero"
import { BsThreeDots } from "react-icons/bs"
import { FaArrowRightLong } from "react-icons/fa6"
import Table from "./EscrowTable"
import Search, { useDebounce } from "../../../../Components/elements/Forms/MembersSearch"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import Input from "../../../../Components/elements/Forms/Input"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import TransactionsTable from "../Wallet/TransactionTable"
import { CgClose } from "react-icons/cg"
import WalletHeader from "../../../../Components/elements/Hero/WalletHeader"
import {
  EscrowGroupDetails,
  SearchEscrowMember,
  Transaction,
  WithdrawalRequestProps,
} from "../../../../types"
import { BeatLoader, BounceLoader } from "react-spinners"
import { GetCommunityWithdrawalRequestsApi, GetEscrowWalletApi, GetEscrowWalletTransactionHistoryApi, GetWithdrawalRequestsApi, escrowGroupDetailsApi, searchEscrowMembersApi, viewEscrowMembersApi } from "../../../../Services"
import CommunityMembers from "../../../../Components/elements/Display/CommunityList"

const EscrowDetails = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openEscrowWalletModal, setOpenEscrowWalletModal] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [members, setMembers] = useState<any[]>([])
  const [escrowGroupData, setEscrowGroupData] = useState<EscrowGroupDetails>()
  const [withdrawalRequestsData, setWithdrawalRequestsData] = useState<
    WithdrawalRequestProps[]
  >([])
  const [walletData, setWalletData] = useState<any>({})
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [searchData, setSearchData] = useState<string | null>(null)
  const debouncedSearchData = useDebounce(searchData || "", 500);
  const [filteredMembersList, setFilteredMembersList] = useState<any[]>([]);
  const [memberLoading, setMemberLoading] = useState(false)
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData(e.target.value);
  };
  const location = useLocation()
  const { rowData } = location.state || {}
  console.log(rowData, "rowData")

  const Head = [
    { key: "no", label: "No." },
    { key: "memberProfile", label: "Member Name" },
    { key: "withdrawalAmount", label: "Withdrawal Amount" },
    { key: "status", label: "Status" },
  ]

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options,
    )
    return formattedDate
  }

  const transformSearchResult = (searchResult: SearchEscrowMember) => ({
    id: searchResult.id, 
    fullName: searchResult.name,
    firstName: searchResult.name.split(' ')[0], 
    lastName: searchResult.name.split(' ')[1] || '',
    emailAddress: searchResult.email,
    phoneNumber: searchResult.phoneNumber,
    profilePictureFileKey: searchResult.imageUrl,
    escrowCreator: searchResult.escrowCreator,
    community:{
      id: ""
    },
    userDetails:{
      accountType: searchResult.accountType
    },
});

  useEffect(() => {
    const fetchSearchedMembers = async () => {
      if (!searchData) {
        setFilteredMembersList(members);
        return;
      }
      setMemberLoading(true);
      try {
        if (rowData.id && debouncedSearchData) {
          const response = await searchEscrowMembersApi(rowData.id, debouncedSearchData);
          console.log(response);
          const transformedData = response.map(transformSearchResult);

          setFilteredMembersList(transformedData);

        } else {
          console.log("escrowId is undefined");
        }
      } catch (error) {
        console.error(error);
      }finally {
        setMemberLoading(false);
      }
    };
    fetchSearchedMembers();
  }, [rowData.id, debouncedSearchData,]); 

  const fetchMembers = async () => {
    try {
      setMemberLoading(true)
      const res = await viewEscrowMembersApi(rowData.id, 1, 10)
      const res2 = await escrowGroupDetailsApi(rowData.id)
      const res3 = await GetWithdrawalRequestsApi(rowData.id, pageNum)
      console.log(res2)
      console.log(res)
      console.log(res3)

      setMembers(res)
      setFilteredMembersList(res);
      setEscrowGroupData(res2)
      setWithdrawalRequestsData(res3)
      setMemberLoading(false)
    } catch (error) {
      console.log(error)
      setMemberLoading(false)
    }
  }

  useEffect(() => {
    fetchMembers()
  }, [])

  const getWalletData = async () => {
    try {
      const res = await GetEscrowWalletApi(rowData.id || "")
      // const res2 = await GetEscrowWalletTransactionHistoryApi(
      //   rowData.id ?? "",
      //   pageNum,
      // )
      console.log(res, "escrow wallet")
      // console.log(res2.transactions[0])

      setWalletData(res)
      // setTransactionsData(res2.transactions)

    } catch (error) {
      console.log(error)
    }
  }
  const getWalletTransactionHistory = async () => {
    try {
      const res = await GetEscrowWalletTransactionHistoryApi(
        rowData.id ?? "",
        pageNum,
      )
      console.log(res.transactions[0])
      setTransactionsData(res.transactions)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWalletData()
  }, [rowData.id])

  useEffect(() => {
    getWalletTransactionHistory()
  }, [pageNum, rowData.id])

  const data = withdrawalRequestsData.map((request, index) => ({
    no: index + 1,
    memberProfile: {
      memberName: request.accountDetails.accountName,
      image: Profile,
    },
    withdrawalAmount: request.amount,
  }))

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <BounceLoader color="#3B82F6" />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col mt-4 md:flex-row justify-between">
        <div className="mt-2 md:w-[62%]">
          <GoBack link="/app/escrowGroups" />
          <div className="flex">
            <img src={EscrowGroupImage} alt="profile" className=" mt-3 mb-0 " />
            <div className="flex flex-col self-center m-3">
              <p className="text-black text-2xl mb-3 font-bold">
                {rowData.communityName}
              </p>
              <div className="flex items-center gap-3">
                <div className="border-2 border-primary flex pr-6 rounded-full pl-0 py-0 justify-between items-center">
                  <img
                    src={Profile}
                    alt="profile"
                    className="w-12 h-12 rounded-full "
                  />
                  <div className="pl-2">
                    <p className="text-primary font-bold">
                      {rowData.member}
                    </p>
                    <p className="mt-0">Admin </p>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={() => setOpenEscrowWalletModal(true)}
                  className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                  style={{
                    background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
                  }}
                >
                  <img
                    src={WalletIcon}
                    alt="delete icon"
                    className="w-full h-full"
                    style={{
                      borderRadius: "100%",
                      background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
                    }}
                  />
                </button>
                <button
                  type="button"
                  onClick={() => setOpenModal(true)}
                  className="bg-error rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                >
                  <img
                    src={DeleteIcon}
                    alt="delete icon"
                    className="w-full h-full"
                    style={{ borderRadius: "100%" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="border-primary mt-6 rounded-md p-2 flex justify-around border-2">
            <div className="flex flex-col items-center">
              <p className="text-xs text-black">Target amount</p>
              <p className="text-xl text-primary font-bold">
                N{escrowGroupData?.escrowTargetAmount || 0}
              </p>
            </div>
            <div className="border-r-2 my-1 border-primary">&nbsp;</div>
            <div className="flex flex-col items-center ">
              <p className="text-xs text-black">Escrow Expiration</p>
              <p className="text-xl text-primary font-bold">
                {" "}
                {formatDate(escrowGroupData?.escrowExpirationDate || "None")}
              </p>
            </div>
            <div className="border-r-2 my-1 border-primary">&nbsp;</div>
            <div className="flex flex-col items-center">
              <p className="text-xs text-black">Progress</p>
              <p className="text-xl text-primary font-bold">
                {escrowGroupData?.escrowStatus || 0}
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-7">
            <p className=" text-[18px] text-black font-bold">Description</p>
            <p className="text-xs text-black">
              {escrowGroupData?.escrowGroupDescription || "No description"}
            </p>
          </div>

          <div className="flex flex-col mt-5">
            <p className=" text-[18px] text-black font-bold">
              Withdrawal requests
            </p>
            <div className="justify-between items-center flex">
              <p className="text-[11px]">
                You have{" "}
                <span className="text-primary font-bold">
                  {" "}
                  {withdrawalRequestsData.length}
                </span>{" "}
                withdrawal requests
              </p>
              {/* <button type="button" className="text-primary flex items-center">
                See all
                <span className="ml-2">
                  <FaArrowRightLong />
                </span>
              </button> */}
            </div>

            <Table
              headers={Head}
              data={data.map((item) => ({
                ...item,
                no: `#${item.no}`,
              }))}
            />
          </div>
        </div>
        <div className="pl-2 md:w-[35%]">
          <div className="">
            <p className="text-[16px] text-black font-bold">
              List of Escrow members
            </p>
            <div className="flex text-[10px] justify-between items-center mb-2">
              <p>
                <span className="text-primary font-bold">
                  {members.length}{" "}
                </span>
                escrow member(s)
              </p>
              {/* <button type="button" className="text-primary">
                Select members
              </button> */}
            </div>
          </div>
          <div className="rounded-xl p-3 bg-white">
            <div className="">
              <Search
              type="text"
              value={searchData || ""}
              placeholder="Search"
              onChange={handleSearchChange}
              />
            </div>
            {memberLoading ? 
        <div className="flex justify-center items-center h-[70vh]">
                <BeatLoader color="#3B82F6"/> 
                </div>
                :
            (filteredMembersList?.map((member, index) => (
              <CommunityMembers
                key={member.id}
                image={member.profilePictureFileKey}
                initials={member.userInitials}
                name={`${member.firstName} ${member.lastName}`}
                role={
                  member?.userDetails?.accountType === "COMMUNITY_MEMBER"
                    ? "Community Leader"
                    : "Member"
                }
                communityMembersList={[]}
                date={member?.createdAt || "null"}
                emailAddress={member.emailAddress || ""}
                phoneNumber={member.phoneNumber || ""}
                communityId={member?.community.id}
                id={member.id}
              />
            )))}
          </div>
        </div>
      </div>
      <ModalPanel closeModal={() => setOpenModal(false)} open={openModal}>
        <div className="flex flex-col justify-center my-10 mx-20 ">
          <p className="mb-10 text-black text-[15px] text-center">
            Are you sure you want to delete this{" "}
            <span className="font-bold">{rowData.communityName}</span> escrow group?
          </p>
          <div className="flex justify-between">
            <MainButton className="mr-2">Yes</MainButton>
            <OutlineButton
              onClick={() => setOpenModal(false)}
              className=" ml-2 bg-[#EEF6FF] border-2 border-primary rounded-full hover:text-black "
            >
              No
            </OutlineButton>
          </div>
        </div>
      </ModalPanel>
      <ModalPanel
        open={openEscrowWalletModal}
        closeModal={() => setOpenEscrowWalletModal(false)}
        modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
      >
        <div className="">
          <div className="relative">
            <WalletHeader
             totalEscrowBalance={walletData.balance ? walletData.balance : "0"}
             targetAmount={walletData.targetAmount ? walletData.targetAmount : "0"}
             escrowDuration={10}
             imageUrl={Walletbg}
            />
         
            <button
              type="button"
              className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-3 right-7  z-10"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
              onClick={() => setOpenEscrowWalletModal(false)}
            >
              <CgClose size={20} />
            </button>
          </div>
          <div className="mt-10 ">
            <p className="text-black text-[15px] font-bold">
              {" "}
              Transactions History
            </p>
          </div>

          <TransactionsTable
          transactions={transactionsData.map((transaction) => ({
            ...transaction,
            date: transaction.createdAt
              ? formatDate(transaction.createdAt)
              : "",
            status: transaction.transactionType || "",
            amount: transaction.transactionAmount || 0,
            description: transaction.transactionStatus || "",
            // details: transaction.transactionDetails || "",
          }))}
        />
        </div>
      </ModalPanel>
    </>
  )
}

export default EscrowDetails
