// Update CommunityMembers.tsx
import React, { ChangeEvent, useEffect, useState } from "react"
import { Member,
  MemberWallet,
  Transaction,
  UserAccountType } from "../../../../types"
import { Modal, Button } from "rsuite"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import {
  MainButton,
  OutlineButton,
} from "../../../../Components/elements/Button"
import Input from "../../../../Components/elements/Forms/Input"
import {
  WalletIcon,
  ReplaceIcon,
  EscrowGroupBlue,
  Profile,
  Walletbg,
} from "../../../../Assets"
import { Switch } from "@headlessui/react"
import { RiGroupLine, RiUserAddLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import Search, { useDebounce } from "../../../../Components/elements/Forms/MembersSearch"
import TransactionsTable from "../Wallet/TransactionTable"
import GoBack from "../../../../Components/elements/Display/GoBack"
import { CgClose } from "react-icons/cg"
import WalletHeader from "../../../../Components/elements/Hero/WalletHeader"
import CustomSwitch from "../../../../Components/elements/Forms/Switch"
import {
  GetSubWalletTransactionHistoryApi,
  addPermissionApi,
  getPermissionsApi,
  getSubWalletApi,
  makeMemberCommunityAdminApi,
  removePermissionApi,
  searchCommunityApi
} from "../../../../Services"
import { toast } from "react-toastify"


interface CommunityMembersProps {
  communityMembersList: Member[]
  communityId: string | undefined
  image: string
  name: string
  role: string
  date: string
  memberId: string
  isSelectMode: boolean
  setRefreshComponent: React.Dispatch<React.SetStateAction<boolean>>
}
interface barSwitchProps {
  loggingIn: boolean;
  fundingSubWallet: boolean;
  fundingCommunityWallet: boolean;
  creatingEscrow: boolean;
  joiningEscrow: boolean;
  makingLoanRequest: boolean;
  [key: string]: boolean;
}

const barSwitchData = [
  { label: 'Bar from logging in', name: 'loggingIn' },
  { label: 'Bar from funding sub-wallet', name: 'fundingSubWallet' },
  { label: 'Bar from funding community wallet', name: 'fundingCommunityWallet' },
  { label: 'Bar from creating escrow', name: 'creatingEscrow' },
  { label: 'Bar from joining escrow', name: 'joiningEscrow' },
  { label: 'Bar from making loan request', name: 'makingLoanRequest' },
];

const CommunityMembers: React.FC<CommunityMembersProps> = ({
  communityMembersList,
  communityId,
  image,
  name,
  role,
  date,
  memberId,
  isSelectMode,
  setRefreshComponent
}) => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [memberWallet, setMemberWallet] = useState<MemberWallet>()
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)  
  const [memberIdInLoading, setMemberIdInLoading] = useState<string | null>(null);
  const [replaceLeaderModal, setReplaceLeaderModal] = useState(false)
  const [openMemberWallet, setOpenMemberWallet] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [barSwitch, setBarSwitch] = useState<barSwitchProps>({
    loggingIn: false,
    fundingSubWallet: false,
    fundingCommunityWallet: false,
    creatingEscrow: false,
    joiningEscrow: false,
    makingLoanRequest: false,
  });

  // const handleSwitchChange = (name: keyof barSwitchProps) => {
  //   setBarSwitch((prevStates) => ({
  //     ...prevStates,
  //     [name]: !prevStates[name],
  //   }));
  // };
  

  const resetSwitches = () => {
    setBarSwitch({
      loggingIn: false,
      fundingSubWallet: false,
      fundingCommunityWallet: false,
      creatingEscrow: false,
      joiningEscrow: false,
      makingLoanRequest: false,
    });
  };

 const createdAtDate = new Date(date);
  const currentDate = new Date();
  const daysDifference = Math.floor((currentDate.getTime() - createdAtDate.getTime()) / (1000 * 60 * 60 * 24));
  const weeksDifference = Math.floor(daysDifference / 7);

  let dateJoined = "";
  if (weeksDifference === 1) {
    dateJoined = `${weeksDifference} week ago`;
  } else if (weeksDifference >= 1) {
    dateJoined = `${weeksDifference} weeks ago`;
  } else if (daysDifference === 1) {
    dateJoined = `${daysDifference} day ago`;
  } else {
    dateJoined = `${daysDifference} days ago`;
  }

  const userRole = communityMembersList?.map((member) =>
    member?.communityLeader === true
      ? "Community Leader"
      : "Member",
  )

  const handleSelectLeader = async (memberId: string) => {
    try {
      setMemberIdInLoading(memberId);
      setLoading(true)
console.log(communityId,"Community Id", memberId, "member Id")
     const res = await makeMemberCommunityAdminApi(communityId, memberId);
console.log(communityId,"Community Id", memberId, "member Id", res)
      toast.success(res.message)
      setReplaceLeaderModal(false);
      setRefreshComponent((prev) => !prev)

    } catch (error: any) {
      console.error( error);
      toast.error(error.response?.data?.message || "An error occured")
      
    } finally{
      setMemberIdInLoading(null);
      setLoading(false);
    }
  };

  // useEffect(() => {
    const fetchSubWallet = async () => {
      // setLoading(true);
      try {
        if (memberId && communityId) {
          const response = await getSubWalletApi(communityId, memberId);
          const response2 = await GetSubWalletTransactionHistoryApi(communityId, memberId, pageNum);
          console.log(response);
          console.log(response2);
          setMemberWallet(response)
          setTransactionsData(response2)
          setPageData(response2.metadata)
        } else {
          console.log("memberId is undefined");
        }
      } catch (error) {
        // setLoading(false);
        console.error(error);
      }
    };
  
    // fetchSubWallet();
  // }, [communityId, memberId, pageNum]); 

  // useEffect(() => {
    const fetchPermissions = async () => {
      try {
        if (memberId) {
          const response = await getPermissionsApi(memberId);
          console.log(response);
  
          // Mapping object to map permissions to switches and set them to true if not present
          const permissionToSwitchMap: { [key: string]: keyof barSwitchProps } = {
            // view_community_members: '',
            fund_wallet: 'fundingSubWallet',
            fund_community_wallet: 'fundingCommunityWallet',
            login: 'loggingIn',
            join_escrow: 'joiningEscrow',
            create_escrow: 'creatingEscrow',
            make_loan_request: 'makingLoanRequest',
          };
  
          // Initialize a new object to hold mapped switches
          const mappedSwitches: barSwitchProps = {} as barSwitchProps;
  
          // Map permissions from API response to corresponding switches
          Object.keys(permissionToSwitchMap).forEach(permission => {
            const switchName = permissionToSwitchMap[permission];
            if (response.permissions.includes(permission)) {
              mappedSwitches[switchName] = false; 
            } else {
              mappedSwitches[switchName] = true; 
            }
          });
  
          // Set the state of barSwitch based on mapped switches
          setBarSwitch(mappedSwitches);
        } else {
          console.log("memberId is undefined");
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    // fetchPermissions();
  // }, [memberId]);
   
  const viewCommunityMember = () => {
    if (!isSelectMode) {
      setOpenModal(true);
    }
    fetchSubWallet();
    fetchPermissions();
  }
  const getPermissionNameFromSwitchName = (switchName: string): string => {
    // Mapping switch names to permission names
    const switchToPermissionMap: { [key: string]: string } = {
      loggingIn: "login",
      fundingSubWallet: "fund_wallet",
      fundingCommunityWallet: "fund_community_wallet",
      creatingEscrow: "create_escrow",
      joiningEscrow: "join_escrow",
      makingLoanRequest: "make_loan_request",
    };

    return switchToPermissionMap[switchName];
  };

  const handleSwitchChange = async (name: keyof barSwitchProps) => {
    const newValue = !barSwitch[name]
    const permissionName = getPermissionNameFromSwitchName(name as string)
  
    try {
      if (newValue) {
        await removePermissionApi(communityId, memberId, {
          permissions: [permissionName],
        })
      } else {
        await addPermissionApi(communityId, memberId, {
          permissions: [permissionName],
        })
      }
  
      setBarSwitch((prevStates) => ({
        ...prevStates,
        [name]: newValue,
      }))
  
      toast.success("Permission updated successfully")
    } catch (error: any) {
      console.error("Error occurred while updating permissions:", error)
      toast.error(error.response?.data?.message || "An error occurred")
    }
  }
  

  const handleConfirmationButtonClick = async () => {
    setIsLoading(true); 
    try {
      const permissionsToAdd = Object.entries(barSwitch)
        .filter(([_, value]) => value)
        .map(([key]) => getPermissionNameFromSwitchName(key));

      const permissionsToRemove = Object.entries(barSwitch)
        .filter(([_, value]) => !value)
        .map(([key]) => getPermissionNameFromSwitchName(key));

      // Call the addPermissionApi with permissions to add
      if (permissionsToRemove.length > 0) {
        await addPermissionApi(communityId, memberId, {
          permissions: permissionsToRemove,
        });
      }

      // Call the removePermissionApi with permissions to remove
      if (permissionsToAdd.length > 0) {
        await removePermissionApi(communityId, memberId, {
          permissions: permissionsToAdd,
        });
      }
      toast.success("Permissions updated successfully");
      setConfirmModal(false)
      setOpenModal(false)
    } catch (error: any) {
      console.error("Error occurred while updating permissions:", error);
      toast.error(error.response?.data?.message || "An error occurred");
      setConfirmModal(false)
      // Handle error
    } finally{
      setIsLoading(false); 
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-UK",
      options,
    )
    return formattedDate
  }
  const getInitials = (name: string) => {
    const nameArray = name?.trim().split(" ");
    if (nameArray.length === 1) return nameArray[0][0].toUpperCase();
    return (
      nameArray[0][0].toUpperCase() + nameArray[nameArray.length - 1][0].toUpperCase()
    );
  };
console.log(name, "name")
  return (
    <>
      <div className="flex items-center flex-grow">
         {/* {image ? (
        <img
          src={image}
          alt="profile"
          className="w-12 h-12 rounded-full ring-2 ring-white mt-3 mb-0"
        />
      ) : (
        <div
          className="w-12 h-12 rounded-full ring-2 ring-white mt-3 mb-0 flex items-center justify-center"
          style={{
            background:
              "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
          }}
        >
          <span className="text-white text-lg font-bold">{getInitials(name)}</span>
        </div>
      )} */}
      
       {image ? (
          <img
            src={image}
            alt="profile"
            className="w-10 h-10 rounded-full ring-2 ring-white mt-3 mb-2"
          />
        ) : (
          <div className="w-10 h-10 items-center justify-center text-white flex rounded-full ring-2 ring-white mt-3 mb-2 bg-primary">
            {/* {getInitials(name)} */}
            VN
          </div>
        )}
        <div className="mx-2 flex-grow-1">
          <p className="text-primary text-[12px] font-bold mb-1">{name}</p>
          <p
            className={`rounded-2xl text-[10px] mt-0 text-[#8E8E8E] ${
              role.toLowerCase() === "community leader"
                ? "text-[#FFA412] border bg-[#FFF8ED] px-2"
                : ""
            } `}
          >
            {role}
          </p>
        </div>
        <button
          type="button"
          className="text-primary underline text-[13px] ml-auto"
          onClick={() => viewCommunityMember() }
        >
          View
        </button>
      </div>
      <ModalPanel open={openModal} closeModal={() => setOpenModal(false)}>
        <div className="mx-4">
          <div className="flex justify-between mb-2">
            <p className="text-black text-[18px] font-bold">Community Member</p>
            <button
              type="button"
              className="text-black"
              onClick={() => {
                setOpenModal(false)
                // resetSwitches();
                }}>
              <CgClose size={25} />
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={image}
                alt="profile"
                className="w-12 h-12 rounded-full ring-2 ring-primary mt-3 mb-0"
              />
              <div className="mx-2">
                <p className="text-primary text-[16px] font-bold mb-1 mt-0">
                  {name}
                </p>
                <p
                  className={`rounded-2xl text-[12px] mt-0 mb-1 text-[#8E8E8E] ${
                    role.toLowerCase() === "community leader"
                      ? "text-[#FFA412] border bg-[#FFF8ED] px-2 text-center"
                      : ""
                  } `}
                > {role}
                  {/* {role.toLowerCase() === "member" ? `Community ${role}` : role} */}
                </p>
                <i  className="text-[#8E8E8E] text-[12px] mt-0">Date joined: {dateJoined}</i>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setOpenMemberWallet(true)
                setOpenModal(false)
              }}
              className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
              style={{
                background:
                  "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
              }}
            >
              <img
                src={WalletIcon}
                alt="edit icon"
                className="w-full h-full"
                style={{ borderRadius: "100%" }}
              />
            </button>
          </div>

          <div className="flex my-4 justify-between">
            <button
              onClick={() => navigate(`/app/escrowGroups/${memberId}`)}
              type="button"
              className="flex items-center gap-2 justify-center w-full mr-2 border-2 h-10 bg-[#EEF6FF] text-primary rounded-full py-6"
            >
              <img src={EscrowGroupBlue} alt="escrow group icon" />
              Escrow Groups
            </button>
            <button
              onClick={() => {
                setReplaceLeaderModal(true)
                setOpenModal(false)
              }}
              type="button"
              className="flex items-center gap-2 justify-center w-full ml-2 border h-10  text-white rounded-full py-6"
              style={{
                background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
              }}
            >
              <img src={ReplaceIcon} alt="replace icon" />
              Replace Leader
            </button>
          </div>

<div className="mt-4">
          {barSwitchData.map((barItem, index) => (
          <CustomSwitch
            key={index}
            checked={barSwitch[barItem.name]}
            onChange={() => handleSwitchChange(barItem.name as keyof barSwitchProps)}
            label={barItem.label}
          />
      ))}
</div>
          <div className="flex justify-end my-6">
            <MainButton 
            // onClick={() => setConfirmModal(true)}
            >
              Suspend Community Member
            </MainButton>
          </div>
        </div>
      </ModalPanel>
      <ModalPanel
        closeModal={() => setConfirmModal(false)}
        open={confirmModal}
        modalSize="md:w-[30%] lg:w-[30%] xl:w-[30%]"
      >
        <div className="flex flex-col justify-center my-7 mx-4 ">
          <p className="mb-10 text-black text-[15px] text-center">
            Are you sure you want to suspend member from Funds Community?
          </p>
          <div className="flex justify-between">
            <MainButton className="mr-2" onClick={handleConfirmationButtonClick} isLoading={isLoading}>Yes</MainButton>
            <OutlineButton
              onClick={() => setConfirmModal(false)}
              className=" ml-2 bg-[#EEF6FF] border-2 border-primary rounded-full hover:text-black "
            >
              No
            </OutlineButton>
          </div>
        </div>
      </ModalPanel>
      <ModalPanel
        modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
        open={openMemberWallet}
        closeModal={() => setOpenMemberWallet(false)}
        // closeButton
      >
        <div className="">
          <div className="relative">
            <WalletHeader
             totalBalance={memberWallet?.balance || 0}
             imageUrl={Walletbg}
             updatedAt={memberWallet?.updatedAt}
            />
          
            <button
              type="button"
              className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-5 right-7  z-10"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
              onClick={() => setOpenMemberWallet(false)}
            >
              <CgClose size={20} />
            </button>
          </div>
          <div className="mt-32">
            <p className="text-black text-[15px] font-bold">
              Transactions History
            </p>
          </div>
          <TransactionsTable 
              transactions={transactionsData.map((transaction) => ({
                ...transaction,
                date: transaction.createdAt
                  ? formatDate(transaction.createdAt)
                  : "",
                status: transaction.transactionType || "",
                amount: transaction.transactionAmount || 0,
                description: transaction.transactionStatus || "",
                // details: transaction.transactionDetails || "",
              }))}
              />
        </div>
      </ModalPanel>
      <ModalPanel
        open={replaceLeaderModal}
        closeModal={() => setReplaceLeaderModal(false)}
        modalSize="md:w-[60%] lg:w-[60%] xl:w-[60%]"
      >
        <div className="mx-1">
          <div className="flex justify-between ">
            <div>
              <p className="text-black text-[18px] font-bold mb-0">
                Replace Leader
              </p>
              <p className="text-[12px] mt-0">
                Select community member to replace leader
              </p>
            </div>
            <button
              type="button"
              className="text-black"
              onClick={() => setReplaceLeaderModal(false)}
            >
              <CgClose size={25} />
            </button>
          </div>

          <div className="mt-3">
            <Search 
            />
          </div>

          {communityMembersList?.map((member, index) => (
            <div key={index} className="flex justify-between items-center mt-2">
              <div className="flex items-center">
                <div>
                  <img
                    src={member?.memberImageUrl || "null"}
                    alt="profile"
                    className="w-12 h-12 rounded-full mt-3 mb-0"
                  />
                </div>
                <div className="mx-2">
                  <p className="text-black text-[12px] mt-0 font-bold">
                  {`${member?.user?.firstName || ''} ${member?.user?.lastName || ''}`.trim() || "null"}

                  </p>
                  <p className="rounded-2xl text-[10px] mt-0 text-[#8E8E8E] ">
                  {userRole[index] || "null"}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-black">{member?.user?.emailAddress}</p>
              </div>
              <div className="">
                <MainButton className="px-6 " 
                isLoading={loading && memberIdInLoading === member?.user?.id}
                onClick={() => handleSelectLeader(member?.user?.id || "")}>Select</MainButton>
              </div>
            </div>
          ))}
        </div>
      </ModalPanel>
    </>
  )
}

export default CommunityMembers
