import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { SearchIcon } from "../../../Assets";

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

interface FormInputProps {
  type?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Search: React.FC<FormInputProps> = ({
  type = "text",
  name,
  value,
  placeholder = "Search",
  required = false,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  const handleInputClick = (e: MouseEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="flex flex-col">
      <div
        className={`flex items-center rounded-md relative border ${
          isFocused || value
            ? "border-gray bg-[#F6F6F6] focus-within:ring-gray focus-within:border-gray"
            : "border-0 bg-[#F6F6F6]"
        } p-3`}
      >
        <input
          className="flex-1 bg-transparent focus:outline-none border-none focus:ring-0 focus:border-0 appearance-none outline-none text-dark-gray font-latoRegular text-sm"
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          required={required}
          onClick={handleInputClick}
          onBlur={handleInputBlur}
        />
        <div className="absolute right-3 top-3">
          <img src={SearchIcon} alt="Search" />
        </div>
      </div>
    </div>
  );
};

export default Search;
