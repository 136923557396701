/// <reference types="node" />
import React, { useEffect, useRef, useState } from "react"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"
import OtpInput from "react-otp-input"
import { ResendResentToken, ResetUserPasswordApi, VerifyOtp } from "../../../Services"
import { toast } from "react-toastify"

type PageProps = {
  handleNext: () => void
}

const Otp = ({ handleNext }: PageProps) => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState<string[]>(["", "", "", ""])
  const email = localStorage.getItem("emailAddress")
  const [seconds, setSeconds] = useState(299)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState("")
  const [isActive, setIsActive] = useState(false)
  // const formRef = useRef<HTMLFormElement>(null)

  const toggleActive = () => {
    setIsActive(!isActive)
  }

  const textClass = isActive ? "text-purple-800" : "text-primary"

  const resetTimer = () => {
    setSeconds(299)
  }

  const resendOtp = async (emailAddress: string) => {
    const payload = {
      email: emailAddress,
    }
    try {
      const res = await ResendResentToken(payload)
      console.log(res)
      toast.success(res.message)
      resetTimer()
    } catch (error: any) {
      if (error.response.data.error) {
        toast.error(error?.response?.data?.error?.message)
      }
    }
  }

  const verifyUserByOtp = async (emailAddress: string, otp: string) => {
    const payload = {
      email: emailAddress,
      otp,
    }
    try {
      const res = await ResetUserPasswordApi(payload)
      console.log(res)
      localStorage.setItem("emailAddress", emailAddress)

    } catch (error: any) {
      console.log(error);
      
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const emailAddress = email as string
    const otpValue = otp.join("")

    if (otpValue.length === 0) {
      setError("OTP is required")
      return
    }
    if (otpValue.length < 4) {
      setError("OTP must be 4 digits")
      return
    }
    setError("")

    // const data = { emailAddress, otp: otpValue }
    verifyUserByOtp(emailAddress, otpValue)
  }

  useEffect(() => {
    const otpValue = otp.join("")
    if (otpValue.length === 4) {
      const verifyOtp = async () => {
        const payload = {
          emailAddress: email,
          otp: parseInt(otpValue, 10),
        }
        try {
          const res = await VerifyOtp(payload)
          console.log(res)
          localStorage.setItem("otp", otpValue)
          localStorage.setItem("resetPasswordToken", res?.resetPasswordToken)
          handleNext()
          toast.success(res.message)
        } catch (error: any) {
          console.log(error);
        toast.error(error?.response?.data?.message)
          
        }
      }
    verifyOtp();
    }
  }, [otp])

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (seconds > 0) {
      setDisabled(true) // Disable the button while the timer is running
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)
    } else {
      setDisabled(false) // Enable the button after the timer is complete
    }

    return () => clearInterval(intervalId)
  }, [seconds])

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit} className="w-full md:w-[60%]">
        <h3 className="text-primary text-2xl">OTP</h3>
        <p className="text-md">Enter OTP Code sent to your email</p>

        <div className="flex flex-col items-center justify-center mt-[48px]">
          <OtpInput
            value={otp.join("")}
            onChange={(value) => setOtp(value.split(""))}
            numInputs={4}
            renderInput={(props) => (
              <input
                {...props}
                type="number"
                inputMode="numeric"
                className="otp-input focus:outline-none bg-off-white border focus:border-primary focus:bg-[#DFE9F5]/30"
                style={{
                  width: "50px",
                  height: "50px",
                  fontSize: "1.5rem",
                  margin: "0 0.5rem",
                  borderRadius: "0.25rem",
                  textAlign: "center",
                }}
              />
            )}
          />
          {error && (
            <p className=" text-xs font-latoRegular text-primary mt-2 ">
              {error}
            </p>
          )}
        </div>
        <div
          tabIndex={0}
          onKeyDown={() => resendOtp(email as string)}
          className="mt-5 text-center text-primary align-center cursor-default"
          onClick={() => resendOtp(email as string)}
          role="button"
        >
          <button
            type="button"
            className={`cursor-pointer text-md md:text-[16px] text-red-500  mt-[13px] ${textClass}`}
            // onClick={toggleActive}
          >
            {seconds > 0 ? (
              <span className="text-black">{`${(Math.floor(seconds / 60) % 60).toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`}</span>

            ) : (
              "OTP code has expired"
            )}
          </button>
        </div>
        <div
          tabIndex={0}
          // onKeyDown={() => resendOtp(email as string)}
          className="mt-2 text-center align-center cursor-default text-md md:text-[14px]"
          // onClick={() => resendOtp(email as string)}
          role="button"
        >
            {seconds > 0 ?
          "Didn't receive OTP code?" : "Need another OTP code?"} &nbsp;
          <button
            type="button"
            className={`cursor-pointer text-md md:text-[14px] hover:text-[#0148DA]  mt-[13px] ${textClass}`}
            onClick={() => resendOtp(email as string)}
          >
            Resend
          </button>
        </div>
      </form>
    </div>
  )
}

export default Otp
