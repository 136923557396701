import axios, { InternalAxiosRequestConfig } from "axios"
import { toast } from "react-toastify"
import store from "store"

const baseURL = "https://fundsflow-api.peachblossoms.ng"

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = store.get("atk")

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

const successHandler = (response: any) => {
  const token = response.headers["x-access-token"]

  if (token) {
    store.set("atk", token)
  }
  return response
}

const errorHandler = (error: any) => Promise.reject(error)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 500) {
        toast.error("Server Error")
      }
      // else if (error.response.status === 400) {
      //   toast.error("Bad Request")
      // }
     else if (error.response.status === 401) {
        store.clearAll()
        localStorage.clear()
        window.location.href = "/login"
        toast.error("Session expired, please login again")
      }
    } else {
      toast.error("Network Error")
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
