import React, { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import Input from "../../../Components/elements/Forms/Input"
import { MainButton } from "../../../Components/elements/Button"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ForgotPasswordApi } from "../../../Services"
import { toast } from "react-toastify"

type PageProps = {
  handleNext: () => void
}

type FormProps = {
  emailAddress: string
}

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
})

function ForgotPassword({ handleNext }: PageProps) {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      console.log(data)
      setLoading(true)
      const res = await ForgotPasswordApi(data.emailAddress)
      console.log(res)
      localStorage.setItem("emailAddress", data.emailAddress);
      handleNext()
    } catch (error) {
      console.log(error)
      toast.error( "An error occurred") 
    }
    setLoading(false)
  }

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[60%]">
        <h3 className="text-primary text-2xl">Forgot Password</h3>
        <p className="text-md">Enter registered email to reset password</p>

        <div className="py-5">
          <Input
            register={register("emailAddress", {
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
            placeholder="Enter Email"
            label="Email"
            onChange={(e) => setValue("emailAddress", e.target.value)}
            className="bg-off-white"
            name="emailAddress"
          />
          {errors.emailAddress && (
            <p className="text-xs font-latoRegular text-primary mt-2">
              {errors.emailAddress.message}
            </p>
          )}
        </div>

        <div className="py-10">
          <MainButton type="submit" isLoading={loading}>Reset Password</MainButton>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
