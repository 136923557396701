import React from "react"
import { MainButton } from "../Button"
import ModalPanel from "../Modal/Index"
import { CgClose } from "react-icons/cg";


interface TransactionModalProps {
    closeModal: () => void
    open: boolean
  type: string
  status: string
  amount: string | number | undefined
  reference?: string
  user?: {
    name: string
    role: string
  };
}



const TransactionModal = ({ type, status, amount, reference, user, closeModal, open }: TransactionModalProps) => {
  let statusColor: string;
switch (status) {
  case "Approved":
  case "Completed":
  case "SUCCESSFUL":
    statusColor = "text-[#03A413]";
    break;
  case "Pending":
    statusColor = "text-alert";
    break;
  default:
    statusColor = "text-[#E10000]";
    break;
}
return (
    <ModalPanel
    open={open}
    closeModal={closeModal}
  >
    <div className="">
      <div className="flex items-center justify-between">
        <p className="text-black text-[18px] font-bold mb-0">
          Transaction Receipt
        </p>
        <button
          type="button"
          className="text-black"
          onClick={closeModal}
        >
          <CgClose size={25} />
        </button>
      </div>
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <p className="font-bold text-black">Transaction type:</p>
          <p className="text-black text-[15px]">{type}</p>
        </div>
        <div className="flex items-center justify-between mt-3">
          <p className="font-bold text-black">Transaction status:</p>
          <p className={`text-[15px] ${statusColor}`}>{status}</p>
        </div>
        <div className="flex items-center justify-between mt-3">
          <p className="font-bold text-black">Amount:</p>
          <p className="text-black text-[15px]">₦{amount}</p>
        </div>
        {/* <div className="flex justify-between mt-3.5 ">
          <p className="font-bold text-black">Sender/Receiver:</p>
          <div className="flex flex-col text-right">
            <p className="text-black text-[15px]">{user?.name}</p>
            <p className="text-[12px] mt-0 text-[#8E8E8E]">{user?.role}</p>
          </div>
        </div> */}
         <div className="flex items-center justify-between mt-3">
          <p className="font-bold text-black">Reference:</p>
          <p className="text-black text-[15px]">{reference}</p>
        </div>
      </div>
    </div>
  </ModalPanel>
)}

export default TransactionModal
