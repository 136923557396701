import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MainButton,
  RejectButton,
} from "../../../../Components/elements/Button";
import ModalPanel from "../../../../Components/elements/Modal/Index";
import { CgClose } from "react-icons/cg";
import {
  CopyIcon,
  NoItem,
  ProfileGirl,
  WalletIconBlue,
  Walletbg,
} from "../../../../Assets";
import { copyToClipboard, formatDate } from "../../../../Utils/Helper";
import WalletHeader from "../../../SuperAdmin/Wallet/superAdminWalletHeader";
import TransactionsTable from "../../../SuperAdmin/Wallet/TransactionTable";
import { MemberWallet, Transaction } from "../../../../types";
import { reviewWithdrawalRequestApi } from "../../../../Services";
import { toast } from "react-toastify";

interface TableProps {
  headers: Array<{ key: string; label: string }>;
  data: Array<{
    [key: string]:
      | string
      | number
      | Array<string>
      | { count: number }
      | { name: string }
      | { role: string }
      | { image: string }
      | { memberName: string }
      | { email: string }
      | { phoneNumber: string }
      | null;
  }>;
}

interface FormValues {
  verdict: boolean;
}

const Table: React.FC<TableProps> = ({ headers, data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openWithdrawalRequest, setOpenWithdrawalRequest] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [openEscrowWalletModal, setOpenEscrowWalletModal] = useState(false);
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([]);
  const [memberWallet, setMemberWallet] = useState<MemberWallet>();
  const { handleSubmit } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      const response = await reviewWithdrawalRequestApi(
        selectedRowData.withdrawalRequestId,
        data.verdict
      );
      console.log(response);
      setOpenWithdrawalRequest(false);
      toast.success("Withdrawal request reviewed successfully");
    } catch (error) {
      console.error('Error reviewing withdrawal request:', error);
      toast.error("An error occurred while reviewing withdrawal request");
    }
  };

  const handleViewClick = (rowData: any) => {
    setSelectedRowData(rowData);
    setOpenWithdrawalRequest(true);
  };

  if (data.length === 0) {
    return (
      <div className="flex items-center justify-center mt-8 flex-col">
        <img src={NoItem} alt="" />
        <p>No Data Available</p>
      </div>
    );
  }

  return (
    <>
      <table
        className="min-w-full border-separate"
        style={{ borderSpacing: "0 15px" }}
      >
        <thead>
          <tr className="mb-3 p-3 border-b text-black">
            {headers.map((header) => (
              <th key={header.key} className="py-2 px-4 border-b">
                {header.label}
              </th>
            ))}
            <th className="py-2 px-4 border-b text-black">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className="bg-white"
              style={{ borderRadius: "10px" }}
            >
              {headers.map((header) => (
                <td
                  key={header.key}
                  className="py-2 px-4 border-b-0 text-center text-black"
                >
                  {header.key === "members" &&
                    row[header.key] instanceof Object && (
                      <div className="flex -space-x-2 justify-center">
                        {(
                          row[header.key] as {
                            avatarUrls: string[];
                            count: number;
                          }
                        ).avatarUrls.map((avatarUrl, avatarIndex) => (
                          <img
                            key={avatarIndex}
                            className="w-6 h-6 rounded-full ring-2 ring-white mt-3 mb-0"
                            src={avatarUrl}
                            alt={`Avatar ${avatarIndex + 1}`}
                          />
                        ))}
                        <span className="flex items-center bg-blue-600 rounded-full text-[7px] text-white w-6 h-6 mt-3 mb-0 pl-1">
                          +
                          {
                            (
                              row[header.key] as {
                                avatarUrls: string[];
                                count: number;
                              }
                            ).count
                          }
                        </span>
                      </div>
                    )}

                  {header.key === "memberProfile" &&
                    row[header.key] instanceof Object && (
                      <div className="flex items-center justify-center gap-2">
                        <img
                          className="w-6 h-6 rounded-full ring-2 ring-white mt-3 mb-0"
                          src={
                            (
                              row[header.key] as {
                                memberName: string;
                                image: string;
                              }
                            ).image
                          }
                          alt=""
                        />
                        <p className="mt-3 text-black">
                          {
                            (
                              row[header.key] as {
                                memberName: string;
                                image: string;
                              }
                            ).memberName
                          }{" "}
                        </p>
                      </div>
                    )}

                  {header.key === "member" &&
                    row[header.key] instanceof Object && (
                      <div className="flex justify-center items-center">
                        <p className="font-semi-bold text-black">
                          {
                            (row[header.key] as { name: string; role: string })
                              .name
                          }
                        </p>
                        {(row[header.key] as { name: string; role: string })
                          .role === "Community Leader" && (
                          <p className="border rounded-2xl mt-0.5 ml-1 text-[10px] bg-[#FFF8ED] text-[#FFA412] px-2">
                            {
                              (
                                row[header.key] as {
                                  name: string;
                                  role: string;
                                }
                              ).role
                            }
                          </p>
                        )}
                      </div>
                    )}

                  {header.key === "status" && (
                    <>
                      {row[header.key] === "Approved" && (
                        <p className="bg-[#DAF2DC] text-[#03A413] p-1 rounded-full text-center text-[10px] w-[70px]">
                          {row[header.key] as string}
                        </p>
                      )}

                      {row[header.key] === "Rejected" && (
                        <p className="bg-[#FBD9D9] text-[#E10000] p-1 rounded-full text-center text-[10px] w-[70px]">
                          {row[header.key] as string}
                        </p>
                      )}

                      {row[header.key] === "Pending" && (
                        <p className="bg-[#FFF2DC] text-[#FFA412] p-1 rounded-full text-center text-[10px] w-[70px]">
                          {row[header.key] as string}
                        </p>
                      )}
                    </>
                  )}

                  {!(
                    header.key === "members" &&
                    row[header.key] instanceof Object
                  ) &&
                    !(
                      header.key === "memberProfile" &&
                      row[header.key] instanceof Object
                    ) &&
                    !(header.key === "status") &&
                    !(
                      header.key === "member" &&
                      row[header.key] instanceof Object
                    ) &&
                    // Explicitly cast the content to ReactNode
                    (row[header.key] as React.ReactNode)}
                </td>
              ))}
              <td className="py-2 px-4 border-b-0">
                <MainButton
                  className="w-[89px]"
                  onClick={() => handleViewClick(row)}
                >
                  View
                </MainButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRowData && (
        <ModalPanel
          open={openWithdrawalRequest}
          closeModal={() => setOpenWithdrawalRequest(false)}
        >
          <div className="mx-4">
            <div className="flex justify-between mb-4">
              <p className="text-black text-[18px] font-bold">Member Profile</p>
              <button
                type="button"
                className="text-black"
                onClick={() => setOpenWithdrawalRequest(false)}
              >
                <CgClose size={25} />
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={ProfileGirl}
                  alt="profile"
                  className="w-13 h-13 rounded-full"
                />
                <div className="mx-2">
                  <p className="text-primary text-[16px] font-bold mb-1">
                    {selectedRowData?.memberProfile?.memberName || "null"}
                  </p>
                  <p className="text-black text-[12px]">
                    {selectedRowData?.withdrawalRequestType|| "null"}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="mt-4">
                <p className="text-black"> Email</p>
                <div className="flex justify-between">
                  <p className="text-primary font-[600]">
                    {selectedRowData?.email || "null"}
                  </p>
                  <button
                    type="button"
                    className=""
                    onClick={() =>
                      copyToClipboard(selectedRowData?.email || "null")
                    }
                  >
                    <img src={CopyIcon} alt="wallet icon" />
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-black"> Phone Number</p>
                <div className="flex justify-between">
                  <p className="text-primary font-[600]">
                    {selectedRowData?.phoneNumber || "null"}
                  </p>
                  <button
                    type="button"
                    className=""
                    onClick={() =>
                      copyToClipboard(selectedRowData?.phoneNumber || "null")
                    }
                  >
                    <img src={CopyIcon} alt="wallet icon" />
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-black"> Withdrawal Amount</p>
                <p className="text-primary font-[600] mt-0">
                  {selectedRowData?.withdrawalAmount}
                </p>
              </div>
              <div className="mt-4">
                <p className="text-black">Reason for withdrawal</p>
                <p className="text-primary font-[600] mt-0 w-2/3">
                  {selectedRowData?.withdrawalRequestReason}
                </p>
              </div>
            </div>
            <div className="mt-4 flex justify-between">
              <div className="flex flex-col">
                <p className="text-black"> Account Number</p>
                <p className="text-primary font-[600] mt-0">
                  {selectedRowData?.accountNumber}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-black"> Account Name</p>
                <p className="text-primary font-[600] mt-0">
                  {selectedRowData?.accountName}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-black">Bank</p>
                <p className="text-primary font-[600] mt-0">
                  {selectedRowData?.bank}
                </p>
              </div>
            </div>

            {/* <div className="mt-10">
              <button
                type="button"
                className="flex items-center gap-2 justify-center w-full mr-2 border-2 h-10 bg-[#EEF6FF] text-primary rounded-full py-6"
                onClick={() => setOpenEscrowWalletModal(true)}
              >
                <img src={WalletIconBlue} alt="wallet icon" />
                Member wallet
              </button>
            </div> */}

            <div className="flex my-6 mt-2">
              <MainButton
                className="mr-2"
                type="button"
                onClick={() => handleSubmit(() => onSubmit({ verdict: true }))()}
              >
                Accept
              </MainButton>
              <RejectButton
                className="ml-2"
                type="button"
                onClick={() => handleSubmit(() => onSubmit({ verdict: false }))()}
              >
                Reject
              </RejectButton>
            </div>
          </div>
        </ModalPanel>
      )}
      <ModalPanel
        open={openEscrowWalletModal}
        closeModal={() => setOpenEscrowWalletModal(false)}
        modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
      >
        <div className="">
          <div className="relative">
            <WalletHeader
              totalBalance={memberWallet?.balance || 0}
              imageUrl={Walletbg}
            />
            <button
              type="button"
              className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-3 right-7  z-10"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
              onClick={() => setOpenEscrowWalletModal(false)}
            >
              <CgClose size={20} />
            </button>
          </div>
          <div className="mt-10 ">
            <p className="text-black text-[15px] font-bold">
              Transactions History
            </p>
          </div>

          <TransactionsTable
            transactions={transactionsData.map((transaction) => ({
              ...transaction,
              date: transaction?.createdAt
                ? formatDate(transaction?.createdAt)
                : "",
              status: transaction?.transactionType || "",
              amount: transaction?.transactionAmount || 0,
              description: transaction?.transactionStatus || "",
            }))} pageData={undefined} setPageNum={function (pageNum: number): void {
              throw new Error("Function not implemented.");
            } }            
          />
        </div>
      </ModalPanel>
    </>
  );
};

export default Table;
