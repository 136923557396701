import React, { useEffect, useState, useCallback, useMemo } from "react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { Walletbg } from "../../../Assets";
import GoBack from "../../../Components/elements/Display/GoBack";
import SearchInput from "../../../Components/elements/Forms/Search";
import { Transaction } from "../../../types";
import TransactionsTable from "./TransactionTable";
import ModalPanel from "../../../Components/elements/Modal/Index";
import {
  GetCommunityWalletTransactionHistoryApi,
  GetSingleCommunityApi,
  getWalletApi,
} from "../../../Services";
import { useParams } from "react-router";
import WalletHeader from "./superAdminWalletHeader";
import CommunityMembers from "../../../Components/elements/Display/CommunityList";
import Search from "../../../Components/elements/Forms/MembersSearch";
import Pagination from "../../../Components/elements/Pagination";
import { BounceLoader } from "react-spinners";

const SuperAdminWallet = () => {
  const { communityId } = useParams();
  const [communityData, setCommunityData] = useState<any>(null);
  const [filterTransactionModal, setFilterTransactionModal] = useState(false);
  const [walletData, setWalletData] = useState<any>({});
  const [pageData1, setPageData1] = useState<any>();
  const [pageNum1, setPageNum1] = useState(1);
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageData2, setPageData2] = useState<any>();
  const [pageNum2, setPageNum2] = useState(1);

  const getWalletData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getWalletApi(communityId || "");
      const res2 = await GetCommunityWalletTransactionHistoryApi(
        communityId ?? "",
        pageNum1
      );
      const res3 = await GetSingleCommunityApi(communityId as string, pageNum2);

      setWalletData(res);
      setTransactionsData(res2.transactions[0]);
      setCommunityData(res3);
      setPageData1(res2.metadata);
      setPageData2(res3.metadata);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [communityId, pageNum1, pageNum2]);

  useEffect(() => {
    getWalletData();
  }, [getWalletData, pageNum1, pageNum2]);

  const sortedMembers = useMemo(
    () =>
      communityData?.communityMembersData?.sort(
        (a: any, b: any) =>
          (b.userDetails.communityLeader ? 1 : 0) -
          (a.userDetails.communityLeader ? 1 : 0)
      ),
    [communityData]
  );

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-UK", options);
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mt-2 md:w-[62%]">
            <div>
              <GoBack link="/superadmin/community" />
            </div>
            <WalletHeader
              totalBalance={
                walletData.totalMemberBalance
                  ? walletData.totalMemberBalance
                  : "0"
              }
              availableBalance={
                walletData.availableBalance ? walletData.availableBalance : "0"
              }
              imageUrl={Walletbg}
              updatedAt={walletData.updatedAt}
            />
            <div className="flex mt-8 justify-between items-center">
              <h1 className="text-black text-lg"> Transactions History</h1>
              <button
                type="button"
                onClick={() => setFilterTransactionModal(true)}
                className="flex items-center bg-primary h-8 text-white rounded-md p-2"
              >
                <HiOutlineAdjustmentsHorizontal className="mr-2" />
                Filter transaction
              </button>
            </div>
            <TransactionsTable
              transactions={transactionsData.map((transaction) => ({
                ...transaction,
                date: transaction.createdAt
                  ? formatDate(transaction.createdAt)
                  : "",
                status: transaction.transactionType || "",
                amount: transaction.transactionAmount || 0,
                description: transaction.transactionStatus || "",
              }))}
              pageData={pageData1}
              setPageNum={setPageNum1}
            />
          </div>
          <div className="pl-2 md:w-[35%]">
            <div className="">
              <p className="text-[16px] text-black font-bold">
                List of community members
              </p>
              <div className="flex text-[10px] justify-between items-center mb-2">
                <p>
                  <span className="text-primary font-bold  mr-1">
                    {communityData?.communityMembersData?.length}
                  </span>
                  community members
                </p>
              </div>
            </div>
            <div className="rounded-xl p-3 bg-white">
              <div className="">
                <Search />
              </div>
              {sortedMembers?.map((member: any, index: number) => (
                <CommunityMembers
                  key={index}
                  image={member.pfpImageUrl}
                  initials={member.userInitials}
                  name={`${member.userDetails.user.firstName} ${member.userDetails.user.lastName}`}
                  role={
                    member.userDetails.communityLeader
                      ? "Community Leader"
                      : "Member"
                  }
                  communityMembersList={[]}
                  date={member?.userDetails?.createdAt || "null"}
                  emailAddress={member?.userDetails?.user?.emailAddress || ""}
                  phoneNumber={member?.userDetails?.user?.phoneNumber || ""}
                  communityId={member?.userDetails?.community?.id || "null"}
                  id={
                    member?.userDetails?.user?.id ||
                    member?.userDetails?.id ||
                    "null"
                  }
                />
              ))}
            </div>
            <div className="flex justify-center mt-5">
              <Pagination pageData={pageData2} setPageNum={setPageNum2} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAdminWallet;
