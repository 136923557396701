import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Profile, WalletIcon, FamilyGroup } from "../../../Assets"
import GoBack from "../../../Components/elements/Display/GoBack"
import { FaArrowRightLong } from "react-icons/fa6"
import Table from "../../app/CommunityAdmin/EscrowGroups/EscrowTable"
import Search from "../../../Components/elements/Forms/MembersSearch"
import {
  GetAllLeadersApi,
  GetCommunityWithdrawalRequestsApi,
  interCommunityEscrowGroupDetailsApi,
} from "../../../Services"
import { EscrowGroupDetails, WithdrawalRequestProps } from "../../../types"
import CommunityLeaders from "./CommunityLeaders"
import { BounceLoader } from "react-spinners"

const InterCommunityDetails = () => {
  const location = useLocation()
  const { rowData } = location.state || {}

  const [withdrawalRequestsData, setWithdrawalRequestsData] = useState<
    WithdrawalRequestProps[]
  >([])
  const [interEscrowGroupData, setInterEscrowGroupData] =
    useState<EscrowGroupDetails>()
  const [leaders, setLeaders] = useState<any[]>([])
  const [filteredLeaders, setFilteredLeaders] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [loading, setLoading] = useState(true)

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options,
    )
    return formattedDate
  }

  const Head = [
    { key: "no", label: "No." },
    { key: "memberProfile", label: "Member Name" },
    { key: "withdrawalAmount", label: "Withdrawal Amount" },
  ]
  
  const fetchInterEscrowMembers = async () => {
    try {
      setLoading(true)
      const escrowData = await interCommunityEscrowGroupDetailsApi(rowData.id)
      const withdrawalData = await GetCommunityWithdrawalRequestsApi(
        rowData.id,
        1,
      )
      const leadersData = await GetAllLeadersApi(1)

      setInterEscrowGroupData(escrowData)
      setWithdrawalRequestsData(withdrawalData)
      setLeaders(leadersData.data)
      setFilteredLeaders(leadersData.data) // Initialize filtered leaders
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchInterEscrowMembers()
  }, [rowData.id])

  useEffect(() => {
    // Filter leaders based on search query
    if (searchQuery === "") {
      setFilteredLeaders(leaders)
    } else {
      setFilteredLeaders(
        leaders.filter((leader) =>
          leader.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      )
    }
  }, [searchQuery, leaders])

  const data = withdrawalRequestsData.map((request, index) => ({
    no: index + 1,
    memberProfile: {
      memberName: request.accountDetails.accountName,
      image: Profile,
    },
    withdrawalAmount: request.amount,
  }))

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <div className="flex flex-col mt-4 md:flex-row justify-between">
          <div className="mt-2 md:w-[62%]">
            <GoBack link="/superadmin/interCommunityEscrows" />
            <div className="flex">
              <img src={FamilyGroup} alt="profile" className=" mt-3 mb-0 " />
              <div className="flex flex-col self-center m-3">
                <p className="text-black text-2xl mb-3 font-bold">
                  {rowData.communityName}
                </p>
                <div className="flex items-center gap-3">
                  <div className="border-2 border-primary flex pr-6 rounded-full pl-0 py-0 justify-between items-center">
                    <img
                      src={Profile}
                      alt="profile"
                      className="w-12 h-12 rounded-full "
                    />
                    <div className="pl-2">
                      <p className="text-primary font-bold">
                        {rowData.communityAdmin}
                      </p>
                      <p className="mt-0">Admin </p>
                    </div>
                  </div>
                  <Link to={`/superadmin/InterCommunityWallet/${rowData.id}`}>
                    <img
                      src={WalletIcon}
                      alt="wallet icon"
                      className="bg-primary rounded-full w-[50px] h-[50px] p-3"
                      style={{
                        borderRadius: "100%",
                        background: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="border-primary mt-6 rounded-md p-2 flex justify-around border-2">
              <div className="flex flex-col items-center">
                <p className="text-xs text-black">Target amount</p>
                <p className="text-xl text-primary font-bold">
                  {interEscrowGroupData?.escrowTargetAmount || 0}
                </p>
              </div>
              <div className="border-r-2 my-1 border-primary">&nbsp;</div>
              <div className="flex flex-col items-center ">
                <p className="text-xs text-black">Escrow Expiration</p>
                <p className="text-xl text-primary font-bold">
                  {formatDate(
                    interEscrowGroupData?.escrowExpirationDate || "None",
                  )}
                </p>
              </div>
              <div className="border-r-2 my-1 border-primary">&nbsp;</div>
              <div className="flex flex-col items-center">
                <p className="text-xs text-black">Progress</p>
                <p className="text-xl text-primary font-bold">
                  {interEscrowGroupData?.escrowStatus || "None"}
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-7">
              <p className=" text-[18px] text-black font-bold">Description</p>
              <p className="text-xs text-black">
                {interEscrowGroupData?.escrowGroupDescription || "None"}
              </p>
            </div>

            <div className="flex flex-col mt-5">
              <p className=" text-[18px] text-black font-bold">
                Withdrawal requests
              </p>
              <div className="justify-between items-center flex">
                <p className="text-[11px]">
                  You have{" "}
                  <span className="text-primary font-bold">
                    {withdrawalRequestsData.length}
                  </span>{" "}
                  withdrawal requests
                </p>
                {/* <Link
                  to={`/superadmin/WithdrawalRequests/${rowData.id}`}
                  className="text-primary flex items-center"
                >
                  See all
                  <span className="ml-2">
                    <FaArrowRightLong />
                  </span>
                </Link> */}
              </div>

              <Table
                headers={Head}
                data={data.map((item) => ({
                  ...item,
                  no: `#${item.no}`,
                }))}
              />
            </div>
          </div>
          <div className="pl-2 md:w-[35%]">
            <div className="">
              <p className="text-[16px] text-black font-bold">
                List of Community Leaders
              </p>
              <div className="flex text-[10px] justify-between items-center mb-2">
                <p>
                  <span className="text-primary font-bold ">
                    {filteredLeaders.length}{" "}
                  </span>
                  Community Leader(s)
                </p>
                {/* <button type="button" className="text-primary">Select members</button> */}
              </div>
            </div>
            <div className="rounded-xl p-3 bg-white">
              <div className="mb-4">
                <Search
                  placeholder="Search community leaders..."
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              {filteredLeaders?.map((leader) => (
                <CommunityLeaders
                  key={leader.communityLeaderUserId}
                  image={leader.pfpImageUrl}
                  name={leader.name}
                  role={leader.communityName}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InterCommunityDetails
