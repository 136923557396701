import React from "react"
import { MainButton } from "../Button"
import { replaceCommunityAdminApi } from "../../../Services"
import { useParams } from "react-router"
import { toast } from "react-toastify"

interface Admin {
  img: string
  name: string
  communityAdmin: string
  email: string
  communityId?: string | null
}

interface AdminListProps {
  users: Admin[]
}

const AdminList: React.FC<AdminListProps> = ({ users }) => {
  const { communityId } = useParams()
  console.log(communityId)

  const handleSelect = async (communityId: string) => {
    try {
      const res = await replaceCommunityAdminApi(communityId)
      console.log(res)
      toast.success(res.message)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {users.map((user, index) => (
        <div
          key={index}
          className="bg-white p-4 flex rounded shadow justify-between items-center"
        >
          <div className="flex  justify-between w-3/4">
            <div className="flex self-center">
              <img
                className="w-14 h-14 mr-2 rounded-full ring-2 ring-white self-center"
                src={user.img}
                alt="Avatar"
              />
              <div className="flex flex-col self-center">
                <div className="mb-2 font-bold">{user.name}</div>
                <div className="mb-2 text-sm text-gray-600">
                  Community Admin
                </div>
              </div>
            </div>

            <div className="mr-4 self-center">{user.email}</div>
          </div>
          <div className="w-1/5">
            {user.communityId && (
              <MainButton
                onClick={() => handleSelect(user.communityId as string)}
              >
                Select
              </MainButton>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default AdminList
