import React, { Fragment, useEffect, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import useCurrentPath from "../../../Hooks/useCurrentPath"
import {
  DownArrowIcon,
  LogoutIcon,
  NoItem,
  NotificationBellIcon,
  OnePersonIcon,
  Profile,
  ProfileGuy,
  ProfileLady,
  SearchIcon,
  SettingsIconBlue,
  UpArrowIcon,
  WithdrawalIcon,
} from "../../../Assets"
import { UserProfileApi, notificationsApi } from "../../../Services"
import { User, notificationType } from "../../../types"
import { formatDate } from "../../../Utils/Helper"
import { Link } from "react-router-dom"
import MobileSideNav from "../Sidebar/MobileSideNav"

const menuOptions = [
  {
    name: "Logout",
    href: "/",
    icon: LogoutIcon,
  },
]

const handleLogout = () => {
  localStorage.clear()
  window.location.href = "/"
}

const getInitials = (fullName: string | null) => {
  const names = fullName?.split(" ")
  const initials = names?.map((name) => name[0]).join("")
  return initials?.toUpperCase()
}

const DashboardHeader = () => {
  const parts = useCurrentPath()
  const activeLink = parts.length > 2 ? parts[2] : ""
  const formattedActiveLink = activeLink.replace(/([a-z])([A-Z])/g, "$1 $2")

  const [userProfileDetails, setUserProfileDetails] = useState<User | null>(
    null,
  )

  const [notificationsData, setNotificationsData] = useState<
    notificationType[]
  >([])
  const displayedNotifications = notificationsData.slice(0, 5)

  const getUserProfileApi = async () => {
    try {
      const res = await UserProfileApi()
      const res2 = await notificationsApi()
      console.log(res)
      setNotificationsData(res2.notifications)
      setUserProfileDetails(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserProfileApi()
  }, [])

  return (
    <div className="flex w-full md:flex justify-between md:items-center">
      <div className=" flex w-full items-center justify-between bg-white p-2">
        <div className="md:flex ">
          <h2 className="text-sm md:flex md:pl-3 md:text-[15px] text-[#111] font-bold capitalize ">
            {formattedActiveLink}
          </h2>
        </div>

        <div className="flex gap-4 items-center justify-between">
          <Popover className="relative">
            <Popover.Button>
              <div className="flex justify-center items-center  bg-[#EEF6FF] h-12 w-12 rounded-full ">
                <img src={NotificationBellIcon} alt="Notification" />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-12 z-10 mt-3 w-[300px] -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative bg-white p-3">
                    <p className="text-black text-lg flex justify-center font-bold items-center border-b-2">
                      Notifications
                    </p>
                    <div className="flex flex-col p-0">
                      {Array.isArray(notificationsData) &&
                      notificationsData.length > 0 ? (
                        <>
                          {displayedNotifications.map((notification, index) => (
                            <div
                              key={index}
                              className={`p-3 mb-1 ${
                                index % 2 === 0 ? "bg-white" : "bg-gray"
                              } flex items-center rounded-lg shadow-sm`}
                            >
                              <div>
                                <p className="text-[14px] font-bold">
                                  {notification?.notificationTitle}
                                </p>
                                <p className="text-[14px]">
                                  {notification?.notificationMessage}
                                </p>
                                <p className="text-xs text-dark-gray mt-0">
                                  {formatDate(notification?.createdAt)}
                                </p>
                              </div>
                            </div>
                          ))}
                          {/* <Link
                            to="/notifications"
                            className="mt-4 text-center text-blue-500 hover:underline"
                          >
                            View All
                          </Link> */}
                        </>
                      ) : (
                        <p className="flex flex-col text-center text-[14px] items-center justify-center text-dark-gray">
                          <img src={NoItem} alt="" />
                          No notifications available
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Popover className="relative">
            <Popover.Button>
              <div className="flex items-center gap-2">
                <div className={`w-12 h-12 border-2  ${userProfileDetails?.profilePictureFileKey===null ? "border-primary bg-[#EEF6FF]" : "border-primary"  } rounded-full overflow-hidden flex justify-center items-center`}
                // style={{
                //   background:
                //     userProfileDetails?.profilePictureFileKey===null ? "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%)" : "white",
                // }}
                >
                  {userProfileDetails?.profilePictureFileKey!==null ?
                  <img
                    src={userProfileDetails?.profilePictureFileKey}
                    className="object-cover w-full  h-full p-0.5"
                    alt="Profile"
                  /> :
                   <span className="text-primary text-xl font-medium">{getInitials(userProfileDetails?.fullName)}</span>
                }
                </div>
                <div>
                  <h3 className="text-primary text-sm ">
                    {userProfileDetails?.fullName}
                  </h3>
                  <p className="text-dark-gray text-[12px]">
                    {userProfileDetails?.accountType === "COMMUNITY_ADMIN" ? (
                      <div>
                        <p className="text-dark-gray ">Community Admin</p>
                      </div>
                    ) : (
                      <div>
                        <p className="text-dark-gray ">Super Admin</p>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-12 z-10 mt-3 w-[250px] -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative grid gap-6 bg-white p-5">
                    {menuOptions.map((item: any) => (
                      <button
                        key={item.name}
                        type="button"
                        onClick={
                          item.name === "Logout" ? handleLogout : undefined
                        }
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-[#F6F6F6] focus:outline-none focus-visible:ring focus-visible:ring-blue-500/50"
                      >
                        <div className="bg-[#EEF6FF] rounded-lg w-[45px] h-[45px] p-3 cursor-pointer border-none focus:outline-none">
                          <img
                            src={item.icon}
                            alt="delete icon"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <div className="flex lg:hidden">
          <MobileSideNav />
        </div>
        </div>
      
      </div>
    </div>
  )
}
export default DashboardHeader
