import {
  EditProfileIcon,
  EditProfileIconBlue,
  NotificationBellIcon,
  NotificationBellIconWhite,
  PasswordIcon,
  PasswordIconWhite,
  SupportIcon,
  SupportIconWhite,
} from "../../../../Assets"
import React, { useState } from "react"
import { IoIosArrowForward } from "react-icons/io"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { ItemProps } from "../../../../types"

function Settings() {
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  const settingsData: ItemProps[] = [
    {
      id: 1,
      title: "Edit Profile",
      link: "edit-profile",
      img: EditProfileIconBlue,
      activeImg: EditProfileIcon,
    },
    // {
    //   id: 2,
    //   title: "Notification Settings",
    //   link: "notification-settings",
    //   img: NotificationBellIcon,
    //   activeImg: NotificationBellIconWhite,
    // },
    {
      id: 3,
      title: "Password Settings",
      link: "password-settings",
      img: PasswordIcon,
      activeImg: PasswordIconWhite,
    },
    {
      id: 4,
      title: "Support",
      link: "support",
      img: SupportIcon,
      activeImg: SupportIconWhite,
    },
  ]
  return (
    <div className="px-1 py-3">
      <div className="h-[86%] fixed bg-white p-3 rounded-lg w-[20%]">
        {settingsData.map(
          ({ icon: IconComponent, img, activeImg, id, title, link }) => (
            <div
              className={`flex py-2 px-2 my-3 rounded-full items-center justify-between ${
                selected === id ? "bg-primary" : "bg-white"
              } `}
              onClick={() => {
                navigate(link)
                setSelected(id)
              }}
              onKeyDown={() => {
                navigate(link)
                setSelected(id)
              }}
              tabIndex={0}
              role="button"
              key={id}
            >
              <div className="flex ">
                {IconComponent && (
                  <IconComponent
                    className={`w-5 h-5 ${
                      selected === id ? "text-white" : "text-primary"
                    }`}
                  />
                )}
                {img &&
                  activeImg &&
                  (selected === id ? (
                    <img
                      src={
                        typeof activeImg === "string" ? activeImg : undefined
                      }
                      alt={title}
                      className="pl-3"
                    />
                  ) : (
                    <img
                      src={typeof img === "string" ? img : undefined}
                      alt={title}
                      className="pl-3"
                    />
                  ))}
                <p
                  className={`text-md pl-3  ${
                    selected === id ? "text-white" : "text-primary"
                  }`}
                >
                  {title}
                </p>
              </div>
              <IoIosArrowForward
                className={`text-lg ${
                  selected === id ? "text-white" : "text-primary"
                }`}
              />
            </div>
          ),
        )}
      </div>
      <div className="w-[75%] ml-[30%]  h-full">
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
