import { toast } from "react-toastify"

export const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options,
  )
  return formattedDate
}

export const search = (array: any[], query: string) => array.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
  toast.success("Copied to clipboard", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined, 
  })
}


export const calculateDurationInDays = (
  startDate: string | number | Date,
  endDate: string | number | Date,
  currentDate: string | number | Date = new Date()
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const current = new Date(currentDate);
  
  const duration = Math.floor(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  );
  
  const daysRemaining = Math.floor(
    (end.getTime() - current.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (daysRemaining < 0) {
    return { duration: "0", daysRemaining: "0" };
  }
  
  return { duration, daysRemaining };
}
