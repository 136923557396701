import React, { useState } from "react";
import { LuArrowDownToLine, LuArrowUpToLine } from "react-icons/lu";
import { BsThreeDots } from "react-icons/bs";
import { TransactionsTableProps, Transaction } from "../../../../types";
import TransactionModal from "../../../../Components/elements/Display/TransactionModal";
import { NoItem } from "../../../../Assets";
import Pagination from "../../../../Components/elements/Pagination";

interface StatusStyle {
  bgColor: string;
  textColor: string;
  icon: JSX.Element;
  label: string;
}

interface DescriptionStyle {
  bgColor: string;
  text: string;
  label: string;
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactions, transPageData, setTransPageNum, emptyTrans
}) => {
  const [openTransactionReceipt, setOpenTransactionReceipt] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);

  const handleOpenTransactionReceipt = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setOpenTransactionReceipt(true);
  };

  const handleCloseTransactionReceipt = () => {
    setOpenTransactionReceipt(false);
  };

  const getStatusStyle = (status: string): StatusStyle => {
    switch (status) {
      case "Withdrawn":
      case "debit":
        return {
          bgColor: "bg-[#FFE5E5]",
          textColor: "text-[#E10000]",
          icon: <LuArrowUpToLine />,
          label: status === "Withdrawn" ? "Withdrawn Funds" : "Withdrawn Funds",
        }
      case "credit":
        return {
          bgColor: "bg-[#D2FFD7]",
          textColor: "text-[#03A413]",
          icon: <LuArrowDownToLine />,
          label: "Deposited Funds",
        }
      default:
        return {
          bgColor: "bg-[#D2FFD7]",
          textColor: "text-[#03A413]",
          icon: <LuArrowDownToLine />,
          label: "Deposited Funds",
        }
    }
  }
  
  const getDescriptionStyle = (description: string): DescriptionStyle => {
    switch (description) {
      case "Approved":
      case "SUCCESSFUL":
        return {
          bgColor: "bg-[#D2FFD7]",
          text: "text-[#03A413]",
          label: "Completed"
        }
      case "FAILED":
      case "Rejected":
        return {
          bgColor: "bg-[#FFE5E5]",
          text: "text-[#E10000]",
          label: "Failed"
        }
      default:
        return {
          bgColor: "bg-[#FFF3DF]",
          text: "text-alert",
          label: "Pending"
        }
    }
  }
  
  const getStatusFromDescription = (description: string | undefined): string => {
    if (!description) return "Pending";
    const lowercaseDesc = description.toLowerCase();
    if (lowercaseDesc === "successful") return "Completed";
    if (lowercaseDesc === "pending") return "Pending";
    return "Failed";
  };

  return (
    <div>
{transactions.length === 0 && (
        <div className="flex flex-col items-center justify-center mt-8 h-[200px]">
          <img src={NoItem} alt="No item" />
          <p className="text-black text-lg font-bold ml-2">No transaction available</p>
        </div>
      )}


      <table className="table-auto w-full mt-5">
        <tbody>
          {transactions.map((transaction: Transaction, index: number) => {
            const statusStyle: StatusStyle = getStatusStyle(transaction.status);
            const descriptionStyle: DescriptionStyle = getDescriptionStyle(transaction.description);

            return (
              <React.Fragment key={index}>
                <tr className="flex justify-between items-center mb-3">
                  <td>
                    <span className={`flex items-center ${statusStyle.textColor}`}>
                      <span className={` ${statusStyle.bgColor} p-3 rounded-full mr-3`}>
                        {statusStyle.icon}
                      </span>
                      {statusStyle.label}
                    </span>
                  </td>
                  <td>{transaction.details}</td>
                  <td>{transaction.date}</td>
                  <td>₦{transaction.amount}</td>
                  <td className={`${descriptionStyle.bgColor} p-1 rounded-full ${descriptionStyle.text} text-center text-[10px] w-[70px]`}>
                  {descriptionStyle.label}
                  </td>
                  <td className="text-2xl cursor-pointer" onClick={() => handleOpenTransactionReceipt(transaction)}>
                    <BsThreeDots />
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          {!emptyTrans && 
           <div className="flex justify-center mt-5">
            <Pagination pageData={transPageData} setPageNum={setTransPageNum} />
          </div>}
        </tbody>
      </table>
      {openTransactionReceipt && selectedTransaction && (
        <TransactionModal
          open={openTransactionReceipt}
          closeModal={handleCloseTransactionReceipt}
          type={selectedTransaction.status === 'debit' ? 'Withdrawn Funds' : 'Deposited Funds'}
          status={getStatusFromDescription(selectedTransaction?.description)}
          amount={selectedTransaction.amount}
          reference={selectedTransaction.transactionReference}
        />
      )}
    </div>
  );
};

export default TransactionsTable;
