import React, { useEffect, useState } from "react"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { Walletbg } from "../../../Assets"
import GoBack from "../../../Components/elements/Display/GoBack"
import SearchInput from "../../../Components/elements/Forms/Search"
import WalletHeader from "../../../Components/elements/Hero/WalletHeader"
import { Transaction } from "../../../types"
import TransactionsTable from "./TransactionTable"
import CommunityWallet from "./CommunityWallet"
import useCurrentPath from "../../../Hooks/useCurrentPath"
import ModalPanel from "../../../Components/elements/Modal/Index"
import {
  GetEscrowWalletApi,
  GetEscrowWalletTransactionHistoryApi,
  GetSingleCommunityApi,
  viewEscrowMembersApi,
} from "../../../Services"
import { useLocation, useParams } from "react-router-dom"
import Pagination from "../../../Components/elements/Pagination"
import Search from "../../../Components/elements/Forms/Search"
import CommunityMembers from "../../../Components/elements/Display/CommunityList"
import { calculateDurationInDays, formatDate } from "../../../Utils/Helper"
import { BounceLoader } from "react-spinners"
import FilterComponent from "../../../Components/elements/Display/FilterTransaction"

const EscrowSuperAdminWallet = () => {
  const { escrowId } = useParams()
  const location = useLocation()

  const { escrowGroupData } = location.state || {}

  const [filterTransactionModal, setFilterTransactionModal] = useState(false)
  const [walletData, setWalletData] = useState<any>({})
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [members, setMembers] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const getWalletData = async () => {
    try {
      setLoading(true)
      const res = await GetEscrowWalletApi(escrowId || "")
      const res2 = await GetEscrowWalletTransactionHistoryApi(
        escrowId ?? "",
        pageNum,
      )
      const res3 = await viewEscrowMembersApi(escrowId || "", 1, 20)

      console.log(res)
      console.log(res2.transactions[0])
      console.log(res3)

      setWalletData(res)
      setTransactionsData(res2.transactions)
      setMembers(res3)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getWalletData()
  }, [pageNum, escrowId])

  const { duration, daysRemaining } = escrowGroupData
    ? calculateDurationInDays(
        escrowGroupData.createdAt,
        escrowGroupData.escrowExpirationDate,
      )
    : { duration: "nothing", daysRemaining: "nothing" }

  console.log(duration)
  console.log(daysRemaining)

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mt-2 md:w-[62%]">
            <div>
              <GoBack link="/superadmin/escrowGroups" />
            </div>
            <WalletHeader
              totalEscrowBalance={walletData.balance ? walletData.balance : "0"}
              targetAmount={
                walletData.targetAmount ? walletData.targetAmount : "0"
              }
              escrowDuration={duration !== "nothing" ? duration : "0"}
              imageUrl={Walletbg}
            />
            <div className="flex mt-8 justify-between items-center">
              <h1 className="text-black text-lg"> Transactions History</h1>
              <button
                type="button"
                onClick={() => setFilterTransactionModal(true)}
                className="flex items-center bg-primary h-8 text-white rounded-md p-2"
              >
                <HiOutlineAdjustmentsHorizontal className="mr-2" />
                Filter transaction
              </button>
            </div>
            <TransactionsTable
                transactions={transactionsData.map((transaction) => ({
                  ...transaction,
                  date: transaction.createdAt
                    ? formatDate(transaction.createdAt)
                    : "",
                  status: transaction.transactionType || "",
                  amount: transaction.transactionAmount || 0,
                  description: transaction.transactionStatus || "",
                  // details: transaction.transactionDetails || "",
                }))}   pageData={undefined}
                setPageNum={() => {
                  if (pageData) {
                    setPageNum(pageData.page)
                  }
                }}          />
          </div>
          <div className="pl-2 md:w-[35%]">
            <div className="">
              <p className="text-[16px] text-black font-bold">
                List of community members
              </p>
              <div className="flex text-[10px] justify-between items-center mb-2">
                <p>
                  <span className="text-primary font-bold mr-1">
                    {members.length || 0}
                  </span>
                  community members
                </p>
                {/* <button type="button" className="text-primary">
              Select members
            </button> */}
              </div>
            </div>
            <div className="rounded-xl p-3 bg-white">
              <div className="">
                <Search />
              </div>
              {members.map((member, index) => (
                <CommunityMembers
                  key={member.id}
                  image={member.profilePictureFileKey}
                  initials={member.userInitials}
                  name={`${member.firstName} ${member.lastName}`}
                  role={
                    member?.userDetails?.accountType === "COMMUNITY_MEMBER"
                      ? "Community Leader"
                      : "Member"
                  }
                  communityMembersList={[]}
                  date={member?.createdAt || "null"}
                  emailAddress={member.emailAddress || ""}
                  phoneNumber={member.phoneNumber || ""}
                  communityId={member?.community.id}
                  id={member.id}
                />
              ))}
            </div>
            <div className="flex justify-center mt-5">
              <Pagination pageData={pageData} setPageNum={setPageNum} />
            </div>
          </div>

          <ModalPanel
            open={filterTransactionModal}
            closeModal={() => setFilterTransactionModal(false)}
            closeButton
          >
            {/* <FilterComponent
              onFilterChange={function (
                transactionType: string,
                transactionStatus: string,
              ): void {
                throw new Error("Function not implemented.")
              }}
              loadingFilter={false}
            /> */}
          </ModalPanel>
        </div>
      )}
    </div>
  )
}

export default EscrowSuperAdminWallet
