import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Member, MemberWallet, Transaction } from "../../../types"
import { useNavigate, useParams } from "react-router-dom"
import ModalPanel from "../Modal/Index"
import { CopyIcon, WalletIconBlue, Walletbg } from "../../../Assets"
import { CgClose } from "react-icons/cg"
import { copyToClipboard, formatDate } from "../../../Utils/Helper"
import {
  GetUserSubWalletApi,
  GetUserSubWalletTransactionHistoryApi,
} from "../../../Services"
import TransactionsTable from "../../../Pages/SuperAdmin/Wallet/TransactionTable"
import GoBack from "./GoBack"
import WalletHeader from "../../../Pages/SuperAdmin/Wallet/superAdminWalletHeader"

interface CommunityMembersProps {
  communityId: string
  id: string
  communityMembersList: Member[]
  initials: string
  image: string
  name: string
  role: string
  date: string
  emailAddress: string
  phoneNumber: string
}

const CommunityMembers: React.FC<CommunityMembersProps> = ({
  communityId,
  id,
  communityMembersList,
  initials,
  image,
  name,
  role,
  date,
  emailAddress,
  phoneNumber,
}) => {
  // const {id } = useParams()

  console.log(id, communityId)

  const navigate = useNavigate()
  const [openWithdrawalRequest, setOpenWithdrawalRequest] = useState(false)
  const [openEscrowWalletModal, setOpenEscrowWalletModal] = useState(false)
  const [memberWallet, setMemberWallet] = useState<MemberWallet>()
  const createdAtDate = new Date(date)
  const currentDate = new Date()
  const daysDifference = Math.floor(
    (currentDate.getTime() - createdAtDate.getTime()) / (1000 * 60 * 60 * 24),
  )
  const weeksDifference = Math.floor(daysDifference / 7)

  let dateJoined = ""
  if (weeksDifference === 1) {
    dateJoined = `${weeksDifference} week ago`
  } else if (weeksDifference >= 1) {
    dateJoined = `${weeksDifference} weeks ago`
  } else if (daysDifference === 1) {
    dateJoined = `${daysDifference} day ago`
  } else {
    dateJoined = `${daysDifference} days ago`
  }
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [memberIdInLoading, setMemberIdInLoading] = useState<string | null>(
    null,
  )
  const [replaceLeaderModal, setReplaceLeaderModal] = useState(false)
  const [openMemberWallet, setOpenMemberWallet] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const userRole = communityMembersList?.map((member) =>
    member?.communityLeader === true ? "Community Leader" : "Member",
  )

  useEffect(() => {
    const fetchSubWallet = async () => {
      console.log(id)
      try {
        if (id && communityId) {
          const response = await GetUserSubWalletApi(communityId, id)
          const response2 = await GetUserSubWalletTransactionHistoryApi(
            communityId,
            id,
            pageNum,
          )
          console.log(response)
          console.log(response2)
          setMemberWallet(response)
          setTransactionsData(response2)
          setPageData(response2.metadata)
        } else {
          console.log("memberId is undefined")
        }
      } catch (error) {
        // setLoading(false);
        console.error(error)
      }
    }

    fetchSubWallet()
  }, [communityId, id, pageNum])

  return (
    <>
      <div className="flex items-center flex-grow" key={id}>
        {image ? (
          <img
            src={image}
            alt="profile"
            className="w-10 h-10 rounded-full ring-2 ring-white mt-3 mb-2"
          />
        ) : (
          <div className="w-10 h-10 items-center justify-center text-white flex rounded-full ring-2 ring-white mt-3 mb-2 bg-primary">
            {initials}
          </div>
        )}

        <div className="mx-2 flex-grow-1">
          <p className="text-primary text-[12px] font-bold mb-1">{name}</p>
          <p
            className={`rounded-2xl text-[10px] mt-0 text-[#8E8E8E] ${
              role.toLowerCase() === "community leader"
                ? "text-[#FFA412] border bg-[#FFF8ED] px-2"
                : ""
            } `}
          >
            {role}
          </p>
        </div>
        <button
          type="button"
          className="text-primary underline text-[13px] ml-auto"
          onClick={() => setOpenWithdrawalRequest(true)}
        >
          View
        </button>
      </div>

      <ModalPanel
        open={openWithdrawalRequest}
        closeModal={() => setOpenWithdrawalRequest(false)}
      >
        <div className="mx-4">
          <div className="flex justify-between mb-4">
            <p className="text-black text-[18px] font-bold">Member Profile</p>
            <button
              type="button"
              className="text-black"
              onClick={() => setOpenWithdrawalRequest(false)}
            >
              <CgClose size={25} />
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {image ? (
                <img
                  src={image}
                  alt="profile"
                  className="w-10 h-10 rounded-full ring-2 ring-white mt-3 mb-2"
                />
              ) : (
                <div className="w-10 h-10 items-center justify-center text-white flex rounded-full ring-2 ring-white mt-3 mb-2 bg-primary">
                  {initials}
                </div>
              )}

              <div className="mx-2">
                <p className="text-primary text-[16px] font-bold mb-1">
                  {name || "No name"}
                </p>
                <p className={`rounded-2xl text-[12px] mt-0 text-[#8E8E8E] `}>
                  {role.toLowerCase() === "member" ? `Community ${role}` : role}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="mt-4">
              <p className="text-black"> Email</p>
              <div className="flex justify-between">
                <p className="text-primary font-[600]">{emailAddress}</p>
                <button
                  type="button"
                  className=""
                  onClick={() => copyToClipboard(emailAddress)}
                >
                  <img src={CopyIcon} alt="copy icon" />
                </button>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-black"> Phone Number</p>
              <div className="flex justify-between">
                <p className="text-primary font-[600]">
                  {phoneNumber || "No phone number"}
                </p>
                <button
                  type="button"
                  className=""
                  onClick={() => copyToClipboard(phoneNumber)}
                >
                  <img src={CopyIcon} alt="copy icon" />
                </button>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <button
              type="button"
              className="flex items-center gap-2 justify-center w-full mr-2 border-2 h-10 bg-[#EEF6FF] text-primary rounded-full py-6"
              onClick={() => setOpenEscrowWalletModal(true)}
            >
              <img src={WalletIconBlue} alt="wallet icon" />
              Member wallet
            </button>
          </div>
        </div>
      </ModalPanel>
      <ModalPanel
        open={openEscrowWalletModal}
        closeModal={() => setOpenEscrowWalletModal(false)}
        modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
      >
        <div className="">
          <div className="relative">
            <WalletHeader
              totalBalance={memberWallet?.balance || 0}
              imageUrl={Walletbg}
            />

            <button
              type="button"
              className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-3 right-7  z-10"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
              onClick={() => setOpenEscrowWalletModal(false)}
            >
              <CgClose size={20} />
            </button>
          </div>
          <div className="mt-10 ">
            <p className="text-black text-[15px] font-bold">
              {" "}
              Transactions History
            </p>
          </div>

          <TransactionsTable
            transactions={transactionsData.map((transaction) => ({
              ...transaction,
              date: transaction?.createdAt
                ? formatDate(transaction?.createdAt)
                : "",
              status: transaction?.transactionType || "",
              amount: transaction?.transactionAmount || 0,
              description: transaction?.transactionStatus || "",
            }))}
            pageData={undefined}
            setPageNum={() => {
              if (pageData) {
                setPageNum(pageData.page)
              }
            }}
          />
        </div>
      </ModalPanel>
    </>
  )
}

export default CommunityMembers
