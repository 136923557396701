import axiosInstance from "../../Utils/Https"

export const UserProfileApi = async () => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = "profile"
  const res = await axiosInstance.get(url)
  return res.data
}

export const CreateNewCommunityApi = async (payload: any) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.interceptors.request.use((config) => {
    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`
    }
    return config
  })

  const url = "communities/create-new-community"
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const res = await axiosInstance.post(url, payload, config)
  return res.data
}

export const EditCommunityApi = async (communityId: string | undefined, payload: any) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.interceptors.request.use((config) => {
    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`
    }
    return config
  })

  const url = `communities/${communityId}/edit-community`
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const res = await axiosInstance.patch(url, payload, config)
  return res.data
}

export const getCommunityListApi = async () => {
  const url = "communities"
  const res = await axiosInstance.get(url)
  return res.data
}

export const getCommunityDetailsApi = async (id: string) => {
  const url = `communities/${id}`
  const res = await axiosInstance.get(url)
  return res.data
}

export const getCommunityMembersApi = async (communityId: string, page: number) => {
  const url = `communities/${communityId}/members?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const makeMemberCommunityAdminApi = async (
  communityId: string | undefined,
  memberId: string,
) => {
  const bearerToken = localStorage.getItem("atk")

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = `/communities/${communityId}/members/${memberId}/make-community-leader`
  const res = await axiosInstance.post(url)
  return res.data
}

export const searchCommunityApi = async (communityId: string, searchData : string) => {
  const url = `/communities/${communityId}/search?name=${searchData}`
  const res = await axiosInstance.get(url)
  return res.data
}

export const notificationsApi = async () => {
  const url = "notifications"
  const res = await axiosInstance.get(url)
  return res.data
}

export const InviteToCommunityApi = async (communityId: string | undefined, payload: { emailAddress: string[] }) => {
  const bearerToken = localStorage.getItem("atk")

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`
  const url = `communities/${communityId}/invite-to-community`
  const res = await axiosInstance.post(url, payload)
  return res.data
}

export const inAppNotificationsApi = async (enabled: boolean) => {
  const url = `settings?in-app-notifications=${enabled ? 'ENABLE' : 'DISABLE'}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const EditProfileApi = async (payload: any) => {
  const bearerToken = localStorage.getItem("atk")
  axiosInstance.interceptors.request.use((config) => {
    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`
    }
    return config
  })

  const url = "edit-profile"
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const res = await axiosInstance.patch(url, payload, config)
  return res.data
}

export const addPermissionApi = async (
  communityId: string | undefined,
  memberId: string,
  payload: any
) => {
  const url = `/communities/${communityId}/members/${memberId}/add-permissions`
  const res = await axiosInstance.patch(url, payload)
  return res.data
}

export const removePermissionApi = async (
  communityId: string | undefined,
  memberId: string,
  payload: any
) => {
  const url = `/communities/${communityId}/members/${memberId}/remove-permissions`
  const res = await axiosInstance.patch(url, payload)
  return res.data
}

export const getPermissionsApi = async (userId: string) => {
  const url = `/${userId}/get-user-permissions`
  const res = await axiosInstance.get(url)
  return res.data
}

export const getWalletApi = async (communityId: string) => {
  const url = `communities/${communityId}/wallet`
  const res = await axiosInstance.get(url)
  return res.data
}

export const getSubWalletApi = async (
  communityId: string,
  memberId: string,
  ) => {
  const url = `communities/${communityId}/members/${memberId}/wallet`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetSubWalletTransactionHistoryApi = async (
  communityId: string,
  memberId: string,
  page: number,
) => {
  const url = `/communities/${communityId}/members/${memberId}/wallet/transaction-history?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const sendNotificationApi = async (
  communityId: string | undefined,
  payload: any
) => {
  const url = `/super-admin/${communityId}/send-notification-to-single-community`
  const res = await axiosInstance.post(url, payload)
  console.log(res.data)
  return res.data
}

export const supportApi = async (
  payload: any
) => {
  const url = `/Customer-support/create-ticket`
  const res = await axiosInstance.post(url, payload)
  console.log(res.data)
  return res.data
}