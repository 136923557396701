import React, { ChangeEvent, FocusEvent, KeyboardEvent } from "react"
import { AiOutlineCalendar } from "react-icons/ai"

interface FormInputProps {
  id?: string
  label?: string
  type?: string
  name: string
  value?: string
  placeholder?: string
  error?: string
  size?: "sm" | "md" | "lg"
  register?: any
  className?: string
  disabled?: boolean
  amountInput?: boolean
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  withCalendar?: boolean
  touched?: boolean
}

const Input: React.FC<FormInputProps> = ({
  id,
  label = "",
  type = "text" || "password" || "email",
  name,
  value,
  placeholder,
  error,
  size,
  register,
  className = "",
  disabled = false,
  amountInput,
  onChange,
  onFocus,
  onKeyDown,
  withCalendar,
  touched,
}) => (
  <div className="flex flex-col w-full">
    <label className="pb-1 text-sm font-bold " htmlFor={name}>
      {label || ""}
    </label>
    <div className="relative">
      {amountInput && (
        <span
          className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary"
          style={{ pointerEvents: "none" }}
        >
          $
        </span>
      )}
      <input
        className={` ${size === "sm" && "p-2"}  ${size === "lg" && "p-4"} ${
          error 
            ? "border-red-500 bg-white focus:ring-red-500 focus:border-red-500"
            : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
        }  border rounded font-regular bg-gray text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none ${className} ${
          amountInput ? "pl-5" : "pl-2"
        }`}
        {...register}
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
    {error && (
      <p className="mt-1 text-xs font-regular text-red-500 ">{error}</p>
    )}
  </div>
)

export default Input
