import axiosInstance from "../../Utils/Https"

export const GetEscrowWalletTransactionHistoryApi = async (
  escrowId: string,
  page: number,
) => {
  const url = `escrow/${escrowId}/escrow-wallet/transaction-history?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetEscrowWalletApi = async (escrowId: string) => {
  const url = `escrow/${escrowId}/escrow-wallet`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetUserSubWalletApi = async (
  communityId: string,
  memberId: string,
) => {
  const url = `/communities/${communityId}/members/${memberId}/wallet`
  const res = await axiosInstance.get(url)
  return res.data
}

export const GetUserSubWalletTransactionHistoryApi = async (
  communityId: string,
  memberId: string,
  page: number,
) => {
  const url = `/communities/${communityId}/members/${memberId}/wallet/transaction-history?page=${page}&limit=10`
  const res = await axiosInstance.get(url)
  return res.data
}
