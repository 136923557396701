import React from "react"
import { Navigate } from "react-router"
import Login from "../Pages/Auth/Login"
import AuthLayout from "../Layouts/AuthLayout"
import Auth from "../Pages/Auth"
import ForgotPasswordScreen from "../Pages/Auth/ForgotPassword"
import Otp from "../Pages/Auth/ForgotPassword/Otp"
import AuthHoc from "../Components/AuthWrapper"
import DashboardLayout from "../Layouts/DashboardLayout/Index"
// import App from "../Pages/app/CommunityAdmin"
import Communities from "../Pages/app/CommunityAdmin/Communities"
import Support from "../Pages/app/CommunityAdmin/Settings/Support"
import NotificationSettings from "../Pages/app/CommunityAdmin/Settings/NotificationSettings"
import PasswordSettings from "../Pages/app/CommunityAdmin/Settings/PasswordSettings"
import EditProfile from "../Pages/app/CommunityAdmin/Settings/EditProfile"
import Settings from "../Pages/app/CommunityAdmin/Settings"
import Wallet from "../Pages/app/CommunityAdmin/Wallet"
import CommunityDetails from "../Pages/app/CommunityAdmin/Communities/CommunityDetails"
import DashBoard from "../Pages/SuperAdmin/Dashboard"
import SuperAdminEscrowGroups from "../Pages/SuperAdmin/EscrowGroups"
import InterCommunityEscrows from "../Pages/SuperAdmin/InterCommunityEscrows"
import Notifications from "../Pages/SuperAdmin/Notifications"
import SuperAdminCommunities from "../Pages/SuperAdmin/Communities"
import SuperAdminCommunityDetails from "../Pages/SuperAdmin/Communities/CommunityDetails"
import SuperAdminWallet from "../Pages/SuperAdmin/Wallet"
import InterCommunityDetails from "../Pages/SuperAdmin/InterCommunityEscrows/InterCommunityDetails"
import SuperAdminEscrowDetails from "../Pages/SuperAdmin/EscrowGroups/EscrowDetails"
import EscrowGroups from "../Pages/app/CommunityAdmin/EscrowGroups"
import EscrowDetails from "../Pages/app/CommunityAdmin/EscrowGroups/EscrowDetails"
import NewPassword from "../Pages/Auth/ForgotPassword/NewPassword"
import EscrowSuperAdminWallet from "../Pages/SuperAdmin/Wallet/EscrowWallet"
import InterCommunityWallet from "../Pages/SuperAdmin/Wallet/InterCommunityEscrow"
import InterCommunityWithdrawalRequestList from "../Pages/SuperAdmin/InterCommunityEscrows/InterWithdrawalRequestList"

export const AutheticationRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "",
      element: <Navigate to="/community/login" replace />,
    },
    {
      path: "/community/login",
      element: <Login />,
    },
    {
      path: "/superadmin/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Auth />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordScreen />,
    },
    {
      path: "/reset-password",
      element: <NewPassword />,
    },
    {
      path: "*",
      element: <Navigate to="/community/login" replace />,
    },
  ],
}

export const DashboardRoutes = {
  path: "/app",
  element: <AuthHoc component={DashboardLayout} />,
  children: [
    {
      path: "/app",
      element: <Navigate to="/community/login" replace />,
    },
    {
      path: "wallet",
      children: [
        {
          index: true,
          element: <Wallet />,
        },
      ],
    },
    {
      path: "settings",
      element: <Settings />,
      children: [
        {
          path: "edit-profile",
          element: <EditProfile />,
        },
        {
          path: "password-settings",
          element: <PasswordSettings />,
        },
        {
          path: "notification-settings",
          element: <NotificationSettings />,
        },
        {
          path: "support",
          element: <Support />,
        },
      ],
    },
    {
      path: "communities",
      children: [
        {
          index: true,
          element: <Communities />,
        },
        {
          path: ":communityId/:communityName",
          element: <CommunityDetails />,
        },
      ],
    },
    {
      path: "escrowGroups",
      children: [
        {
          index: true,
          element: <EscrowGroups />,
        },
        {
          path: ":escrowId",
          element: <EscrowDetails />,
        },
        // {
        //   part: "/superadmin/EscrowUserList",
        // },
        {
          path: "*",
          element: <Navigate to="/community/login" replace />,
        },
      ],
    },
  ],
}

export const SuperAdminRoutes = {
  path: "/superadmin",
  element: <AuthHoc component={DashboardLayout} />,
  children: [
    {
      path: "",
      element: <Navigate to="/superadmin/Dashboard" replace />,
    },
    {
      path: "Dashboard",
      children: [
        {
          index: true,
          element: <DashBoard />,
        },
      ],
    },
    {
      path: "community",
      children: [
        {
          index: true,
          element: <SuperAdminCommunities />,
        },
        {
          path: ":communityId",
          element: <SuperAdminCommunityDetails />,
        },
      ],
    },
    {
      path: "EscrowGroups",
      children: [
        {
          index: true,
          element: <SuperAdminEscrowGroups />,
        },
        {
          path: ":escrowId",
          element: <SuperAdminEscrowDetails />,
          children: [
            {
              path: ":escrowId/EscrowSuperAdminWallet",
              element: <EscrowSuperAdminWallet />,
            },
          ],
        },
      ],
    },

    {
      path: "wallet/:communityId",
      element: <SuperAdminWallet />,
    },
    {
      path: "EscrowSuperAdminWallet/:escrowId",
      element: <EscrowSuperAdminWallet />,
    },
    {
      path: "InterCommunityWallet/:escrowId", // Route to access InterCommunityWallet
      element: <InterCommunityWallet />,
    },
    {
      path: "WithdrawalRequests/:escrowId",
      element: <InterCommunityWithdrawalRequestList />,
    },

    {
      path: "InterCommunityEscrows",
      children: [
        {
          index: true,
          element: <InterCommunityEscrows />,
        },
        {
          path: ":communityId",
          element: <InterCommunityDetails />,
        },
      ],
    },

    {
      path: "Notifications",
      children: [
        {
          index: true,
          element: <Notifications />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/superadmin/Dashboard" replace />,
    },
  ],
}
