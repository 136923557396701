import React, { useEffect, useState } from "react"
import { Profile } from "../../../Assets"
import Table from "../../../Components/elements/Table"
import {
  GetCommunityAdminListApi,
  GetSuperAdminDashboardApi,
  GetSuperAdminCommunityListApi,
} from "../../../Services"
import { BarLoader } from "react-spinners"
import { useParams } from "react-router"
import { CommunityData, totalInfoProps } from "../../../types"
import Pagination from "../../../Components/elements/Pagination"

const Head = [
  { key: "no", label: "No." },
  { key: "communityName", label: "Community name" },
  { key: "members", label: "No. of member" },
  { key: "communityAdmin", label: "Community Admin" },
  { key: "communityLeader", label: "Community Leader" },
]

const backgroundColors = [
  "bg-[#DCFCE7]",
  "bg-[#FFF4DE]",
  "bg-[#FFE2E5]",
  "bg-[#F3E8FF]",
  "bg-[#C2DFFF]",
]

const imgColors = [
  "bg-[#3CD856]",
  "bg-[#FF947A]",
  "bg-[#FA5A7D]",
  "bg-[#BF83FF]",
  "bg-[#3394FF]",
]

const DashBoard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<any>(null)
  const [superAdminCommunityData, setSuperAdminCommunityData] =
    useState<any>(null)
  const [loading, setLoading] = useState(false)
  const { communityId } = useParams()
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)

  const getDashboardData = async () => {
    try {
      setLoading(true)

      const [dashboardResponse, superAdminCommunityResponse] =
        await Promise.all([
          GetSuperAdminDashboardApi(),
          GetSuperAdminCommunityListApi(pageNum),
        ])

      console.log(dashboardResponse)
      console.log(superAdminCommunityResponse)

      setDashboardData(dashboardResponse)
      setSuperAdminCommunityData(superAdminCommunityResponse)
      setPageData(superAdminCommunityResponse.metadata)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getDashboardData()
  }, [pageNum])

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BarLoader color="#3B82F6" />
        </div>
      ) : (
        <div>
          <div className="bg-white rounded-lg p-4 mt-2 mb-4">
            <div>
              <p className="text-black font-bold text-[13px]">
                Users Data Analytics
              </p>
              <p className="text-[#8E8E8E] mt-0 font-bold text-[10px]">
                Data Summary
              </p>
            </div>
            <div className="md:flex justify-between mt-4">
              {totalInfoProps.map((totalInfoProp, index) => (
                <div
                  key={index}
                  className={`md:w-[18%] m-2 p-4 rounded-lg ${backgroundColors[index]} flex flex-col justify-between`}
                >
                  <div
                    className={`flex justify-between items-center w-10 h-10 rounded-full p-3 object-center ${imgColors[index]}`}
                  >
                    <img
                      src={totalInfoProp.img}
                      alt="Total Info"
                      className="w-5 h-5"
                    />
                  </div>
                  <div>
                    <p className="text-lg font-bold mt-3 text-black">
                      {dashboardData
                        ? dashboardData[totalInfoProp.dataKey]
                        : "-"}
                    </p>
                    <p className="text-[10px] font-bold text-black">
                      {totalInfoProp.totalDetails}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="flex mt-4">
            <div className="w-3/5 mr-4">
              <div className="bg-white rounded-lg flex justify-center items-center h-40">
                <p className="font-bold text-black">Total Users</p>
              </div>
            </div>
            <div className="w-2/5">
              <div className="bg-white rounded-lg flex justify-center items-center h-40">
                <p className="font-bold text-black">Traffic by Location</p>
              </div>
            </div>
          </div> */}

          <Table
            headers={Head}
            data={
              superAdminCommunityData?.data
                ? superAdminCommunityData?.data.map(
                    (community: CommunityData, index: number) => ({
                      id: community.id,
                      no: index + 1,
                      communityName: community.communityName,
                      members: community?.members?.length || 0,
                      communityAdmin:
                        community?.communityAdmin?.user?.fullName || "No admin",
                      communityLeader:
                        community?.communityLeader?.user?.fullName ||
                        (community?.communityLeader?.user?.firstName &&
                        community?.communityLeader?.user?.lastName
                          ? `${community?.communityLeader?.user?.firstName} ${community?.communityLeader?.user?.lastName}`
                          : "No community leader"),
                    }),
                  )
                : []
            }
          />
        </div>
      )}
      <div className="flex justify-center mt-5">
        <Pagination pageData={pageData} setPageNum={setPageNum} />
      </div>
    </div>
  )
}

export default DashBoard
