import React, { useEffect, useState } from "react"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { Walletbg } from "../../../Assets"
import GoBack from "../../../Components/elements/Display/GoBack"
import SearchInput from "../../../Components/elements/Forms/Search"
import WalletHeader from "../../../Components/elements/Hero/WalletHeader"
import { Transaction } from "../../../types"

import TransactionsTable from "./TransactionTable"

import ModalPanel from "../../../Components/elements/Modal/Index"
import {
  GetAllLeadersApi,
  GetEscrowWalletApi,
  GetEscrowWalletTransactionHistoryApi,
} from "../../../Services"
import { useLocation, useParams } from "react-router"
import { calculateDurationInDays, formatDate } from "../../../Utils/Helper"
import CommunityLeaders from "../InterCommunityEscrows/CommunityLeaders"
import Search from "../../../Components/elements/Forms/MembersSearch"
import { BounceLoader } from "react-spinners"

const InterCommunityWallet = () => {
  const { escrowId } = useParams()
  const location = useLocation()
  const { escrowGroupData } = location.state || {}
  const [filterTransactionModal, setFilterTransactionModal] = useState(false)
  const [walletData, setWalletData] = useState<any>({})
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([])
  const [leaders, setLeaders] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const communityNames = [
    { name: "Funds Community ", amount: "N50,000" },
    { name: "Happy Family", amount: "N20,000" },
    { name: "Max Contributions", amount: "N50,000" },
    { name: "Fun Community", amount: "N10,000" },
    { name: "Fun Community 2", amount: "N20,000" },
  ]

  const getWalletData = async () => {
    try {
      setLoading(true)
      const res = await GetEscrowWalletApi(escrowId || "")
      const res2 = await GetEscrowWalletTransactionHistoryApi(
        escrowId ?? "",
        pageNum,
      )
      const res3 = await GetAllLeadersApi(1)

      console.log(res)
      console.log(res2.transactions[0])
      console.log(res3.data[0])
      setLeaders(res3.data)
      setWalletData(res)
      setTransactionsData(res2.transactions)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getWalletData()
  }, [pageNum, escrowId])

  const { duration, daysRemaining } = escrowGroupData
  ? calculateDurationInDays(
      escrowGroupData.createdAt,
      escrowGroupData.escrowExpirationDate
    )
  : { duration: "nothing", daysRemaining: "nothing" };


  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mt-2 md:w-[62%]">
            <div>
              <GoBack link="/superadmin/interCommunityEscrows" />
            </div>
            <WalletHeader
              totalEscrowBalance={walletData.balance ? walletData.balance : "0"}
              targetAmount={
                walletData.targetAmount ? walletData.targetAmount : "0"
              }
              escrowDuration={
                duration !== "nothing" ? duration : "0"
              }
              imageUrl={Walletbg}
            />
            <div className="flex mt-8 justify-between items-center">
              <h1 className="text-black text-lg"> Transactions History</h1>
              <button
                type="button"
                onClick={() => setFilterTransactionModal(true)}
                className="flex items-center bg-primary h-8 text-white rounded-md p-2"
              >
                <HiOutlineAdjustmentsHorizontal className="mr-2" />
                Filter transaction
              </button>
            </div>
            <TransactionsTable
                transactions={transactionsData.map((transaction) => ({
                  ...transaction,
                  date: transaction.createdAt
                    ? formatDate(transaction.createdAt)
                    : "",
                  status: transaction.transactionType || "",
                  amount: transaction.transactionAmount || 0,
                  description: transaction.transactionStatus || "",
                  // details: transaction.transactionDetails || "",
                }))} 
                pageData={undefined}
                setPageNum={() => {
                  if (pageData) {
                    setPageNum(pageData.page)
                  }
                }}          />
          </div>
          <div className="pl-2 md:w-[35%]">
            <div className="">
              <p className="text-[16px] text-black font-bold">
                List of Community Leaders
              </p>
              <div className="flex text-[10px] justify-between items-center mb-2">
                <p>
                  <span className="text-primary font-bold ">
                    {leaders.length}{" "}
                  </span>
                  Community Leader(s)
                </p>
              </div>
            </div>
            <div className="rounded-xl p-3 bg-white">
              <div className="">
                <Search />
              </div>
              {Array.isArray(leaders) &&
                leaders.map((leader) => (
                  <CommunityLeaders
                    key={leader.communityLeaderUserId}
                    image={leader.pfpImageUrl}
                    name={leader.name}
                    role={leader.communityName}
                  />
                ))}
            </div>
          </div>

          {/* <ModalPanel
            open={filterTransactionModal}
            closeModal={() => setFilterTransactionModal(false)}
            closeButton
          >
            filterTransactionModal
          </ModalPanel> */}
        </div>
      )}
    </div>
  )
}

export default InterCommunityWallet
