import React, { Component, useState } from "react"

import Otp from "./Otp"
import ForgotPassword from "./ForgotPassword"
import NewPassword from "./NewPassword"

function ForgotPasswordScreen() {
  const [step, setStep] = useState(0)

  let componentToDisplay

  switch (step) {
    case 1:
      componentToDisplay = <Otp handleNext={() => setStep(2)} />
      break
    case 2:
      componentToDisplay = <NewPassword />
      break
    default:
      componentToDisplay = <ForgotPassword handleNext={() => setStep(1)} />
  }

  return componentToDisplay
}

export default ForgotPasswordScreen
