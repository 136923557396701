import React, { ChangeEvent, MouseEvent, useState } from "react"
import { BiSearch } from "react-icons/bi"

interface FormInputProps {
  type?: string
  name?: string
  value?: string
  placeholder?: string
  required?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: React.FC<FormInputProps> = ({
  type,
  name,
  value,
  placeholder,
  required,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
  }

  const handleInputClick = (e: MouseEvent<HTMLInputElement>) => {
    setIsFocused(true)
  }

  const handleInputBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className="flex flex-col">
      {isFocused || value ? (
        <div className="flex items-center rounded-full border border-gray bg-white focus-within:ring-gray focus-within:border-gray py-1 ">
          <input
            className="flex-1  bg-transparent focus:outline-none border-none focus:ring-0 focus:border-0 appearance-none outline-none text-dark-gray font-latoRegular text-sm "
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            required={required}
            onClick={handleInputClick}
            onBlur={handleInputBlur}
          />
          <BiSearch
            className=" ml-3"
            color="#0078FF"
            fontSize="20px"
            fontWeight="bold"
          />
        </div>
      ) : (
        <div className="flex items-center rounded-full border-0 bg-slate-200  p-3 ">
          <input
            className="flex-1  bg-transparent  placeholder-[#0078FF] focus:outline-none focus:ring-0 focus:border-0   border-none appearance-none outline-none  font-latoRegular text-sm "
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            placeholder="Search"
            required={required}
            onClick={handleInputClick}
            onBlur={handleInputBlur}
          />

          <BiSearch className="ml-2" color="#0078FF" fontSize="20px" />
        </div>
      )}
    </div>
  )
}

export default SearchInput
