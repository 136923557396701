import React, { useEffect, useState } from "react"
import { Switch } from "@headlessui/react"
import { inAppNotificationsApi } from "../../../../Services"

const NotificationSettings = () => {
  const [appNotificationsEnabled, setAppNotificationsEnabled] = useState(false)
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(false)

    // useEffect(() => {
    //   const fetchNotificationSettings = async () => {
    //     try {
    //       const notificationsData = await notificationsApi();
    //       setAppNotificationsEnabled(notificationsData.inAppNotificationsEnabled);
    //     } catch (error) {
    //       console.error("Error fetching notification settings:", error);
    //     }
    //   };
  
    //   fetchNotificationSettings();
    // }, []);
  
    const handleAppNotificationsToggle = async () => {
      try {
        await inAppNotificationsApi(!appNotificationsEnabled);
        setAppNotificationsEnabled(!appNotificationsEnabled);
        console.log("Notification setting updated successfully!");
      } catch (error) {
        console.error("Error updating notification setting:", error);
      }
    };

  return (
    <div>
      <div className="p-3 bg-white rounded-lg mr-3">
        <h5 className="text-lg font-bold text-black">Notification Settings</h5>
        <p className="text-xs text-black">
          Enable and disable notification settings
        </p>
      </div>
      <div className="mt-6 flex justify-between items-center">
        <p className="text-[16px] pl-3">App notifications</p>
        <Switch
          checked={appNotificationsEnabled}
          onChange={handleAppNotificationsToggle}
          className={`${appNotificationsEnabled ? "bg-primary" : "bg-dark-gray"}
            relative mr-8 inline-flex h-[34px] w-[70px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
        >
          <span
            aria-hidden="true"
            className={`${
              appNotificationsEnabled ? "translate-x-9" : "translate-x-0"
            }
              pointer-events-none inline-block h-[31px] w-[31px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <div className="mt-6 flex justify-between items-center">
        <p className="text-[16px] pl-3">Push notifications</p>
        <Switch
          checked={pushNotificationsEnabled}
          onChange={setPushNotificationsEnabled}
          className={`${
            pushNotificationsEnabled ? "bg-primary" : "bg-dark-gray"
          }
            relative mr-8 inline-flex h-[34px] w-[70px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
        >
          <span
            aria-hidden="true"
            className={`${
              pushNotificationsEnabled ? "translate-x-9" : "translate-x-0"
            }
              pointer-events-none inline-block h-[31px] w-[31px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  )
}

export default NotificationSettings
