import { useRoutes } from "react-router-dom"
import {
  AutheticationRoutes,
  DashboardRoutes,
  SuperAdminRoutes,
} from "./AppRoutes"

const Routes = () =>
  useRoutes([AutheticationRoutes, DashboardRoutes, SuperAdminRoutes])

export default Routes
