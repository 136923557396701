import React, { useEffect, useState } from "react"
import Table from "../../../Components/elements/Table"
import { Profile } from "../../../Assets"
import { GetSuperAdminInterCommunityEscrowGroupsApi } from "../../../Services"
import { BounceLoader } from "react-spinners"
import Pagination from "../../../Components/elements/Pagination"

const InterCommunityEscrows: React.FC = () => {
  const [interCommunityEscrows, setInterCommunityEscrows] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState<any>()
  const [pageNum, setPageNum] = useState(1)

  const Head = [
    { key: "no", label: "No." },
    { key: "communityName", label: "Inter-community Escrow name" },
    { key: "members", label: "No. of member" },
    { key: "communityAdmin", label: "Community Admin" },
  ]

  const fetchInterCommunityEscrows = async () => {
    try {
      setLoading(true)
      const res = await GetSuperAdminInterCommunityEscrowGroupsApi(pageNum)
      console.log(res)
      setInterCommunityEscrows(res.data)
      setPageData(res.metaData)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchInterCommunityEscrows()
  }, [pageNum])

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BounceLoader color="#3B82F6" />
        </div>
      ) : (
        <>
          <div className="mt-6">
            <p className="font-bold text-black text-[18px] mt-4">
              {" "}
              List of Inter-community escrow
            </p>
            <p className="text-[12px] mt-0">
              You have{" "}
              <span className="text-primary font-bold">
                {interCommunityEscrows.length}
              </span>{" "}
              Inter-community escrow groups
            </p>
            <Table
              headers={Head}
              data={interCommunityEscrows.map((item: any, index: number) => ({
                id: item.escrowId,
                no: index + 1,
                communityName: item.escrowName,
                members: item.escrowMemberData.totalNumberOfMembers,
                communityAdmin: item.escrowAdmin,
              }))}
            />
          </div>
          <div className="flex justify-center mt-5">
            <Pagination pageData={pageData} setPageNum={setPageNum} />
          </div>
        </>
      )}
    </div>
  )
}

export default InterCommunityEscrows
