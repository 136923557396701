import React, { useEffect, useState } from "react"
import Card from "../../../../Components/elements/Card"
import { useNavigate } from "react-router-dom"
import { MainButton } from "../../../../Components/elements/Button"
import {
  CommunityIMG,
  FileUploadIcon,
  NoItem,
  SuccessIcon,
  Profile,
  FailureIcon,
} from "../../../../Assets"
import Input from "../../../../Components/elements/Forms/Input"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import ResultToast from "../../../../Components/elements/Display/ToastModal"
import Textarea from "../../../../Components/elements/Forms/TextArea"
import { Uploader } from "rsuite"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  CreateNewCommunityApi,
  getCommunityListApi,
} from "../../../../Services"
import { Community } from "../../../../types"
import { BarLoader, ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import { formatDate } from "../../../../Utils/Helper"

type Inputs = {
  communityName: string
  communityDescription: string
  communityImage: FileList
}

const schema = yup.object().shape({
  communityName: yup.string().required("Community name is required"),
  communityDescription: yup
    .string()
    .required("Community description is required"),
  // communityImage: yup.mixed().required("Community image is required"),
  communityImage: yup
  .mixed()
  .required("Community image is required")
  .test(
    "fileSize",
    "Community image is required",
    (value) => value instanceof FileList && value.length > 0
  ),
})

const Communities: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema) as any,
  })

  const [communityList, setCommunityList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [refreshComponent, setRefreshComponent] = useState(false)
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [openFailureToast, setOpenFailureToast] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const getAdminCommunityList = async () => {
    try {
      setLoading(true)
      const response = await getCommunityListApi()
      console.log(response)
      setCommunityList(response)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAdminCommunityList()
  }, [refreshComponent])

  const communityImage = watch("communityImage")
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setSubmitting(true)
      console.log(data)

      const formData = new FormData()
      formData.append("communityName", data.communityName)
      formData.append("communityDescription", data.communityDescription)
      if (data.communityImage && data.communityImage.length > 0) {
        formData.append("communityImage", data.communityImage[0])
      }

      console.log("FormData before sending:", formData)

      const response = await CreateNewCommunityApi(formData)
      console.log(response)
      // toast.success(response.message)
      setOpenToast(true)
      reset()
      setSubmitting(false)
      setOpenModal(false)
      setRefreshComponent((prev) => !prev)
    } catch (error: any) {
      console.log(error)
      setOpenModal(false)
      setOpenFailureToast(true)
      setSubmitting(false)
      reset()
    }
  }

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BarLoader color="#3B82F6" />
        </div>
      ) : (
        <>
          <div>
            <div className="mt-5">
              <MainButton onClick={() => setOpenModal(true)}>
                <span className="bg-white text-blue-600 p-1 rounded m-4">
                  +
                </span>
                Create Community
              </MainButton>
            </div>
            <div>
              {communityList.length === 0 ? (
                <div className="flex items-center justify-center h-[80vh]">
                  <img src={NoItem} alt="" />
                  <p>No Community Available</p>
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
                  {communityList.map((community: Community) => (
                    <Card
                      key={community.communityName}
                      to={`/app/communities/${
                        community.id
                      }/${encodeURIComponent(community.communityName)}`}
                      imageUrl={community.communityImageUrl}
                      title={community.communityName}
                      date={formatDate(community.createdAt)}
                      description={community.communityDescription}
                      avatarUrls={Object.values(
                        community.firstFourMembersImages,
                      )}
                      count={community.numberOfCommunityMembers.numberToDisplay}
                      members={community.numberOfCommunityMembers.totalNumber}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <ModalPanel
            closeModal={() => {
              setOpenModal(false)
              reset()
            }}
            open={openModal}
            closeButton
            className="max-h-80"
            title="Create Community"
          >
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col ">
              <div className="flex justify-between" />
              {/* <div className="mr-20">
                A minimum of 1 community and a maximum of 3 communities are
                allowed to be created
              </div> */}
              <div className="flex flex-col">
                <div className="py-3">
                  <Input
                    register={register("communityName", {
                      required: "Community name is required",
                    })}
                    label="Community Name"
                    name="communityName"
                    placeholder="Enter community name"
                    className="bg-off-white"
                  />
                  <div>
                    {errors.communityName && (
                      <p className="text-red-500 text-sm">
                        {errors?.communityName?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="py-3">
                  <Textarea
                    register={register("communityDescription", {
                      required: "Community description is required",
                    })}
                    label="Description"
                    name="communityDescription"
                    placeholder=" Enter description"
                    className="bg-off-white"
                  />
                </div>
                <div>
                  {errors.communityDescription && (
                    <p className="text-red-500 text-sm">
                      {errors?.communityDescription?.message}
                    </p>
                  )}
                </div>
                <div className="py-3 pb-6">
                  <div className="border-2 border-primary rounded-md">
                    {communityImage && communityImage.length > 0 ? (
                      <>
                        {console.log(
                          "Image URL:",
                          URL.createObjectURL(communityImage[0]),
                        )}
                        <button
                          onClick={() =>
                            document.getElementById("fileInput")?.click()
                          }
                          className="cursor-pointer"
                          type="button"
                        >
                          <img
                            src={URL.createObjectURL(communityImage[0])}
                            alt="uploaded file"
                            style={{
                              width: "100%",
                              height: "300px",
                              objectFit: "cover",
                            }}
                          />
                        </button>
                      </>
                    ) : (
                      <Uploader
                        style={{ padding: "20px" }}
                        listType="picture-text"
                        autoUpload={false}
                        draggable
                        action=""
                        onChange={(fileList) => {
                          const validFiles = fileList.filter((file) => {
                            const fileType = file.blobFile?.type;
                            return fileType === "image/jpeg" || fileType === "image/png";
                          });
                      
                          if (validFiles.length > 0) {
                            const dataTransfer = new DataTransfer();
                            validFiles.forEach((file) => {
                              if (file.blobFile) {
                                dataTransfer.items.add(file.blobFile);
                              }
                            });
                            setValue("communityImage", dataTransfer.files);
                          } else {
                            toast.error("Please upload only JPG or PNG files.");
                          }
                        }}
                      >
                        <div
                          style={{
                            height: 150,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={FileUploadIcon} alt="file upload" />
                        </div>
                      </Uploader>
                    )}
                  </div>
                  <input
                    id="fileInput"
                    type="file"
                    {...register("communityImage", {
                      required: "Community image is required",
                    })}
                    accept=".jpg,.jpeg,.png"
                    className="hidden"
                  />
                   {errors.communityImage && (
      <p className="text-red-500 text-sm mt-2">
        {errors.communityImage.message}
      </p>
    )}
                </div>
              </div>
              <div className="flex justify-end">
                <MainButton type="submit" disabled={submitting}>
                  {submitting ? (
                    <div className="flex justify-center items-center h-3">
                      <ClipLoader color="#ffffff" />
                    </div>
                  ) : (
                    <>
                      <span className="bg-white text-blue-600 p-1 rounded m-4">
                        +
                      </span>
                      Create Community
                    </>
                  )}
                </MainButton>
              </div>
            </form>
          </ModalPanel>
          <ResultToast
        image={SuccessIcon} 
        mainText="You’ve Successfully created a community" 
        buttonText="Ok" 
        closeModal={() => setOpenToast(false)}
        open={openToast}
/>
          <ResultToast
        image={FailureIcon} 
        mainText="Community creation failed" 
        buttonText="Try Again" 
        closeModal={() => setOpenFailureToast(false)}
        open={openFailureToast}
/>
        </>
      )}
    </div>
  )
}

export default Communities
