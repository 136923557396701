import React, { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Input from "../../Components/elements/Forms/Input"
import { MainButton } from "../../Components/elements/Button"
import { UserSignUpApi } from "../../Services"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"




type FormProps = {
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: Date
  BVN: number
  phoneNumber: string
}

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  gender: yup.string().required("Gender is required"),
  dateOfBirth: yup.date().required("Date of Birth is required"),
  BVN: yup.number()
  .required("BVN is required")
  .typeError("BVN is required")
  .positive("BVN must be a positive number")
  .integer("BVN must be an integer")
  .test(
    "is-eleven-digits",
    "BVN must be 11 digits",
    (value) => value.toString().length === 11
  ),
  phoneNumber: yup.string().required("Phone Number is required").matches(
    /^(0|\+?234)\d{10}$/,
    "Please enter a valid phone number"
  ),
})

function AuthOne() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
  })
  
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    
    try {
      setLoading(true)
      console.log("Form Data:", data)

      const res = await UserSignUpApi(data)
      console.log("Register API Response:", res)
      toast.success(res.message)
      reset()
      navigate("/app/communities")
    } catch (error:any) {
      console.error("Error:", error)
      toast.error(error.response?.data?.error?.message || "An error occurred")
    }
    finally {
      setLoading(false)
    }
  }
  console.log("Errors:",dirtyFields, errors);
  return (
    <div className="w-full mt-16 mb-8 flex items-center justify-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[60%]">
        <h3 className="text-primary text-2xl">Personal Information</h3>
        <p className="text-md">Enter login details provided to get started</p>
        <div className="flex py-5">
          <div className="flex-[.5] mr-1 bg-primary h-1 rounded-sm" />
          <div className="flex-[.5] mr-1 bg-primary h-1 rounded-sm" />
        </div>
        <div className="py-3">
          {/* <Input
            {...register("fullName")}
            error={errors.fullName?.message}
            label="Full Name"
            placeholder="Enter first name"
            className="bg-off-white"
          /> */}
          <label htmlFor="firstName" className="pb-1 text-sm font-bold ">
            First Name
            </label>
            <input
              {...register("firstName")}
              id="firstName"
              name="firstName"
              type="text"
              placeholder="Enter first name"
              className={`${
                errors.firstName
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            {errors.firstName && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>
        <div className="py-3">
          {/* <Input
            {...register("lastName")}
            error={errors.lastName?.message}
            label="Last Name"
            placeholder="Enter Last Name"
            className="bg-off-white"
          /> */}
           <label htmlFor="lastName" className="pb-1 text-sm font-bold ">
            Last Name
            </label>
            <input
              {...register("lastName")}
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Enter last name"
              className={`${
                errors.lastName
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            {errors.lastName && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.lastName.message}
            </p>
          )}
        </div>
        <div className="py-3">
          <p className="pb-2 text-sm font-bold">Gender</p>
          <div className="flex w-full ">
            <label className="flex items-center">
              <input
                type="radio"
                {...register("gender")}
                value="Male"
                className="m-2 items-center"
              />
              Male
            </label>
            <label className="flex items-center pl-10">
              <input
                type="radio"
                {...register("gender")}
                value="Female"
                className="m-2 items-center"
              />
              Female
            </label>
          </div>
          {errors.gender && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.gender.message}
            </p>
          )}
        </div>
        <div className="py-3 ">
          <label htmlFor="dob"  className="pb-1 text-sm font-bold ">Date of Birth</label> <br />
          <div style={{ width: "100%" }}>
          <DatePicker
            id="dob"
            selected={watch("dateOfBirth")}
            onChange={(date: Date) => setValue("dateOfBirth", date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Enter date of birth"
            className={`w-full border bg-off-white rounded-md px-3 py-3 mt-2 focus:outline-none focus:border-dark-gray ${errors.dateOfBirth ? 'border-red-500' : 'border-dark-gray'}`}
          />
          </div>
          {errors.dateOfBirth && (
            <div className="text-red-500 text-[12px] pt-1">{errors.dateOfBirth.message}</div>
          )}
        </div>
        <div className="py-3">
          {/* <Input
            {...register("BVN")}
            error={errors.BVN?.message}
            label="BVN"
            placeholder="Enter BVN"
            className="bg-off-white"
          /> */}
          <label htmlFor="BVN" className="pb-1 text-sm font-bold ">
            BVN
            </label>
            <input
              {...register("BVN")}
              id="BVN"
              name="BVN"
              type="number"
              placeholder="Enter BVN"
              className={`${
                errors.BVN
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            {errors.BVN && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.BVN.message}
            </p>
          )}
        </div>
        <div className="py-3">
          {/* <Input
            {...register("phoneNumber")}
            error={errors.phoneNumber?.message}
            label="Phone Number"
            placeholder="Enter your phone number"
            className="bg-off-white"
          /> */}
 <label htmlFor="phoneNumber" className="pb-1 text-sm font-bold ">
            Phone Number
            </label>
            <input
              {...register("phoneNumber")}
              id="phoneNumber"
              name="phoneNumber"
              type="number"
              placeholder="Enter your phone number"
              className={`${
                errors.phoneNumber
                  ? "border-red-500"
                  : "border-dark-gray  focus:ring-dark-gray focus:border-dark-gray"
              }  border rounded font-regular bg-white text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none pl-2`}
            />
            {errors.phoneNumber && (
            <p className="text-red-500 text-[12px] pt-1">
              {errors.phoneNumber.message}
            </p>
          )}
        </div>
        <div className="py-5">
          <MainButton isLoading={loading} type="submit">
            Save and continue
          </MainButton>
        </div>
      </form>
    </div>
  )
}

export default AuthOne
