import React from "react"
import { MainButton } from "../Button"
import ModalPanel from "../Modal/Index"

interface ToastModalProps {
    closeModal: () => void;
    open: boolean;
image: string
  mainText: string
  buttonText: string
  className?: string
}

const ToastModal = ({ image, mainText, buttonText, className, closeModal, open }: ToastModalProps) => (
    <ModalPanel
    closeModal={closeModal}
    open={open}
    modalSize="xl:w-[35%]"
    >
  <div className={` ${className} flex flex-col items-center gap-6 mx-16 my-6`}>
    <img 
    src={image}
    alt={buttonText}
    className="h-22 w-22"
    />
    <p className="text-center text-[15px] text-[#091E05]">{mainText}</p>
    <MainButton onClick={closeModal}>{buttonText}</MainButton>
  </div>
  </ModalPanel>
)

export default ToastModal
