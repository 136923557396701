import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  adminSidebarLinks,
  superAdminSidebarLinks,
  sidebarBottom,
} from "./data";
import { useSelector } from "react-redux";
import { UserAccountType } from "../../../types";
import SidebarLayout from "./SidebarLayout";
import useCurrentPath from "../../../Hooks/useCurrentPath";
import { IoMdClose } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { FundsWalletIcon } from "../../../Assets";

const MobileSideNav = () => {
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const activeLink = useCurrentPath();
  console.log(activeLink[2]);

  const { user } = useSelector((state: any) => state.auth);

  const accountType = user ? UserAccountType.COMMUNITY_ADMIN : UserAccountType.SUPER_ADMIN;

  const sidebarLinks =
    currentPath[1] === "superadmin" ? superAdminSidebarLinks : adminSidebarLinks;

  const sidebarBottomLinks = sidebarBottom[accountType as UserAccountType] || [];

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleLinkClick = (url: string) => {
    if (url === "logout") {
      handleLogout();
    } else {
      navigate(url);
    }
    if (isOpen) {
      toggleSidebar();
    }
  };

  const handleLogout = () => {
    window.location.href = "/login";
    localStorage.clear();
  };

  return (
    <div>
      <button onClick={toggleSidebar} className="p-4 lg:hidden">
        <RxHamburgerMenu className="text-2xl" />
      </button>
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-50 ${
          isOpen ? "block" : "hidden"
        } lg:hidden`}
      >
        <div className="bg-primary h-full flex flex-col justify-between">
          <div className="m-5 flex justify-between items-center">
            <img src={FundsWalletIcon} alt="pyramid" className="w-20 h-20" />
            <div>
              <IoMdClose className="text-3xl text-white" onClick={toggleSidebar} />
            </div>
          </div>
          <div className="border-t-2 mx-4 border-[#F4F6FA]">
            <div>
              {sidebarLinks.map(({ id, title, image, url }) => (
                <div
                  onClick={() => handleLinkClick(url)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleLinkClick(url)}
                  key={id}
                  className="py-[1px]"
                  style={{
                    background:
                      url === activeLink[2]
                        ? "linear-gradient(270deg, #FFF -47.16%, rgba(255, 255, 255, 0.00) 72.88%)"
                        : "",
                  }}
                >
                  <SidebarLayout title={title} image={image} />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-auto">
            {sidebarBottomLinks.map(({ id, title, image, url }) => (
              <div
                onClick={() => handleLinkClick(url)}
                role="button"
                tabIndex={0}
                onKeyDown={() => handleLinkClick(url)}
                key={id}
                className="py-[1px]"
                style={{
                  background:
                    url === activeLink[2]
                      ? "linear-gradient(270deg, #FFF -47.16%, rgba(255, 255, 255, 0.00) 72.88%)"
                      : "",
                }}
              >
                <SidebarLayout title={title} image={image} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSideNav;
