import React, { useState } from "react"
import { MainButton } from "../Button"
import { useLocation, useNavigate } from "react-router-dom"
import ModalPanel from "../Modal/Index"

interface TableProps {
  headers: Array<{ key: string; label: string }>
  data: Array<{
    [key: string]: string | number | Array<string> | { count: number }
  }>
}

const Table: React.FC<TableProps> = ({ headers, data }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<any>(null)

  const handleActionClick = (label: string, rowData: any) => {
    console.log("Row Data:", rowData)
    // You can add conditions here to determine whether to show a modal or redirect
    const shouldShowModal =
      location.pathname.includes(
        `/superadmin/community//${rowData.id}`, 
      ) ||
      location.pathname.includes(
        `/superadmin/InterCommunityEscrows//${rowData.id}`
      ) ||
      location.pathname.includes(
        `/superadmin/escrowGroups//${rowData.id}`
      ) ||
      location.pathname.includes(
        `/app/escrowGroups//${rowData.id}`
      )

    if (shouldShowModal) {
      // Set the selected row data and show the modal
      setSelectedRowData(rowData)
      setShowModal(true)
    } else {
      // Get the current path from the location object
      const currentPath = location.pathname

      // Determine the destination path based on the current path
      let destinationPath = ""

      if (currentPath.includes("/superadmin/community")) {
        destinationPath = `/superadmin/community/${rowData.id}`
      } else if (currentPath.includes("/superadmin/interCommunityEscrows")) {
        destinationPath = `/superadmin/InterCommunityEscrows/${rowData.id}`
      } else if (currentPath.includes("/superadmin/escrowGroups")) {
        destinationPath = `/superadmin/escrowGroups/${rowData.id}`
      } else if (currentPath.includes("/app/escrowGroups")) {
        destinationPath = `/app/escrowGroups/${rowData.id}`
      }
      if (currentPath.includes("/superadmin/dashboard")) {
        destinationPath = `/superadmin/community/${rowData.id}`
      }

      // Redirect to the determined destination path
      navigate(destinationPath, { state: { rowData } })
      console.log(destinationPath, rowData)
  
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div>
      <table
        className="min-w-full border-separate"
        style={{ borderSpacing: "0 15px" }}
      >
        <thead>
          <tr className="mb-3 p-3 border-b text-black">
            {headers.map((header) => (
              <th key={header.key} className="py-2 px-4 border-b">
                {header.label}
              </th>
            ))}
            <th className="py-2 px-4 border-b text-black">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className="rounded-full bg-white"
              style={{ borderRadius: "10px" }}
            >
              {headers.map((header) => (
                <td
                  key={header.key}
                  className="py-2 capitalize px-4 border-b-0 text-center text-black"
                >
                  {header.key === "members" &&
                  row[header.key] instanceof Object ? (
                    <div className="flex -space-x-2 justify-center">
                      {(
                        row[header.key] as {
                          avatarUrls: string[]
                          count: number
                        }
                      ).avatarUrls.map((avatarUrl, avatarIndex) => (
                        <img
                          key={avatarIndex}
                          className="w-6 h-6 rounded-full ring-2 ring-white mt-3 mb-0"
                          src={avatarUrl}
                          alt={`Avatar ${avatarIndex + 1}`}
                        />
                      ))}
                      {(
                        row[header.key] as {
                          avatarUrls: string[]
                          count: number
                        }
                      ).count > 0 && (
                        <span className="flex items-center bg-blue-600 rounded-full text-[7px] text-white w-6 h-6 mt-3 mb-0 pl-1">
                          +
                          {
                            (
                              row[header.key] as {
                                avatarUrls: string[]
                                count: number
                              }
                            ).count
                          }
                        </span>
                      )}
                    </div>
                  ) : (
                    // Explicitly cast the content to ReactNode
                    (row[header.key] as React.ReactNode)
                  )}
                </td>
              ))}
              <td className="py-2 px-4 border-b-0">
                <MainButton
                  className="w-[89px]"
                  onClick={() =>
                    handleActionClick(row[headers[0].key] as string, row)
                  }
                >
                  View
                </MainButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <ModalPanel closeModal={closeModal}>
          <div className="flex flex-col gap-4">
            {/* Render selectedRowData in the modal */}
            {Object.keys(selectedRowData).map((key, index) => (
              <div key={index} className="flex flex-col gap-2">
                <p className="text-sm font-bold text-gray-600">{key}</p>
                <p className="text-base font-bold text-gray-600">
                  {selectedRowData[key]}
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-4 mt-4">
            <MainButton onClick={closeModal}>Close</MainButton>
            <MainButton onClick={closeModal}>Action</MainButton>
          </div>
        </ModalPanel>
      )}
    </div>
  )
}

export default Table
