import React from "react"
import {
  CommunityIcon,
  SettingsIcon,
  WalletIcon,
  EscrowGroupsIcon,
  NotificationBellIconWhite,
  DashboardIcon,
  Intercom,
  LogOut,
} from "../../../Assets"
import { UserAccountType } from "../../../types"

export interface SidebarLinks {
  id: number
  title: string
  url: string
  image?: string | React.ReactElement | undefined
  headingText: string
}

export const adminSidebarLinks: SidebarLinks[] = [
  {
    id: 1,
    title: "Communities",
    url: "communities",
    image: CommunityIcon,
    headingText: "Communities",
  },
  {
    id: 2,
    title: "Escrow Groups",
    url: "escrowGroups",
    image: EscrowGroupsIcon,
    headingText: "Escrow Groups",
  },
  // {
  //   id: 3,
  //   title: "Wallet",
  //   url: "wallet",
  //   image: WalletIcon,
  //   headingText: "Wallet",
  // },
  {
    id: 3,
    title: "Settings",
    url: "settings/edit-profile",
    image: SettingsIcon,
    headingText: "Settings",
  },
]

export const superAdminSidebarLinks: SidebarLinks[] = [
  {
    id: 1,
    title: "Dashboard",
    url: "dashboard",
    image: DashboardIcon,
    headingText: "Dashboard",
  },
  {
    id: 2,
    title: "Community",
    url: "community",
    image: SettingsIcon,
    headingText: "Community",
  },

  {
    id: 3,
    title: "Escrow Groups",
    url: "escrowGroups",
    image: EscrowGroupsIcon,
    headingText: "Escrow Groups",
  },
  {
    id: 4,
    title: "Inter-community escrows",
    url: "interCommunityEscrows",
    image: Intercom,
    headingText: "Inter-community escrows",
  },
  {
    id: 5,
    title: "Notifications",
    url: "notifications",
    image: NotificationBellIconWhite,
    headingText: "Notifications",
  },
]

export const sidebarLinks: Record<UserAccountType, SidebarLinks[]> = {
  [UserAccountType.SUPER_ADMIN]: superAdminSidebarLinks,
  [UserAccountType.COMMUNITY_ADMIN]: adminSidebarLinks,
  [UserAccountType.COMMUNITY_MEMBER]: [],
  [UserAccountType.COMMUNITY_LEADER]: []
}

export const SidebarBottomLinks: SidebarLinks[] = [
  {
      id: 1,
      title: "Logout",
      url: "logout",
      image: LogOut,
      headingText: "Logout"
  }
];

export const sidebarBottom: Record<UserAccountType, SidebarLinks[]> = {
  [UserAccountType.SUPER_ADMIN]: SidebarBottomLinks,
  [UserAccountType.COMMUNITY_ADMIN]: SidebarBottomLinks,
  [UserAccountType.COMMUNITY_MEMBER]: [],
  [UserAccountType.COMMUNITY_LEADER]: []
}


export default sidebarLinks
