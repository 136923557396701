import React, { useState } from "react"

type Props = {
  id?: string
  name: string
  title?: string
  onChange?: (checked: boolean) => void
  error?: string
}

const CheckBox = ({
  id,
  name,
  title = "Check Label",
  onChange,
  error = "",
}: Props) => {
  const [checked, setChecked] = useState(false)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked
    setChecked(newChecked)

    if (onChange) {
      onChange(newChecked)
    }
  }

  return (
    <div>
      <div className="flex gap-2 items-center justify-center">
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={handleCheckboxChange}
          className="accent h-4 w-4 transition duration-150 ease-in-out"
        />
        <label htmlFor={name} className="text-dark-gray font-regular text-sm">
          {title || ""}
        </label>
      </div>
      {error && (
        <p className="mt-2 text-xs text-primary">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </div>
  )
}

export default CheckBox
