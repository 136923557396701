import React, { ChangeEvent, useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import {
  Profile,
  CommunityIMG,
  WalletIcon,
  NotificationIcon,
  FailureIcon,
  SuccessIcon,
} from "../../../Assets"
import { MainButton, OutlineButton } from "../../../Components/elements/Button"
import GoBack from "../../../Components/elements/Display/GoBack"
import Hero from "../../../Components/elements/Hero"
import CommunityMembers from "../../../Components/elements/Display/CommunityList"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FaArrowRightLong, FaUserPlus } from "react-icons/fa6"
import Table from "../../app/CommunityAdmin/EscrowGroups/EscrowTable"
import ModalPanel from "../../../Components/elements/Modal/Index"
import { FiSend } from "react-icons/fi"
import { RiUserFollowLine } from "react-icons/ri"
import Input from "../../../Components/elements/Forms/Input"
import AdminList from "../../../Components/elements/Table/AdminList"
import Search, {
  useDebounce,
} from "../../../Components/elements/Forms/MembersSearch"
import { CgClose } from "react-icons/cg"
import {
  GetCommunityAdminListApi,
  GetCommunityWithdrawalRequestsApi,
  GetSingleCommunityApi,
  sendNotificationToSingleCommunityApi,
  replaceCommunityAdminApi
} from "../../../Services"
import { BounceLoader, ClipLoader } from "react-spinners"
import Pagination from "../../../Components/elements/Pagination"
import Textarea from "../../../Components/elements/Forms/TextArea"
import { CommunityAdmin, WithdrawalRequestProps } from "../../../types"
import ToastModal from "../../../Components/elements/Display/ToastModal"

type Inputs = {
  subject: string
  message: string
}

const schema = yup.object().shape({
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
})

const SuperAdminCommunityDetails: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  })
  const { communityId } = useParams()
  const [inviteModal, setInviteModal] = useState(false)
  const [sendInviteModal, setSendInviteModal] = useState(false)
  const [pickAdminModal, setPickAdminModal] = useState(false)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [communityData, setCommunityData] = useState<any>(null)
  const [pageData, setPageData] = useState<any>()
  const [communityAdmins, setCommunityAdmins] = useState<CommunityAdmin[]>([])
  const [pageNum, setPageNum] = useState(1)
  const [pageNum1, setPageNum1] = useState(1)
  const location = useLocation()
  const { rowData } = location.state || {}
  const [withdrawalRequestsData, setWithdrawalRequestsData] = useState<
    WithdrawalRequestProps[]
  >([])
  const [totalNumberOfAdmins, setTotalNumberOfAdmins] = useState(0)
  const [pageData1, setPageData1] = useState<any>()
  const [submitting, setSubmitting] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [openFailureToast, setOpenFailureToast] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const filteredMembers = communityData?.communityMembersData?.filter(
    (member: any) =>
      member?.userDetails?.user?.firstName
        .toLowerCase()
        .includes(debouncedSearchTerm.toLowerCase()) ||
      member?.userDetails?.user?.lastName
        .toLowerCase()
        .includes(debouncedSearchTerm.toLowerCase()),
  )

  const sortedMembers = communityData?.communityMembersData?.sort(
    (a: any, b: any) =>
      (b.userDetails.communityLeader ? 1 : 0) -
      (a.userDetails.communityLeader ? 1 : 0),
  )

  const Head = [
    { key: "no", label: "No." },
    { key: "memberProfile", label: "Member Name" },
    { key: "withdrawalAmount", label: "Withdrawal Amount" },
  ]

  const communityDetails = async () => {
    try {
      const res = await GetSingleCommunityApi(communityId as string, pageNum)
      const res2 = await GetCommunityWithdrawalRequestsApi(
        communityId as string,
        pageNum,
      )
      const res3 = await GetCommunityAdminListApi(pageNum1)

      console.log(res)
      console.log(res2)
      console.log(res3)
      setCommunityData(res)
      setWithdrawalRequestsData(res2)
      setCommunityAdmins(res3.data)
      setTotalNumberOfAdmins(res3.totalNumberOfAdmins)
      setLoading(false)
      setPageData(res.metadata)
      setPageData1(res3.metadata)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getInitials = (fullName: string | null) => {
    const names = fullName?.split(" ")
    const initials = names?.map((name) => name[0]).join("")
    return initials?.toUpperCase()
  }

  useEffect(() => {
    communityDetails()
  }, [pageNum, pageNum1])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      console.log(data)
      const payload = {
        communityIds: communityId,
        notificationTitle: data.subject,
        notificationMessage: data.message,
      }
      setSubmitting(true)
      await sendNotificationToSingleCommunityApi(payload)
      setSubmitting(false)
      setOpenNotificationModal(false)
      setOpenToast(true)
      reset()
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      setOpenNotificationModal(false)
      setOpenFailureToast(true)
      setSubmitting(false)
      reset()
    }
  }

  const usersData = communityAdmins.map((admin) => ({
    key: admin.id,
    img: admin.user.profilePictureFileKey || Profile,
    name: `${admin?.user.firstName} ${admin?.user.lastName}`,
    communityAdmin: admin?.communityName,
    email: admin.user.emailAddress,
    communityId: communityId,
  }))

  const data = withdrawalRequestsData.map((request, index) => ({
    no: index + 1,
    withdrawalRequestId: request.id,
    memberProfile: {
      memberName: `${request.communityMember.user.firstName} ${
        request.communityMember.user.lastName || ""
      }`,
      image: Profile,
    },
    withdrawalAmount: request.amount,
    email: request.communityMember.user.emailAddress,
    phoneNumber: request.communityMember.user.phoneNumber || "",
    withdrawalRequestReason: request.withdrawalRequestReason,
    withdrawalRequestType: request.withdrawalRequestType,
    accountName: request.accountDetails.accountName,
    accountNumber: request.accountDetails.accountNumber,
    bank: request.accountDetails.bank,
  }))

  return (
    <>
      <div>
        {loading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <BounceLoader color="#3B82F6" />
          </div>
        ) : (
          <>
            <div className="flex flex-col mt-4 md:flex-row justify-between">
              <div className="md:w-[62%]">
                <GoBack link="/superadmin/community" />
                <Hero
                  imageUrl={
                    communityData?.community?.imageUrl
                      ? communityData?.community?.imageUrl
                      : CommunityIMG
                  }
                />
                <div className="flex flex-col md:flex-row mt-3 justify-between w-full">
                  <div className="flex gap-4 justify-between items-center w-full">
                    <div className="flex items-center w-full md:w-1/3  border-r-2 border-primary pr-8">
                      <img
                        src={Profile}
                        alt="profile"
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="pl-2 w-full mr-2">
                        <p className="text-primary font-bold text-[12px]">
                          {rowData.communityAdmin}
                        </p>
                        <p className="text-[10px] mt-0">Admin</p>
                      </div>
                    </div>
                    {sortedMembers?.[0]?.userDetails?.communityLeader && (
                      <div className="flex items-center w-full md:w-1/4">
                        <img
                          src={
                            sortedMembers?.[0]?.pfpImageUrl
                              ? sortedMembers?.[0]?.pfpImageUrl
                              : Profile
                          }
                          alt="profile"
                          className="w-12 h-12 rounded-full"
                        />
                        <div className="mx-2 w-full">
                          <p className="text-primary font-bold text-[12px]">
                            {sortedMembers?.[0]?.userDetails?.user?.firstName}{" "}
                            {sortedMembers?.[0]?.userDetails?.user?.lastName}
                          </p>
                          <p className="border rounded-2xl mt-0.5 text-[8px] text-center bg-[#FFF8ED] text-[#FFA412] px-2">
                            Community Leader
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="flex md:flex-row justify-end items-center space-x-3 flex-col w-full md:w-1/2">
                      <div className="flex ">
                        <button
                          type="button"
                          onClick={() => setInviteModal(true)}
                          className="flex items-center justify-center border h-12 text-primary rounded-full px-6 gap-2"
                          style={{ background: "transparent" }}
                        >
                          <FaUserPlus />
                          Replace Admin
                        </button>
                      </div>

                      <div className="flex space-x-3 items-center">
                        <Link
                          to={`/superadmin/wallet/${communityId}`}
                          state={{ communityData }}
                        >
                          <img
                            src={WalletIcon}
                            alt="wallet icon"
                            className="bg-primary rounded-full w-[50px] h-[50px] p-3"
                            style={{
                              background:
                                "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                            }}
                          />
                        </Link>
                        <button
                          type="button"
                          onClick={() => setOpenNotificationModal(true)}
                          className="rounded-full w-[50px] h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                          style={{
                            background:
                              "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                          }}
                        >
                          <img
                            src={NotificationIcon}
                            alt="notification icon"
                            className="w-full h-full"
                            style={{ borderRadius: "100%" }}
                          />
                        </button>
                        {/* <BsThreeDots
              style={{
                background:
                  "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
              }}
              className="text-white rounded-full p-3 w-[50px] h-[50px]"
            /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-5">
                  <p className=" text-[18px] text-black font-bold">
                    Description
                  </p>
                  <p className="text-xs text-black">
                    {communityData?.community?.communityDescription}
                  </p>
                </div>
                <div className="flex flex-col mt-5">
                  <p className=" text-[18px] text-black font-bold">
                    Withdrawal requests
                  </p>
                  <div className="justify-between items-center flex">
                    <p className="text-[11px]">
                      You have{" "}
                      <span className="text-primary font-bold">
                        {withdrawalRequestsData.length}
                      </span>{" "}
                      withdrawal requests
                    </p>
                  </div>
                  <Table
                    headers={Head}
                    data={data.map((item) => ({
                      ...item,
                      no: `#${item.no}`,
                    }))}
                  />
                </div>
              </div>
              <div className="pl-2 md:w-[35%]">
                <div className="">
                  <p className="text-[16px] text-black font-bold">
                    List of community members
                  </p>
                  <div className="flex text-[10px] justify-between items-center mb-2">
                    <p>
                      <span className="text-primary font-bold ">
                        {communityData?.communityMembersData?.length}{" "}
                      </span>
                      community member(s)
                    </p>
                  </div>
                </div>
                <div className="rounded-xl p-3 bg-white">
                  <div className="">
                    <Search
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search"
                    />
                  </div>
                  {filteredMembers?.map((member: any, index: number) => (
                    <CommunityMembers
                      key={index}
                      image={member.pfpImageUrl}
                      initials={member.userInitials}
                      name={`${member?.userDetails.user.firstName} ${member.userDetails.user.lastName}`}
                      role={
                        member?.userDetails.communityLeader
                          ? "Community Leader"
                          : "Member"
                      }
                      communityMembersList={[]}
                      date={member?.userDetails?.createdAt || "null"}
                      emailAddress={
                        member?.userDetails?.user?.emailAddress || ""
                      }
                      phoneNumber={member?.userDetails?.user?.phoneNumber || ""}
                      communityId={member?.userDetails?.community?.id || "null"}
                      id={
                        member?.userDetails?.user?.id ||
                        member?.userDetails?.id ||
                        "null"
                      }
                    />
                  ))}
                </div>
                <div className="flex justify-center mt-5">
                  <Pagination pageData={pageData} setPageNum={setPageNum} />
                </div>
              </div>
            </div>
            <ModalPanel
              open={inviteModal}
              closeModal={() => setInviteModal(false)}
              closeButton
            >
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <h3 className="text-dark text-xl">Make Admin</h3>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setSendInviteModal(true)
                    setInviteModal(false)
                  }}
                  className="flex text-start items-center border border-primary p-4 bg-[#EEF6FF] mb-4 mt-5"
                >
                  <div className="w-4/5">
                    <p className="text-primary font-bold text-lg">
                      Send out an invite
                    </p>
                    <p className="text-[10px] font-semibold">
                      Lorem ipsum dolor sit amet consectetur. Feugiat nunc sed
                      mauris tortor. Non aenean et sit semper commodo sed ut.
                    </p>
                  </div>
                  <div className="w-1/5 flex  place-content-end">
                    <FiSend className="text-primary w-10 h-10" />
                  </div>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setPickAdminModal(true)
                    setInviteModal(false)
                  }}
                  className="flex text-start items-center border border-primary p-4 bg-[#EEF6FF] mb-4 mt-5"
                >
                  <div className="w-4/5">
                    <p className="text-primary font-bold text-lg">
                      Pick from existing Admins/Leaders
                    </p>
                    <p className="text-[10px] font-semibold">
                      Lorem ipsum dolor sit amet consectetur. Feugiat nunc sed
                      mauris tortor. Non aenean et sit semper commodo sed ut.
                    </p>
                  </div>
                  <div className="w-1/5 flex place-content-end">
                    <RiUserFollowLine className="text-primary w-10 h-10" />
                  </div>
                </button>
              </div>
            </ModalPanel>
            <ModalPanel
              open={sendInviteModal}
              closeModal={() => setSendInviteModal(false)}
              closeButton
            >
              <div className="flex flex-col ">
                <div className="flex justify-between">
                  <h3 className="text-black text-[18px] font-bold">Email</h3>
                </div>
                <div className="mb-6 mr-20">
                  Enter the correct email address
                </div>
                <div className="flex flex-col">
                  <div className="py-3">
                    <Input
                      label="Email Address"
                      name="email"
                      placeholder="Enter email address"
                      className="bg-off-white"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-28">
                  <MainButton>Send Email Invite</MainButton>
                </div>
              </div>
            </ModalPanel>
            <ModalPanel
              open={pickAdminModal}
              closeModal={() => setPickAdminModal(false)}
              modalSize="md:w-[60%] lg:w-[60%] xl:w-[60%]"
            >
              <div className="flex justify-between">
                <p className="text-[16px] text-black font-bold">
                  List of Community Admins/Leaders
                </p>
                <button
                  type="button"
                  onClick={() => setPickAdminModal(false)}
                  className="text-black"
                >
                  <CgClose size={25} />
                </button>
              </div>
              <p className="mb-2 text-[12px]">
                You have{" "}
                <span className="text-primary font-bold">
                  {totalNumberOfAdmins}
                </span>{" "}
                community admins in total
              </p>
              <div className="mb-4">
                <Search />
              </div>
              <AdminList users={usersData} />
              <div className="flex justify-center mt-5">
                <Pagination pageData={pageData1} setPageNum={setPageNum1} />
              </div>
            </ModalPanel>
            <ModalPanel
              closeModal={() => setOpenNotificationModal(false)}
              open={openNotificationModal}
            >
              <div className="">
                <div className="flex justify-between">
                  <p className="text-black text-[20px] font-bold">
                    Send Notification
                  </p>
                  <button
                    type="button"
                    className="text-black"
                    onClick={() => setOpenNotificationModal(false)}
                  >
                    <CgClose size={25} />
                  </button>
                </div>
                <div className="mb-6 mr-20">
                  Enter a notification for all members of this community
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col "
                >
                  <div className="pt-3 pb-1">
                    <Input
                      register={register("subject", {
                        required: "subject is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Cannot be empty",
                        },
                      })}
                      name="subject"
                      label="Subject"
                      placeholder="Enter notification subject"
                      className="bg-off-white"
                      error={errors.subject && errors.subject.message}
                    />
                  </div>
                  <div className="py-3">
                    <Textarea
                      register={register("message", {
                        required: "message is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Cannot be empty",
                        },
                      })}
                      name="message"
                      label="Message"
                      placeholder="Enter message"
                      className="bg-off-white"
                      error={errors.message && errors.message.message}
                    />
                  </div>

                  <div className="flex justify-end mt-4">
                    <MainButton type="submit" disabled={submitting}>
                      {submitting ? (
                        <div className="flex justify-center items-center h-3">
                          <ClipLoader color="#ffffff" />
                        </div>
                      ) : (
                        <>Send</>
                      )}
                    </MainButton>
                  </div>
                </form>
              </div>
            </ModalPanel>
          </>
        )}
      </div>
      <ToastModal
        image={SuccessIcon}
        mainText="Notification sent successfully"
        buttonText="Ok"
        closeModal={() => setOpenToast(false)}
        open={openToast}
      />
      <ToastModal
        image={FailureIcon}
        mainText="Notification failed to send"
        buttonText="Try Again"
        closeModal={() => setOpenFailureToast(false)}
        open={openFailureToast}
      />
    </>
  )
}

export default SuperAdminCommunityDetails
