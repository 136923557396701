import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from "react-icons/io5";

type Props = {
    pageData?: any;
    setPageNum: (num: number) => void;
};

const MemberPagination = ({ pageData, setPageNum }: Props) => {
    const [currentPage, setCurrentPage] = useState(1);

    const goToNextPage = () => {
        if (currentPage < pageData?.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        setPageNum(currentPage);
    }, [currentPage]);

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <div className="mb-5 mt-3 flex items-center">
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={goToPreviousPage}
                    onClick={goToPreviousPage}
                    style={{ color: currentPage > 1 ? '#0161EE' : '#D1D1D1' }} // Change color based on previous page availability
                >
                    <IoArrowBackCircleOutline fontSize="30px" />
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={goToNextPage}
                    onClick={goToNextPage}
                    style={{ color: currentPage < pageData?.totalPages ? '#0161EE' : '#D1D1D1' }} // Change color based on next page availability
                >
                    <IoArrowForwardCircleOutline fontSize="30px" />
                </div>
            </div>
        </div>
    )
};

export default MemberPagination;
