import React, { useState } from "react"
import { FailureIcon, Profile, SuccessIcon, Walletbg } from "../../../../Assets"
import SearchInput from "../../../../Components/elements/Forms/Search"
import GoBack from "../../../../Components/elements/Display/GoBack"
import CommunityWallet from "./CommunityWallet"
import WalletHeader from "../../../../Components/elements/Hero/WalletHeader"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import TransactionsTable from "./TransactionTable"
import { Transaction } from "../../../../types"
import Search from "../../../../Components/elements/Forms/MembersSearch"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import { CgClose } from "react-icons/cg"
import { MainButton } from "../../../../Components/elements/Button"
import Input from "../../../../Components/elements/Forms/Input"
import ResultToast from "../../../../Components/elements/Display/ToastModal"
import FilterComponent from "../../../../Components/elements/Display/FilterTransaction"

const Wallet = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const communityNames = [
    { name: "Funds Community ", amount: "N50,000" },
    { name: "Happy Family", amount: "N20,000" },
    { name: "Max Contributions", amount: "N50,000" },
    { name: "Fun Community", amount: "N10,000" },
    { name: "Fun Community 2", amount: "N20,000" },
  ]
  const transactionsData: Transaction[] = [
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 500,
      status: "Withdrawn",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Rejected",
      amount: 1000,
      status: "Deposited",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Pending",
      amount: 1000,
      status: "Withdrawn",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 1000,
      status: "Deposited",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 1000,
      status: "Deposited",
    },
  ]
  const handleFilterChange = (
    transactionType: string,
    transactionStatus: string,
  ) => {
    console.log("Selected Transaction Type:", transactionType)
    console.log("Selected Transaction Status:", transactionStatus)
  }
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="mt-4 md:w-[62%]">
          <div>
            <GoBack link="/app/communities" />
          </div>
          <WalletHeader
            totalBalance={150000}
            availableBalance={150000}
            withdrawableBalance={63000}
            imageUrl={Walletbg}
          />
          <div className="flex mt-8 justify-between items-center">
            <h1 className="text-black text-[15px] font-bold">
              {" "}
              Transactions History
            </h1>
            <button
              type="button"
              onClick={() => setOpenFilterModal(true)}
              className="flex items-center bg-primary h-8 text-white rounded-md p-2"
            >
              <HiOutlineAdjustmentsHorizontal className="mr-2" />
              Filter transaction
            </button>
          </div>
          <TransactionsTable transactions={transactionsData} />
        </div>
        <div className="md:w-[35%] mt-4">
          <p className="text-[16px] text-black font-bold mt-2">
            Community Wallets
          </p>
          <div className="rounded-xl p-3 bg-white mt-2">
            <div className="">
              <Search />
            </div>
            {communityNames.map((member, index) => (
              <CommunityWallet
                key={index}
                name={member.name}
                amount={member.amount}
              />
            ))}
          </div>
        </div>
      </div>
      <ModalPanel
        closeModal={() => setOpenFilterModal(false)}
        open={openFilterModal}
      >
        <div className="">
          <div className="flex justify-between">
            <p className="text-black text-[18px] font-bold">
              Filter Transactions
            </p>
            <button
              type="button"
              className="text-black"
              onClick={() => setOpenFilterModal(false)}
            >
              <CgClose size={25} />
            </button>
          </div>
        
          {/* <FilterComponent onFilterChange={handleFilterChange} /> */}

        </div>
      </ModalPanel>
    </>
  )
}

export default Wallet
