import React from "react"

type HeroProps = {
  title?: string
  description?: string
  imageUrl?: string
  opacity?: number
  className?: string
}

const Hero: React.FC<HeroProps> = ({
  title,
  description,
  className,
  imageUrl,
  opacity = 0.4,
}) => {
  const sectionStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity})), url('${imageUrl}')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "292px",
    borderRadius: "10px",
  }

  return (
    <section className="mt-[-7px] bg-blend-multiply" style={sectionStyle}>
      <div className="">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
          {title}
        </h1>
        <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
          {description}
        </p>
      </div>
    </section>
  )
}

export default Hero
