import React from "react"
import { WalletHeaderProps } from "../../../types"
import { RxTarget } from "react-icons/rx"
import { FaRegClock } from "react-icons/fa"

const WalletHeader: React.FC<WalletHeaderProps> = ({
  totalBalance,
  availableBalance,
  withdrawableBalance,
  totalEscrowBalance,
  targetAmount,
  escrowDuration,
  imageUrl,
  updatedAt,
}) => {
  function formatNumber(number?: number): string {
    return number !== undefined ? number.toLocaleString() : ""
  }

  function formatAMPM(date: Date): string {
    const hours = date.getHours() % 12 || 12 // Set hours to 12 if it is 0
    const minutes = date.getMinutes().toString().padStart(2, "0") // Ensure minutes are always two digits

    const ampm = date.getHours() >= 12 ? "pm" : "am"
    const strTime = `${hours}:${minutes}${ampm}`

    return strTime
  }

  function formatLastUpdated(updatedAt: string): string {
    const date = new Date(updatedAt)
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${formatAMPM(date)}`
    return formattedDate
  }

  return (
    <div className="relative mt-5">
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "262px",
          width: "100%",
          borderRadius: "20px",
        }}
      />
      <div className="relative z-10 p-6 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div className=" flex text-white text-sm item-center justify-center ">
            {totalEscrowBalance
              ? "Total Escrow Balance"
              : "Total Wallet Balance"}
          </div>
          <div className="text-white text-4xl font-bold my-4">
            {totalEscrowBalance
              ? `₦${formatNumber(totalEscrowBalance)}`
              : `₦${formatNumber(totalBalance)}`}
          </div>
        </div>
        {(( availableBalance) ||
          (targetAmount && escrowDuration)) && (
          <div className="flex">
            <div className="flex flex-col text-white mb-4 pr-4">
              <p className="flex items-center">
                <span className="pr-1">
                  <RxTarget />
                </span>
                {targetAmount ? "Target Amount" : "Available Wallet"}
              </p>
              <div className="text-white text-2xl flex justify-center font-bold mb-4">
                {targetAmount ? (
                  <>
                    <span>{`₦${formatNumber(targetAmount)}`}</span>
                    {/* <span className="text-[12px] font-normal">
                      &nbsp;(to Target)
                    </span> */}
                  </>
                ) : (
                  <span>{`N${formatNumber(availableBalance)}`}</span>
                )}
              </div>
            </div>
            <div className="border-r border-white h-14" />
            <div className="flex flex-col text-white mb-4 pl-4">
              <p className="flex items-center">
                <span className="pr-1">
                  <FaRegClock />
                </span>
                {escrowDuration ? "Escrow Duration" : "Withdrawable Balance"}
              </p>
              <div className="text-white text-2xl flex justify-center font-bold mb-4">
                {escrowDuration ? (
                  <>
                    <span>{`${escrowDuration} days`}</span>
                    <span className="text-[12px] font-normal">
                      {/* &nbsp;(remaining) */}
                    </span>
                  </>
                ) : (
                  <span>{`₦${formatNumber(withdrawableBalance)}`}</span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="text-white text-[13px] font-light">
          Last Updated {updatedAt ? formatLastUpdated(updatedAt) : "now"}
        </div>
      </div>
    </div>
  )
}

export default WalletHeader
