import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Profile} from "../../../Assets"
import GoBack from "../../../Components/elements/Display/GoBack"
import Table from "../../app/CommunityAdmin/EscrowGroups/EscrowTable"
import Search from "../../../Components/elements/Forms/MembersSearch"
import {
  GetAllLeadersApi,
  GetCommunityWithdrawalRequestsApi,
} from "../../../Services"
import { WithdrawalRequestProps } from "../../../types"
import CommunityLeaders from "./CommunityLeaders"

const InterCommunityWithdrawaRequestList = () => {
  const location = useLocation()
  const [withdrawalRequestsData, setWithdrawalRequestsData] = useState<
    WithdrawalRequestProps[]
  >([])
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options,
    )
    return formattedDate
  }

  const { rowData } = location.state || {}
  console.log(rowData)
  const [leaders, setLeaders] = useState<any[]>([])

  const Head = [
    { key: "no", label: "No." },
    { key: "memberProfile", label: "Member Name" },
    { key: "withdrawalAmount", label: "Withdrawal Amount" },
  ]

  const fetchInterEscrowMembers = async () => {
    try {
      const res = await GetAllLeadersApi(1)
      const res2 = await GetCommunityWithdrawalRequestsApi(rowData.id, 1)
      console.log(res)
      setLeaders(res)
      setWithdrawalRequestsData(res2)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchInterEscrowMembers()
  }, [])

  const data = withdrawalRequestsData.map((request, index) => ({
    no: index + 1,
    memberProfile: {
      memberName: request.accountDetails.accountName,
      image: Profile,
    },
    withdrawalAmount: request.amount,
  }))

  return (
    <div className="flex flex-col mt-4 md:flex-row justify-between">
      <div className="mt-2 md:w-[62%]">
        <GoBack link="/superadmin/interCommunityEscrows" />

        <div className="flex flex-col mt-5">
          <p className=" text-[18px] text-black font-bold">
            Withdrawal requests
          </p>

          <Table
            headers={Head}
            data={data.map((item) => ({
              ...item,
              no: `#${item.no}`,
            }))}
          />
        </div>
      </div>
      <div className="pl-2 md:w-[35%]">
        <div className="">
          <p className="text-[16px] text-black font-bold">
            List of Community Leaders
          </p>
          <div className="flex text-[10px] justify-between items-center mb-2">
            <p>
              <span className="text-primary font-bold ">{leaders.length} </span>
              Community Leader(s)
            </p>
            {/* <button type="button" className="text-primary">
                Select members
              </button> */}
          </div>
        </div>
        <div className="rounded-xl p-3 bg-white">
          <div className="">
            <Search />
          </div>
          {leaders?.map((leader) => (
            <CommunityLeaders
              key={leader.communityLeaderUserId}
              image={leader.pfpImageUrl}
              name={leader.name}
              role={leader.communityName}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default InterCommunityWithdrawaRequestList
