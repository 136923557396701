import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../types"


type TAuthState = {
  user: User | null
}

const initialState: TAuthState = {
  user: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signinUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
  },
})

const { actions, reducer: AuthReducer } = authSlice

export const { signinUser } = actions
export default AuthReducer
