import React, { useEffect, useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { MainButton } from "../../../../Components/elements/Button"
import Textarea from "../../../../Components/elements/Forms/TextArea"
import Input from "../../../../Components/elements/Forms/Input"
import { supportApi, UserProfileApi } from "../../../../Services"
import { toast } from "react-toastify"

type FormData = {
  name: string
  email: string
  subject: string
  description: string
}

const Support = () => {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
    userFullName: data.name,
    userEmailAddress: data.email,
    ticketSubject: data.subject,
    ticketDescription: data.description
    }
    try {
      console.log(payload)
      setLoading(true)
      const res = await supportApi(payload)
      console.log(res)
      toast.success(res.message)
      reset({ subject: "", description: "" }) 
    } catch (error) {
      console.log(error)
      toast.error( "An error occurred") 
    }
    setLoading(false)

  }
  const getUserProfileApi = async () => {
    try {
      const res = await UserProfileApi()
      console.log(res)

      if (res) {
        setValue("name", res.fullName || null)
        setValue("email", res.emailAddress || null)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserProfileApi()
  }, [])

  return (
    <div>
      <div className="p-3 bg-white rounded-lg mr-3 ">
        <h5 className="text-lg font-bold text-black"> Support</h5>
        <p className="text-xs text-black">
          Feel free to lay any enquiries, requests or complains.
        </p>
      </div>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-md mx-auto mt-8"
        >
          <div className="mb-4">
            <Input
              type="text"
              name="name"
              label="Name"
              placeholder="e.g David Mark"
              id="name"
              register={register("name", { required: "Name is required" })}
              className={`mt-1 p-2 border-white rounded-md w-full bg-white ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
            />
             {errors.name && (
              <p className="text-red-500 text-xs mt-1">
                {errors.name.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <Input
              type="email"
              name="email"
              label="Email Address"
              id="email"
              placeholder="Enter email address"
              register={register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
              className={`mt-1 p-2 border-white rounded-md w-full bg-white ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">
                {errors.email.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <Input
              type="text"
              name="subject"
              label="Subject"
              id="subject"
              placeholder="Enter subject"
              register={register("subject", { required: "Subject is required" })}
              className={`mt-1 p-2 border-white rounded-md w-full bg-white ${
                errors.subject ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.subject && (
              <p className="text-red-500 text-xs mt-1">
                {errors.subject.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <Textarea
              label="Description"
              id="description"
              name="description"
              placeholder="Write description"
              register={register("description", {
                required: "Description is required",
              })}
              className={`mt-1 p-2 border-white rounded-md w-full h-[162px] ${
                errors.description ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.description && (
              <p className="text-red-500 text-xs mt-1">
                {errors.description.message}
              </p>
            )}
          </div>

          <MainButton className="my-8" isLoading={loading} type="submit">
            Confirm
          </MainButton>
        </form>
      </div>
    </div>
  )
}

export default Support
