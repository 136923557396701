import React, { useEffect, useState } from "react"
import {
  FailureIcon,
  NoItem,
  NotificationIcon,
  SuccessIcon,
} from "../../../Assets"
import {
  notificationsApi,
  sendNotificationToCommunityApi,
  GetSuperAdminCommunityFullListApi,
} from "../../../Services"
import { BounceLoader, ClipLoader } from "react-spinners"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { CgClose } from "react-icons/cg"
import Input from "../../../Components/elements/Forms/Input"
import { MainButton } from "../../../Components/elements/Button"
import Textarea from "../../../Components/elements/Forms/TextArea"
import ModalPanel from "../../../Components/elements/Modal/Index"
import Select from "react-select"
import ToastModal from "../../../Components/elements/Display/ToastModal"
import { notificationType } from "../../../types"
import { formatDate } from "../../../Utils/Helper"

type Inputs = {
  communityId: { value: string; label: string }[]
  notificationTitle: string
  notificationMessage: string
}

const schema = yup.object().shape({
  communityId: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required("Community value is required"),
        label: yup.string().required("Community label is required"),
      }),
    )
    .min(1, "At least one community is required")
    .required("Community is required"),
  notificationTitle: yup.string().required("Subject is required"),
  notificationMessage: yup.string().required("Message is required"),
})

const Notifications: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      communityId: [],
      notificationTitle: "",
      notificationMessage: "",
    },
  })

  const [notifications, setNotifications] = useState<notificationType[]>([])
  const [loading, setLoading] = useState(false)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [emailOptions, setEmailOptions] = useState<any[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [openFailureToast, setOpenFailureToast] = useState(false)

  const getNotifications = async () => {
    try {
      setLoading(true)
      const res = await notificationsApi()
      const res2 = await GetSuperAdminCommunityFullListApi()
      console.log(res)

      console.log(res2)
      setNotifications(res.notifications)
      setEmailOptions(
        res2.data.map((item: any) => ({
          label: item.communityName,
          value: item.id,
        })),
      )
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      console.log("Form data:", data)
      const payload = {
        communityIds: data.communityId.map((community) => community.value),
        notificationTitle: data.notificationTitle,
        notificationMessage: data.notificationMessage,
      }
      setSubmitting(true)
      console.log("Payload:", payload)
      const res = await sendNotificationToCommunityApi(payload)
      console.log("API response:", res)
      setOpenNotificationModal(false)
      reset()
      setSubmitting(false)
      setOpenToast(true)
      reset()
      setOpenNotificationModal(false)
    } catch (error) {
      console.error("API error:", error)
      reset()
      setOpenNotificationModal(false)
      setOpenFailureToast(true)
      setSubmitting(false)
    }
  }

  return (
    <>
      <div>
        {loading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <BounceLoader color="#3B82F6" />
          </div>
        ) : (
          <>
            <div className="flex p-3 rounded-lg justify-between">
              <div>
                <p className="text-[16px] font-bold text-black">
                  List of recent notifications
                </p>
                <p className="text-[12px] text-dark-gray mt-0">
                  You have{" "}
                  <span className="text-primary font-bold">
                    {notifications.length}
                  </span>{" "}
                  notifications
                </p>
              </div>

              <button
                type="button"
                onClick={() => setOpenNotificationModal(true)}
                className="rounded-full h-[50px] p-3 cursor-pointer border-none focus:outline-none"
                style={{
                  background:
                    "linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) ",
                }}
              >
                <p className="flex text-white items-center">
                  <img
                    src={NotificationIcon}
                    alt="notification icon"
                    className="w-[50px] h-[30px]"
                    style={{ borderRadius: "100%" }}
                  />
                  Send Notifications
                </p>
              </button>
            </div>
            <div className="flex flex-col px-[10%] py-8">
              {Array.isArray(notifications) && notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <div
                  key={index}
                  className={`p-3 mb-1 ${
                    index % 2 === 0 ? "bg-white" : "bg-transparent"
                  } flex items-center rounded-lg shadow-sm`}
                >
                    {/* <img
                      src={notification?.img}
                      alt="Notification Img"
                      className="w-10 h-10 rounded-full mr-4"
                    /> */}
                    <div>
                      <p className="text-[14px] font-bold">
                        {notification?.notificationTitle}
                      </p>
                      <p className="text-[14px]">
                        {notification?.notificationMessage}
                      </p>
                      <p className="text-xs text-dark-gray mt-0">
                        {formatDate(notification?.createdAt)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-dark-gray">
                  <img src={NoItem} alt="" />
                  No notifications available
                </p>
              )}
            </div>
          </>
        )}
      </div>

      <ModalPanel
        closeModal={() => setOpenNotificationModal(false)}
        open={openNotificationModal}
      >
        <div className="">
          <div className="flex justify-between">
            <p className="text-black text-[20px] font-bold">
              Send Notification
            </p>
            <button
              type="button"
              className="text-black"
              onClick={() => setOpenNotificationModal(false)}
            >
              <CgClose size={25} />
            </button>
          </div>
          <div className="mb-6 mr-20">
            Enter a notification for all members of this community
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col ">
            <div className="pt-3 pb-1">
              <Controller
                name="communityId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={emailOptions}
                    placeholder="Enter receiver’s name(s) or communities and select"
                    className="bg-gray text-[14px]"
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: errors.communityId
                          ? "red"
                          : base.borderColor,
                        height: "45px",
                      }),
                    }}
                    onChange={(selected) => field.onChange(selected)}
                    value={field.value}
                  />
                )}
              />
              {errors.communityId && (
                <p className="text-red-500 text-xs mt-2">
                  {errors.communityId.message}
                </p>
              )}
            </div>
            <div className="pt-3 pb-1">
              <Input
                register={register("notificationTitle", {
                  required: "Subject is required",
                })}
                name="notificationTitle"
                error={errors.notificationTitle?.message}
                label="Subject"
                placeholder="Enter notification Subject"
                className="bg-off-white"
              />
            </div>

            <div className="py-3">
              <Textarea
                register={register("notificationMessage", {
                  required: "Message is required",
                })}
                name="notificationMessage"
                error={errors.notificationMessage?.message}
                label="Message"
                placeholder="Enter notification message"
                className="bg-off-white"
              />
            </div>

            <div className="flex justify-end mt-4">
              <MainButton type="submit" disabled={submitting}>
                {submitting ? (
                  <div className="flex justify-center items-center h-3">
                    <ClipLoader color="#ffffff" />
                  </div>
                ) : (
                  <>Send</>
                )}
              </MainButton>
            </div>
          </form>
        </div>
      </ModalPanel>
      <ToastModal
        image={SuccessIcon}
        mainText="Notification sent successfully"
        buttonText="Ok"
        closeModal={() => setOpenToast(false)}
        open={openToast}
      />
      <ToastModal
        image={FailureIcon}
        mainText="Notification failed to send"
        buttonText="Try Again"
        closeModal={() => setOpenFailureToast(false)}
        open={openFailureToast}
      />
    </>
  )
}

export default Notifications
