import React, { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "../../../Components/elements/Forms/Input"
import { MainButton } from "../../../Components/elements/Button"
import { ResetUserPasswordApi } from "../../../Services"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

type FormProps = {
  newPassword: string
  confirmPassword: string
}

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character",
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
})

function NewPassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
    setLoading(true)
      const emailAddress = localStorage.getItem("emailAddress")
      const resetToken = localStorage.getItem("resetPasswordToken")

      console.log("Form Data:", data)
      console.log("Email Address:", emailAddress)
      console.log("Reset Token:", resetToken)
      
      if (!emailAddress || !resetToken) {
        console.error("Email address or OTP not found")
        return
      }

      const res = await ResetUserPasswordApi({
        newPassword: data.newPassword,
        emailAddress,
        resetToken,
      })

      reset()
      localStorage.removeItem("emailAddress")
      localStorage.removeItem("otp")
      toast.success(res.message)
      navigate("/auth/login")
      console.log("Reset Password API Response:", res)

    } catch (error: any) {
      console.error("Error:", error)
      toast.error(error?.response?.data?.error?.message)
    }
    setLoading(false)
  }

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-[60%]">
        <h3 className="text-primary text-2xl">New Password</h3>
        <p className="text-md">Create a new password</p>

        <div className="py-3 relative">
          <Input
            register={register("newPassword")}
            name="newPassword"
            error={errors.newPassword?.message}
            type={showPassword ? "text" : "password"}
            label="New Password"
            placeholder="Enter new password"
          />
           <button
              type="button"
              className={`${
                errors.newPassword ? "bottom-10" : "bottom-5"
              } absolute right-3 transform -translate-y-1/2`}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
              ) : (
                <AiOutlineEye className="text-gray-400 text-1.5xl" />
              )}
            </button>
        </div>
        <div className="py-3 relative">
          <Input
            register={register("confirmPassword")}
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            error={errors.confirmPassword?.message}
            label="Confirm Password"
            placeholder="Confirm Password"
          />
           <button
            type="button"
            className={`${
              errors.confirmPassword ? "bottom-10" : "bottom-5"
            } absolute right-3 transform -translate-y-1/2`}
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? (
              <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
            ) : (
              <AiOutlineEye className="text-gray-400 text-1.5xl" />
            )}
          </button>
        </div>

        <div className="py-5">
          <MainButton type="submit" isLoading={loading} >Update Password</MainButton>
        </div>
      </form>
    </div>
  )
}

export default NewPassword
