import React from "react"
import {
  TotalCommunityMember,
  TotalEscrowGroups,
  TotalIntercommunityGroups,
  TotalAdmins,
  TotalCommunityLeaders,
} from "../Assets"

export enum UserAccountType {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMMUNITY_ADMIN = "COMMUNITY_ADMIN",
  COMMUNITY_MEMBER = "COMMUNITY_MEMBER",
  COMMUNITY_LEADER = "COMMUNITY_LEADER",
}

export type ItemProps = {
  id: number
  title: string
  link: string
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  img?: string
  activeImg?: string
}

export interface CommunityMemberProps {
  image: string
  name: string
  role: string
  date?: string
}

export interface CommunityWalletProps {
  name: string
  amount: string
}

export interface WalletHeaderProps {
  totalBalance?: number | undefined
  availableBalance?: number | undefined
  withdrawableBalance?: number | undefined
  totalEscrowBalance?: number | undefined
  targetAmount?: number | undefined
  escrowDuration?: string | number | undefined
  updatedAt?: string | undefined
  imageUrl: string
  id?: string
  createdAt?: Date
  totalMemberBalance?: number
}

export interface Transaction {
  date?: string
  details?: string
  description:
    | "Approved"
    | "Rejected"
    | "Pending"
    | "SUCCESSFUL"
    | "FAILED"
    | "PENDING"
    | ""
  amount?: number
  status: "Withdrawn" | "Deposited" | "credit" | "debit" | ""
  id?: string
  transactionReference?: string
  transactionType?: "credit" | "debit" | ""
  transactionDestination?: string
  walletType?: string
  fromWalletId?: string
  toWalletId?: string
  transactionAmount?: number
  transactionDetails?: string
  walletBalanceBeforeTransaction?: number
  walletBalanceAfterTransaction?: number
  transactionStatus?: "SUCCESSFUL" | "FAILED" | "PENDING" | ""
  createdAt?: string
  updatedAt?: Date
}

export interface TransactionsTableProps {
  transactions: Transaction[]
  // setTransPageNum?: React.Dispatch<React.SetStateAction<number>>
  setTransPageNum?: any
  transPageData?: any
  emptyTrans?: boolean
}

export interface DashboardData {
  communityMembers: number
  numberOfIntraEscrow: number
  numberOfInterEscrow: number
  communityAdmins: number
  communityLeaders: number
}

export interface TotalInfoProps {
  img: string
  totalDetails: string
  dataKey: keyof DashboardData // New property to map with the dashboardData
}

export const totalInfoProps: TotalInfoProps[] = [
  {
    img: TotalCommunityMember,
    totalDetails: "Total Community Member",
    dataKey: "communityMembers",
  },
  {
    img: TotalEscrowGroups,
    totalDetails: "Total Escrow Groups",
    dataKey: "numberOfIntraEscrow",
  },
  {
    img: TotalIntercommunityGroups,
    totalDetails: "Total Inter-community Groups",
    dataKey: "numberOfInterEscrow",
  },
  {
    img: TotalAdmins,
    totalDetails: "Total Admins",
    dataKey: "communityAdmins",
  },
  {
    img: TotalCommunityLeaders,
    totalDetails: "Total Community Leaders",
    dataKey: "communityLeaders",
  },
]
export interface User {
  id: string
  accountType: string
  username: string | null
  fullName: string | null
  firstName: string | null
  lastName: string | null
  emailAddress: string
  password: string
  dateOfBirth: string | null
  phoneNumber: string | null
  gender: string | null
  otp: string | null
  otpExpiry: string | null
  profilePictureFileKey: string | null
  isActive: boolean
  isBlocked: boolean
  isSuspended: boolean
  createdAt: string
  updatedAt: string
}

export interface MemberData {
  id:               string;
  communityLeader:  boolean;
  isMember:         boolean;
  registered:       boolean;
  loanInterestRate: number;
  createdAt:        Date;
  updatedAt:        Date;
  user:             User;
  community:        Community;
}
export interface SearchComMember {
  memberName:      string;
  memberImageUrl:  string;
  memberData:      MemberData;
  communityLeader: boolean;
}

export interface SearchEscrowMember {
  id: string;
  name: string;
  imageUrl: string;
  accountType: string;
  email: string;
  escrowCreator: boolean;
  phoneNumber: string;
}
export interface Member {
  id: string
  communityLeader: boolean
  isMember: boolean
  registered: boolean
  createdAt: string
  updatedAt: string
  memberData?: MemberData
  user: User
  memberImageUrl: string
  loanInterestRate: number | null
  community: Community
}


export interface CommunityAdmin {
  id: string
  numberOfCreatedCommunities: number
  createdAt: string
  updatedAt: string
  registered: boolean
  user: User
  totalNumberOfAdmins: number
  communityDescription: string
  communityImageFileKey: string
  communityName: string
  communityImageUrl: string
  communityImageURL: string
}

export interface Community {
  id: string
  communityDescription: string
  communityImageFileKey: string
  communityName: string
  createdAt: string
  updatedAt: string
  communityAdmin: CommunityAdmin
  members: Member[]
  communityImageUrl: string
  communityImageURL: string
  numberOfCommunityMembers: {
    totalNumber: number
    numberToDisplay: number
  }
  firstFourMembersImages: {
    image1?: string
    image2?: string
    image3?: string
    image4?: string
  }
}
export interface CommunityWallet {
  availableBalance: number
  createdAt: string
  id: string
  totalBalance: number
  totalMemberBalance: number
  updatedAt: string | undefined
}
export interface MemberWallet {
  id: string
  balance: number
  virtualAccountNumber: string
  virtualAccountNumberBank: string
  virtualAccount: {
    data: {
      note: string
      amount: string
      flw_ref: string
      bank_name: string
      frequency: number
      order_ref: string
      created_at: number
      expiry_date: number
      response_code: string
      account_number: string
      account_status: string
      response_message: string
    }
    status: string
    message: string
  }
  createdAt: string
  updatedAt: string
}

export type CommunityLeader = {
  id: string
  communityLeader: boolean
  isMember: boolean
  registered: boolean
  loanInterestRate: number | null
  createdAt: string
  updatedAt: string
  user: User
  community: Community
}

export interface CommunityData {
  communityName: string
  totalNumberOfMembers: number
  communityAdmin: {
    id: string
    numberOfCreatedCommunities: number
    registered: boolean
    createdAt: string
    updatedAt: string
    user: {
      id: string
      accountType: string
      username: string | null
      fullName: string
      emailAddress: string
      password: string
      dateOfBirth: string | null
      phoneNumber: string | null
      gender: string | null
      otp: string | null
      otpExpiry: string | null
      profilePictureFileKey: string | null
      isActive: boolean
      isBlocked: boolean
      isSuspended: boolean
      createdAt: string
      updatedAt: string
    }
  }
  communityLeader: CommunityLeader | null
  imagesOfFirstFourMembers: string[]
  id: string
  communityDescription: string
  communityImageFileKey: string
  createdAt: string
  updatedAt: string
  members: Member[]
}

export interface Escrow {
  id: string
  escrowGroupName: string
  escrowGroupDescription: string
  escrowDuration: string
  escrowExpirationDate: string
  escrowType: string
  escrowStatus: string
  escrowTargetAmount: number
  createdAt: string
  updatedAt: string
  escrowGroupMembers: []
  imageOfFirstThreeMembers: []
  numberOfMembers: number
}

export interface EscrowGroup {
  createdEscrowGroups: Escrow[]
  joinedEscrowGroups: []
}

export interface Image {
  pfpImageUrl: string
}

export interface EscrowMemberData {
  totalNumberOfMembers: number
  images: Image[]
}

export interface EscrowGroupData {
  escrowId: string
  escrowName: string
  escrowMemberData: EscrowMemberData
  escrowAdmin: string
}
export interface EscrowGroupDetails {
  id: string
  escrowGroupName: string
  escrowGroupDescription: string
  escrowDuration: string
  escrowExpirationDate: string
  escrowType: string
  escrowStatus: string
  escrowTargetAmount: number
  createdAt: string
  updatedAt: string
  imageOfFirstThreeMembers: Image[]
  numberOfMembers: number
}

export interface WithdrawalRequestProps {
  id: string
  withdrawalRequestReason: string
  accountDetails: AccountDetails
  amount: number
  withdrawalRequestStatus: string
  withdrawalRequestType: string
  createdAt: Date
  updatedAt: Date
  communityMember: CommunityMember
}

export interface AccountDetails {
  bank: string
  accountName: string
  accountNumber: string
}

export interface CommunityMember {
  id: string
  communityLeader: boolean
  isMember: boolean
  registered: boolean
  loanInterestRate: number
  createdAt: Date
  updatedAt: Date
  user: User
  community: Community
}

export interface notificationType {
  id: string
  userId: string
  notificationTitle: string
  notificationType: string
  notificationMessage: string
  destination: string
  notificationMetaData: NotificationMetaData
  createdAt: string
  updatedAt: Date
}

export interface NotificationMetaData {}

// export interface VirtualAccount {
//   id: string
//   balance: number
//   virtualAccountNumber: string
//   virtualAccountNumberBank: string
//   virtualAccount: VirtualAccountClass
//   createdAt: Date
//   updatedAt: Date
// }

// export interface VirtualAccountClass {
//   data: Data
//   status: string
//   message: string
// }

// export interface Data {
//   note: string
//   amount: string
//   flw_ref: string
//   bank_name: string
//   frequency: number
//   order_ref: string
//   created_at: number
//   expiry_date: number
//   response_code: string
//   account_number: string
//   account_status: string
//   response_message: string
// }
