  import React, { Component, useState } from "react"
  import AuthOne from "./AuthOne"
  import AuthTwo from "./AuthTwo"

  function Auth() {
    const [step, setStep] = useState(0)

    let componentToDisplay

    switch (step) {
      case 1:
        componentToDisplay = <AuthOne />
        break
      default:
        componentToDisplay = <AuthTwo handleNext={() => setStep(1)} />
    }

    return componentToDisplay
  }

  export default Auth
