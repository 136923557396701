import React, { useState } from "react";
import { useDispatch } from "react-redux"; // Import dispatch from react-redux
import { useNavigate } from "react-router-dom";
import { UserSignInApi } from "../../Services";
import { signinUser } from "../../Redux/slices/auth.slice";
import store from "store";
import { UserAccountType } from "../../types";
import { toast } from "react-toastify";
import Input from "../../Components/elements/Forms/Input";
import CheckBox from "../../Components/elements/Forms/CheckBox";
import { MainButton } from "../../Components/elements/Button";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Login = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await UserSignInApi(formData);
      dispatch(signinUser(res.user)); // Dispatch action to update Redux store

      store.set("isLoggedIn", true);
      store.set("atk", res.accessToken);

      let routeTo = "/app/communities";

      if (res.user.accountType === UserAccountType.SUPER_ADMIN) {
        routeTo = "/superadmin/dashboard";
      } else if (
        res.user.accountType === UserAccountType.COMMUNITY_ADMIN &&
        res.registered === true
      ) {
        routeTo = "/app/communities";
      } else if (
        res.user.accountType === UserAccountType.COMMUNITY_ADMIN &&
        res.registered === false
      ) {
        routeTo = "/register";
      }

      navigate(routeTo, { state: { password: formData.password } });
      toast.success(res.message);
    } catch (error: any) {
      console.error(error);
      toast.error(
        error.response?.data.message || "An error occurred"
        || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <form className="w-full md:w-[60%] content-center">
        <h3 className="text-primary text-2xl">Welcome</h3>
        <p className="text-md">Enter login details provided to get started</p>
        <div className="pt-10 pb-3">
          <Input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            label="Email"
            placeholder="Enter Email"
            className="bg-off-white"
          />
        </div>
        <div className="py-3 relative">
          <Input
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            label="Password"
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            className="bg-off-white"
          />
          <button
            type="button"
            className="absolute bottom-5 right-3 transform -translate-y-1/2"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <AiOutlineEyeInvisible className="text-gray-400 text-1.5xl" />
            ) : (
              <AiOutlineEye className="text-gray-400 text-1.5xl" />
            )}
          </button>
        </div>
        <div className="justify-between flex py-4">
          <CheckBox name="remember" title="Remember me" />
          <button
            type="button"
            className="text-primary"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot password ?
          </button>
        </div>
      </form>
      <MainButton type="submit" isLoading={loading} onClick={onSubmit}
      className="w-full md:w-[60%] mt-4"
      >
        Login
      </MainButton>
    </div>
  );
};

export default Login;
