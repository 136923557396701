import React from "react"
import { Link } from "react-router-dom"

interface CardProps {
  imageUrl: string
  title?: string | React.ReactElement
  description: string
  avatarUrls?: string[]
  count: number
  to?: string
  onClick?: () => void
  date?: string
  members?: number
}

const Card: React.FC<CardProps> = ({
  imageUrl,
  members,
  title,
  description,
  avatarUrls,
  count,
  to,
  onClick,
  date,
}) => (
  <Link to={to || "#"}>
    <div
      className="relative overflow-hidden rounded-lg h-80"
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={imageUrl}
          alt="Community"
        />
        <div className="absolute inset-0 bg-black opacity-40" />
      </div>
      <div className="flex flex-col justify-end h-full relative p-4">
        <h3 className="text-xl font-semibold text-white capitalize">{title}</h3>
        <p className="text-white text-xs"> Created: {date}</p>
        <div className="flex items-center mt-2">
          <div className="flex -space-x-2">
            {avatarUrls?.map((avatarUrl, index) => (
              <img
                key={index}
                className="w-6 h-6 rounded-full ring-2 ring-white mt-3 mb-0"
                src={avatarUrl}
                alt={`Avatar ${index + 1}`}
              />
            ))}
            <span className="flex items-center bg-blue-600 rounded-full text-[7px] text-white w-6 h-6 mt-3 mb-0 pl-1">
              +{count}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Link>
)

export default Card
