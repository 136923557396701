import React, { useEffect, useState } from "react"
import Table from "../../../Components/elements/Table"
import {
  AddPersonIcon,
  FailureIcon,
  OnePersonIcon,
  Profile,
  SuccessIcon,
} from "../../../Assets"
import {
  MainButton,
  OutlineButton,
  RejectButton,
} from "../../../Components/elements/Button"
import Input from "../../../Components/elements/Forms/Input"
import ModalPanel from "../../../Components/elements/Modal/Index"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  InviteNewAdminApi,
  GetSuperAdminCommunityListApi,
  GetCommunityAdminListApi,
  getAdminPermissionsApi,
  addAdminPermissionApi,
  removeAdminPermissionApi,
  suspendCommunityAdminApi,
} from "../../../Services"
import { CgClose } from "react-icons/cg"
import Search from "../../../Components/elements/Forms/MembersSearch"
import CustomSwitch from "../../../Components/elements/Forms/Switch"
import { CommunityAdmin, CommunityData } from "../../../types"
import { BarLoader, ClipLoader } from "react-spinners"
import Pagination from "../../../Components/elements/Pagination"
import ResultToast from "../../../Components/elements/Display/ToastModal"
import { toast } from "react-toastify"
import { RxAvatar } from "react-icons/rx"
import { set } from "store"

const communityAdmins: CommunityAdmin[] = []

interface barSwitchProps {
  create_community: boolean
  manage_community: boolean
  fund_community_wallet: boolean
  create_escrow_groups: boolean
  approve_withdrawal_requests: boolean
  assign_community_leader: boolean
  invite_community_members: boolean
  withdraw_funds: boolean
  remove_community_leader: boolean
  manage_community_members: boolean
  [key: string]: boolean
}

const barSwitchData = [
  { label: "Create Communities", name: "create_community" },
  { label: "Manage Communities", name: "manage_community" },
  { label: "Fund Community Wallet", name: "fund_community_wallet" },
  { label: "Create Escrow Groups", name: "create_escrow_groups" },
  { label: "Approve Withdrawal Requests", name: "approve_withdrawal_requests" },
  { label: "Invite Community Members", name: "invite_community_members" },
  { label: "Withdraw Funds", name: "withdraw_funds" },
  { label: "Assign Community Leader", name: "assign_community_leader" },
  { label: "Remove Community Leader", name: "remove_community_leader" },
  { label: "Manage Community Members", name: "manage_community_members" },
]

const Head = [
  { key: "no", label: "No." },
  { key: "communityName", label: "Community name" },
  { key: "members", label: "No. of member" },
  { key: "communityAdmin", label: "Community Admin" },
  { key: "communityLeader", label: "Community Leader" },
]

type Inputs = {
  fullName: string
  emailAddress: string
}

const schema = yup.object().shape({
  fullName: yup.string().required("Name is required"),
  emailAddress: yup.string().required("Email is required"),
})

const SuperAdminCommunities: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  })

  const [addCommunityAdminModal, setAddCommunityAdminModal] = useState(false)
  const [allCommunityAdminsModal, setAllCommunityAdminsModal] = useState(false)
  const [viewCommunityAdminModal, setViewCommunityAdminModal] = useState(false)
  const [suspendCommunityAdminModal, setSuspendCommunityAdminModal] =
    useState(false)
  const [superAdminCommunityData, setSuperAdminCommunityData] =
    useState<any>(null)
  const [communityAdmins, setCommunityAdmins] = useState<CommunityAdmin[]>([])
  const [barSwitch, setBarSwitch] = useState<barSwitchProps>({
    manage_community: false,
    assign_community_leader: false,
    remove_community_leader: false,
    manage_community_members: false,
    create_community: false,
    fund_community_wallet: false,
    create_escrow_groups: false,
    approve_withdrawal_requests: false,
    invite_community_members: false,
    withdraw_funds: false,
  })
  const [selectedAdmin, setSelectedAdmin] = useState<CommunityAdmin | null>(
    null,
  )
  const [pageData, setPageData] = useState<any>()
  const [pageData1, setPageData1] = useState<any>()
  const [pageNum, setPageNum] = useState(1)
  const [pageNum1, setPageNum1] = useState(1)
  const [loading, setLoading] = useState(true)
  const [totalNumberOfAdmins, setTotalNumberOfAdmins] = useState(0)
  const [openToast, setOpenToast] = useState(false)
  const [openFailureToast, setOpenFailureToast] = useState(false)

  const viewCommunityAdmin = (admin: CommunityAdmin) => {
    setSelectedAdmin(admin)
    setViewCommunityAdminModal(true)
  }
  const [submitting, setSubmitting] = useState(false)
  const handleSwitchChange = async (name: keyof barSwitchProps) => {
    if (selectedAdmin) {
      const adminUserId = selectedAdmin.user.id
      let payload

      try {
        if (barSwitch[name]) {
          payload = { removedPermissions: [name] }
          await removeAdminPermissionApi(adminUserId, payload)
        } else {
          payload = { addedPermissions: [name] }
          await addAdminPermissionApi(adminUserId, payload)
        }

        setBarSwitch((prevStates) => ({
          ...prevStates,
          [name]: !prevStates[name],
        }))
        toast.success("Permissions updated successfully")
      } catch (error) {
        console.error("Error updating permissions:", error)
        toast.error("Failed to update permissions")
      }
    }
  }

  const [superAdminLoading, setSuperAdminLoading] = useState(true)
  const [communityAdminLoading, setCommunityAdminLoading] = useState(true)

  useEffect(() => {
    const fetchSuperAdminData = async () => {
      try {
        const superAdminData = await GetSuperAdminCommunityListApi(pageNum1)
        setSuperAdminCommunityData(superAdminData)
        setPageData1(superAdminData.metadata)
      } catch (error) {
        console.error("Error fetching super admin data:", error)
      } finally {
        setSuperAdminLoading(false)
      }
    }
    fetchSuperAdminData()
  }, [pageNum1])

  useEffect(() => {
    const fetchCommunityAdminData = async () => {
      try {
        const communityAdminData = await GetCommunityAdminListApi(pageNum)
        setCommunityAdmins(communityAdminData.data)
        setTotalNumberOfAdmins(communityAdminData.totalNumberOfAdmins)
        setPageData(communityAdminData.metadata)
      } catch (error) {
        console.error("Error fetching community admin data:", error)
      } finally {
        setCommunityAdminLoading(false)
      }
    }
    fetchCommunityAdminData()
  }, [pageNum])

  useEffect(() => {
    if (!superAdminLoading && !communityAdminLoading) {
      setLoading(false)
    }
  }, [superAdminLoading, communityAdminLoading])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { emailAddress } = data
    if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      toast.error("Invalid email format")
      return
    }
    try {
      setSubmitting(true)
      const users = [
        {
          fullName: data.fullName,
          emailAddress: data.emailAddress,
        },
      ]

      console.log(users)

      const res = await InviteNewAdminApi({ users })
      console.log(res)
      setOpenToast(true)
    } catch (error: any) {
      console.log(error)
      setOpenFailureToast(true)
      toast.error(error?.response?.data?.message || "An error occurred")
    } finally {
      reset()
      setSubmitting(false)
    }
  }
  useEffect(() => {
    const fetchAdminPermissions = async () => {
      if (selectedAdmin) {
        try {
          const permissionsRes = await getAdminPermissionsApi(
            selectedAdmin.user.id,
          )
          console.log(selectedAdmin.user.id)

          console.log(permissionsRes.permissions)

          const newBarSwitch: barSwitchProps = {
            manage_community: false,
            assign_community_leader: false,
            remove_community_leader: false,
            manage_community_members: false,
            create_community: false,
            fund_community_wallet: false,
            create_escrow_groups: false,
            approve_withdrawal_requests: false,
            invite_community_members: false,
            withdraw_funds: false,
          }

          permissionsRes.permissions.forEach((permission: string) => {
            if (newBarSwitch.hasOwnProperty(permission)) {
              newBarSwitch[permission] = true
            }
          })

          setBarSwitch(newBarSwitch)
        } catch (error) {
          console.error("Error fetching permissions:", error)
        }
      }
    }
    fetchAdminPermissions()
  }, [selectedAdmin])

  const dataLength = superAdminCommunityData?.numberOfCommunities || 0
  const profilePictureSrc = selectedAdmin?.user.profilePictureFileKey

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <BarLoader color="#3B82F6" />
        </div>
      ) : (
        <>
          <div className="mt-6">
            <div className="flex flex-grow justify-between items-center">
              <div className="">
                <p className="font-bold text-black text-[18px]">
                  List of Communities
                </p>
                <p className="text-[12px] mt-0">
                  You have
                  <span className="text-primary font-bold">
                    {" "}
                    {dataLength}{" "}
                  </span>{" "}
                  communities
                </p>
              </div>

              <div className=" flex w-1/2">
                <MainButton
                  className="flex justify-center items-center gap-2 mr-2"
                  onClick={() => setAddCommunityAdminModal(true)}
                >
                  <img src={AddPersonIcon} alt="addAdmin" className="" />
                  Add Community Admin
                </MainButton>
                <OutlineButton
                  onClick={() => setAllCommunityAdminsModal(true)}
                  className="flex justify-center items-center gap-2"
                  style={{
                    color: `linear-gradient(106deg, #0078FF 23.74%, #0045D8 81.57%) `,
                  }}
                >
                  <img src={OnePersonIcon} alt="profile" className="" /> All
                  Community Admins
                </OutlineButton>
              </div>
            </div>

            <Table
              headers={Head}
              data={
                superAdminCommunityData?.data
                  ? superAdminCommunityData?.data.map(
                      (community: CommunityData, index: number) => ({
                        id: community.id,
                        no: index + 1,
                        communityName: community.communityName,
                        members: community?.members.length || 0,
                        communityAdmin:
                          community?.communityAdmin?.user?.fullName ||
                          "No admin",
                        communityLeader:
                          community?.communityLeader?.user?.fullName ||
                          community?.communityLeader?.user?.firstName ||
                          "No community leader",
                      }),
                    )
                  : []
              }
            />
          </div>
          <ModalPanel
            open={addCommunityAdminModal}
            closeModal={() => setAddCommunityAdminModal(false)}
            closeButton
            title="Add Community Admin"
          >
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col ">
              <div className="flex flex-col">
                <div className="py-3">
                  <Input
                    register={register("fullName", {
                      required: "Name is required",
                    })}
                    name="fullName"
                    error={errors.fullName?.message}
                    label="Full Name"
                    placeholder="Enter name"
                    className="bg-off-white"
                  />
                </div>
                <div className="py-3">
                  <Input
                    register={register("emailAddress", {
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email format",
                      },
                    })}
                    name="emailAddress"
                    error={errors.emailAddress && errors.emailAddress.message}
                    label="Email Address"
                    placeholder="Enter email"
                    className="bg-off-white"
                  />
                </div>
              </div>
              <div className="flex justify-end mt-28">
                {/* <MainButton type="submit"></MainButton> */}
                <MainButton type="submit" disabled={submitting}>
                  {submitting ? (
                    <div className="flex justify-center items-center h-3">
                      <ClipLoader color="#ffffff" />
                    </div>
                  ) : (
                    <>Send Invite</>
                  )}
                </MainButton>
              </div>
            </form>
          </ModalPanel>
          <ModalPanel
            open={allCommunityAdminsModal}
            closeModal={() => setAllCommunityAdminsModal(false)}
            modalSize="md:w-[60%] lg:w-[60%] xl:w-[60%]"
          >
            <div className="mx-1">
              <div className="flex justify-between ">
                <div>
                  <p className="text-black text-[18px] font-bold mb-0">
                    Community Admins
                  </p>
                  <p className="text-[12px] mt-0">
                    <span className="text-primary font-bold">
                      {totalNumberOfAdmins}
                    </span>{" "}
                    Admins
                  </p>
                </div>
                <button
                  type="button"
                  className="text-black"
                  onClick={() => setAllCommunityAdminsModal(false)}
                >
                  <CgClose size={25} />
                </button>
              </div>

              <div className="mt-3">
                <Search />
              </div>

              {communityAdmins?.map((admin, index) => (
                <div
                  key={admin.id}
                  className="flex justify-between items-center mt-2"
                >
                  <div className="flex items-center">
                    <div>
                      {profilePictureSrc ? (
                        <img
                          src={profilePictureSrc}
                          alt="profile"
                          className="w-12 h-12 rounded-full mt-3 mb-0"
                        />
                      ) : (
                        <RxAvatar className="w-8 h-8" />
                      )}
                    </div>
                    <div className="mx-2">
                      <p className="text-black text-[12px] mt-0 font-bold">
                        {admin?.user.fullName}
                      </p>
                      <p className="rounded-2xl text-[10px] mt-0 text-[#8E8E8E] ">
                        {admin.numberOfCreatedCommunities === 0 ? (
                          <p>No community created</p>
                        ) : admin.numberOfCreatedCommunities === 1 ? (
                          <p>1 Community</p>
                        ) : (
                          <p>{admin.numberOfCreatedCommunities} Communities</p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-black">{admin?.user.emailAddress}</p>
                  </div>
                  <div>
                    <p className="text-black">
                      {admin?.user.phoneNumber || "No phone number"}
                    </p>
                  </div>
                  <div className="">
                    <MainButton
                      className="px-6"
                      onClick={() => viewCommunityAdmin(admin)}
                    >
                      View
                    </MainButton>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-5">
              <Pagination pageData={pageData} setPageNum={setPageNum} />
            </div>
          </ModalPanel>
          <ModalPanel
            open={viewCommunityAdminModal}
            closeModal={() => setViewCommunityAdminModal(false)}
          >
            {selectedAdmin && (
              <div className="mx-2">
                <div className="flex justify-between mb-2">
                  <p className="text-black text-[18px] font-bold">
                    Community Admin
                  </p>
                  <button
                    type="button"
                    className="text-black"
                    onClick={() => {
                      setViewCommunityAdminModal(false)
                      setAllCommunityAdminsModal(true)
                      setSelectedAdmin(null)
                    }}
                  >
                    <CgClose size={25} />
                  </button>
                </div>

                <div className="flex items-center">
                  <img
                    src={selectedAdmin.user.profilePictureFileKey || Profile}
                    alt="profile"
                    className="w-20 h-20 rounded-full ring-2 ring-primary mt-3 mb-0"
                  />
                  <div className="mx-2">
                    <p className="text-primary text-[16px] font-bold mb-1">
                      {selectedAdmin.user.fullName}
                    </p>
                    <p className="rounded-2xl text-[12px] mt-0 text-primary border border-primary bg-[#EEF6FF] px-2">
                      {selectedAdmin.numberOfCreatedCommunities === 0 ? (
                        <p>No community created</p>
                      ) : selectedAdmin.numberOfCreatedCommunities === 1 ? (
                        <p>1 Community</p>
                      ) : (
                        <p>
                          {selectedAdmin.numberOfCreatedCommunities} Communities
                        </p>
                      )}
                    </p>
                  </div>
                </div>

                <div className="my-4">
                  {barSwitchData.map((barItem, index) => (
                    <CustomSwitch
                      key={index}
                      checked={barSwitch[barItem.name]}
                      onChange={() =>
                        handleSwitchChange(barItem.name as keyof barSwitchProps)
                      }
                      label={barItem.label}
                    />
                  ))}
                </div>
                <div className="flex flex-col space-y-4 justify-end mt-2 mb-6">
                  <MainButton
                     onClick={() => {
                      setAllCommunityAdminsModal(false)
                      setSuspendCommunityAdminModal(true)
                    }}
                  >
                    Suspend Community Admin
                  </MainButton>
                  <RejectButton>Remove Community Admin</RejectButton>
                </div>
              </div>
            )}
          </ModalPanel>

          <div className="flex justify-center mt-5">
            <Pagination pageData={pageData1} setPageNum={setPageNum1} />
          </div>

          <ModalPanel
            open={suspendCommunityAdminModal}
            closeModal={() => setSuspendCommunityAdminModal(false)}
          >
            <div className="flex justify-between mb-2">
              <p className="text-black text-[18px] font-bold">
                Community Admin
              </p>
              <button
                type="button"
                className="text-black"
                onClick={() => {
                  setViewCommunityAdminModal(false)
                  setAllCommunityAdminsModal(true)
                  setSelectedAdmin(null)
                  setSuspendCommunityAdminModal(false)
                }}
              >
                <CgClose size={25} />
              </button>
            </div>
          </ModalPanel>
        </>
      )}

      <ResultToast
        image={SuccessIcon}
        mainText="User invited  Successfully"
        buttonText="Continue"
        closeModal={() => setOpenToast(false)}
        open={openToast}
      />
      <ResultToast
        image={FailureIcon}
        mainText="User invitation Failed"
        buttonText="Try Again"
        closeModal={() => setOpenFailureToast(false)}
        open={openFailureToast}
      />
    </div>
  )
}

export default SuperAdminCommunities
