import React, { useEffect, useState } from "react";
import PaginateButton from "./PaginateButton";
import { BiSolidRightArrow, BiSolidLeftArrow } from "react-icons/bi";

type Props = {
    pageData?: any;
    setPageNum: (num: number) => void;
};

const Pagination = ({ pageData, setPageNum }: Props) => {
    const [currentPage, setCurrentPage] = useState(1);

    const goToNextPage = () => {
        if (currentPage < pageData?.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        setPageNum(currentPage);
    }, [currentPage]);

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPaginationButtons = () => {
        const visiblePages = Math.min(pageData?.totalPages, 3);
        const middlePage = Math.floor(visiblePages / 2) + 1;
        const startPage = Math.max(1, currentPage - middlePage + 1);
        const endPage = Math.min(startPage + visiblePages - 1, pageData?.totalPages);

        const paginationButtons = [];

        for (let num = startPage; num <= endPage; num++) {
            paginationButtons.push(
                <PaginateButton
                    key={num}
                    number={num}
                    isActive={num === currentPage}
                    isGradient={false}
                    setPageToActive={() => setCurrentPage(num)}
                />
            );
        }

        return paginationButtons;
    };

    return (
        <div>
            {/* {pageData?.totalPages?.length > 0 && ( */}
            <div className="my-5 flex items-center">
                <div role="button" tabIndex={0} onKeyDown={goToPreviousPage} onClick={goToPreviousPage}>
                    <BiSolidLeftArrow fontSize="20px" color="#4B4B4D" />
                </div>
                {renderPaginationButtons()}
                <div role="button" tabIndex={0} onKeyDown={goToNextPage} onClick={goToNextPage}>
                    <BiSolidRightArrow fontSize="20px" color="#4B4B4D" />
                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default Pagination;
