import React from "react"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { Transaction } from "../../../types"
import Pagination from "../../../Components/elements/Pagination"
import { LuArrowUpToLine, LuArrowDownToLine } from "react-icons/lu"

interface TransactionsTableProps {
  transactions: Transaction[]
  pageData: any
  setPageNum: (pageNum: number) => void
}

interface StatusStyle {
  bgColor: string
  textColor: string
  icon: JSX.Element
  label: string
}

interface DescriptionStyle {
  bgColor: string
  text: string
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactions,
  pageData,
  setPageNum,
}) => {
  const getStatusStyle = (status: string): StatusStyle => {
    switch (status) {
      case "Withdrawn":
      case "debit":
        return {
          bgColor: "bg-[#FFE5E5]",
          textColor: "text-[#E10000]",
          icon: <LuArrowUpToLine />,
          label: status === "Withdrawn" ? "Withdrawn" : "Debit",
        }
      case "credit":
        return {
          bgColor: "bg-[#D2FFD7]",
          textColor: "text-[#00D718]",
          icon: <LuArrowDownToLine />,
          label: "Credit",
        }
      default:
        return {
          bgColor: "bg-[#D2FFD7]",
          textColor: "text-[#00D718]",
          icon: <LuArrowDownToLine />,
          label: "Credit",
        }
    }
  }

  const getDescriptionStyle = (description: string): DescriptionStyle => {
    switch (description) {
      case "Approved":
      case "SUCCESSFUL":
        return {
          bgColor: "bg-[#00CE17]",
          text: "text-white",
        }
      case "FAILED":
      case "Rejected":
        return {
          bgColor: "bg-error",
          text: "text-white",
        }
      default:
        return {
          bgColor: "bg-alert",
          text: "text-white",
        }
    }
  }

  return (
    <div>
      {transactions.length === 0 && (
        <div className="flex flex-col items-center justify-center mt-8 h-[200px]">
          <p className="text-black text-lg font-bold ml-2">
            No transaction yet
          </p>
        </div>
      )}

      <table className="table-auto w-full mt-5">
        <tbody>
          {transactions.map((transaction, index) => {
            const statusStyle = getStatusStyle(transaction.status)
            const descriptionStyle = getDescriptionStyle(
              transaction.description,
            )

            return (
              <tr
                key={index}
                className="flex justify-between items-center mb-3"
              >
                <td>
                  <span
                    className={`flex items-center ${statusStyle.textColor}`}
                  >
                    <span
                      className={` ${statusStyle.bgColor} p-3 rounded-full mr-3`}
                    >
                      {statusStyle.icon}
                    </span>
                    {statusStyle.label}
                  </span>
                </td>
                <td>{transaction.details}</td>
                <td>{transaction.date}</td>
                <td>₦{transaction.amount}</td>
                <td
                  className={`${descriptionStyle.bgColor} p-1 rounded-full ${descriptionStyle.text} text-center text-[10px] w-[70px]`}
                >
                  {transaction.description}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="flex justify-center mt-5">
        <Pagination pageData={pageData} setPageNum={setPageNum} />
      </div>
    </div>
  )
}

export default TransactionsTable
