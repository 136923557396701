import React, { CSSProperties, useState } from 'react';
import { MainButton } from '../Button';

interface FilterComponentProps {
  loadingFilter: boolean;
  transactionType: string;
  setTransactionType: React.Dispatch<React.SetStateAction<string>>;
  transactionStatus: string;
  setTransactionStatus: React.Dispatch<React.SetStateAction<string>>;
  onFilterChange: () => void;
  onClearFilter: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ 
  loadingFilter,
  transactionType,
  setTransactionType,
  transactionStatus,
  setTransactionStatus,
  onFilterChange,
  onClearFilter,
 }) => {
  console.log("In between")
return (
    <div className="my-4">
        <div className="flex justify-end">
        <button
          type="button"
          className="text-primary pt-2 text-[12px]"
          onClick={onClearFilter}
        >
          Clear Filter
        </button>
      </div>
      <div>
        <p className='text-[14px] text-[#111827] font-semibold'>Transaction type:</p>

        <label className="flex justify-between items-center mt-2" htmlFor="withdrawnFunds">
          <span className="ml-2 text-[#0F0A0A] text-[14px] font-medium">Withdrawn Funds</span>
          <input
            type="radio"
            id="withdrawnFunds"
            value="DEBIT"
            checked={transactionType === 'DEBIT'}
            onChange={() => setTransactionType('DEBIT')}
            style={{height:"24px", width:"24px", cursor: "pointer" }}
          />
        </label>

        <label className="flex justify-between items-center mt-2" htmlFor="depositedFunds">
          <span className="ml-2 text-[#0F0A0A] text-[14px] font-medium">Deposited Funds</span>
          <input
            type="radio"
            id="depositedFunds"
            value="credit"
            checked={transactionType === 'credit'}
            onChange={() => setTransactionType('credit')}
            style={{height:"24px", width:"24px", cursor: "pointer" }}
          />
        </label>
      
      </div>

      <div className='mt-4'>
        <p className='text-[14px] text-[#111827] font-semibold'>Transaction status:</p>

        <label className="flex justify-between items-center mt-2" htmlFor="approved">
        <span className="ml-2 text-[#0F0A0A] text-[14px] font-medium">Completed</span>
          <input
            type="radio"
            id="approved"
            value="SUCCESSFUL"
            checked={transactionStatus === 'SUCCESSFUL'}
            onChange={() => setTransactionStatus('SUCCESSFUL')}
            style={{height:"24px", width:"24px", cursor: "pointer" }}
          />
        </label>

        <label className="flex justify-between items-center mt-2" htmlFor="pending">
        <span className="ml-2 text-[#0F0A0A] text-[14px] font-medium">Pending</span>
          <input
            type="radio"
            id="pending"
            value="PENDING"
            checked={transactionStatus === 'PENDING'}
            onChange={() => setTransactionStatus('PENDING')}
            style={{height:"24px", width:"24px", cursor: "pointer" }}
          />
          
        </label>

        <label className="flex justify-between items-center mt-2" htmlFor="rejected">
        <span className="ml-2 text-[#0F0A0A] text-[14px] font-medium">Failed</span>
          <input
            type="radio"
            id="rejected"
            value="FAILED"
            checked={transactionStatus === 'FAILED'}
            onChange={() => setTransactionStatus('FAILED')}
            style={{height:"24px", width:"24px", cursor: "pointer" }}
          />
        </label>
      </div>

      <div className="flex justify-end mt-10">
            <MainButton isLoading={loadingFilter} onClick={onFilterChange}>Filter Transaction</MainButton>
          </div>
    </div>
  );
};

export default FilterComponent;
