import React, { useEffect, useReducer } from "react"
import { Outlet } from "react-router-dom"
import DashboardHeader from "../../Components/blocks/DashboardHeader"
import Sidebar from "../../Components/blocks/Sidebar"

const DashboardLayout = (props: any) => {
  const initialState = {
    openMobileSideBar: false,
  }
  const [_, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="font-latoRegular bg-[#EEF6FF] overflow-x-hidden w-screen h-screen relative">
      <div>
      <header className="p-2 m-2 mr-7 flex right-0 w-[95vw] sm:w-[97vw] lg:w-[97vw] md:w-[96vw] lg:pl-[21vw] mx-3 z-10 top-0 ">
          <DashboardHeader />
        </header>
      </div>
      <div className="flex p-2 ">
      <nav className=" py-2 md:flex-[.2] lg:flex-[.15] h-[100vh] rounded-lg fixed top-0 w-[19%] hidden lg:block">
          <div
            className="flex flex-col rounded-lg h-full items-center w-full "
            style={{
              background: "linear-gradient(180deg, #0078FF 0%, #0146D9 100%)",
            }}
          >
            <div className="w-full mt-32 h-full">
              <Sidebar />
            </div>
          </div>
        </nav>
        <div className="w-[95vw] sm:w-[97vw] lg:w-[97vw] pl-2 h-screen md:w-[96vw] lg:pl-[21vw]">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
