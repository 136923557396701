import React, { useState } from "react"
import { CommunityWalletProps, Transaction } from "../../../../types"
import ModalPanel from "../../../../Components/elements/Modal/Index"
import { CgClose } from "react-icons/cg"
import TransactionsTable from "./TransactionTable"
import GoBack from "../../../../Components/elements/Display/GoBack"
import { Walletbg } from "../../../../Assets"
import Search from "../../../../Components/elements/Forms/MembersSearch"
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import WalletHeader from "../../../../Components/elements/Hero/WalletHeader"

const CommunityWallet: React.FC<CommunityWalletProps> = ({ name, amount }) => {
  const [openWalletModal, setOpenWalletModal] = useState(false)

  const transactionsData: Transaction[] = [
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 500,
      status: "Withdrawn",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Rejected",
      amount: 1000,
      status: "Deposited",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Pending",
      amount: 1000,
      status: "Withdrawn",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 1000,
      status: "Deposited",
    },
    {
      date: "7 Aug 2023 ",
      details: "Funds Community",
      description: "Approved",
      amount: 1000,
      status: "Deposited",
    },
  ]
  return (
    <>
      <div className="flex items-center">
        <div className="m-3">
          <p className="text-primary text-[12px] font-bold mb-0">{name}</p>
          <p className="text-[#8E8E8E]  text-[10px] mt-0">{amount}</p>
        </div>
        <button
          type="button"
          className="text-primary underline text-[13px] ml-auto"
          onClick={() => setOpenWalletModal(true)}
        >
          View
        </button>
      </div>
      <ModalPanel
        open={openWalletModal}
        closeModal={() => setOpenWalletModal(false)}
        modalSize="md:w-[70%] lg:w-[70%] xl:w-[70%]"
      >
        <div className="">
          <div className="relative">
            <WalletHeader
              totalBalance={150000}
              availableBalance={150000}
              withdrawableBalance={63000}
              imageUrl={Walletbg}
            />
            <button
              type="button"
              className="rounded-full w-[40px] h-[40px] p-2.5 cursor-pointer border-none focus:outline-none  text-white absolute top-3 right-7  z-10"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
              onClick={() => setOpenWalletModal(false)}
            >
              <CgClose size={20} />
            </button>
          </div>
          <div className="flex mt-8 justify-between items-center">
            <p className="text-black text-[15px] font-bold">
              {" "}
              Transactions History
            </p>
            <div className="flex gap-2 items-center ">
              <Search />
              <button
                type="button"
                className="flex items-center bg-primary h-10 text-white rounded-md p-2"
              >
                <HiOutlineAdjustmentsHorizontal className="mr-2" />
                Filter transaction
              </button>
            </div>
          </div>

          <TransactionsTable transactions={transactionsData} />
        </div>
      </ModalPanel>
    </>
  )
}

export default CommunityWallet
